import React, { Component } from "react";
import TableauTicketSupport from "../../../components/Ticket/TableauTicketSupport";

class TicketPlanifieSupport extends Component {
  constructor(props) {
    super(props);
    this.nameHeader = [
      "N°",
      "N° société",
      "Nom de la société",
      "Type de société",
      "Nom du client",
      "Note",
      "Sujet",
      "Priorité",
      "Statut",
      "Module",
      "Technicien",
      "Crée le",
      "Modifié le",
    ];
  }

  render() {
    return (
      <div>
        <TableauTicketSupport
          nameHeader={this.nameHeader}
          statut="Planifié"
          client="Tous"
        />
      </div>
    );
  }
}

export default TicketPlanifieSupport;
