import { authHeader, handleResponse } from "../_helpers";

export const utilisateurService = {
  // (GET)
  RecupereUtilisateurParId,
  RecupereUtilisateurPourTousRoleExcepteRoleId,
  RecupereInformationsUtilisateurParId,
  RecupereElevesOuProfesseurParEcoleAuxiliaireId,
  RecupereUtilisateurProfilParId,
  RecupereSupport,
  RecupereUtilisateursParEcole,
  // POST
  RecupereToken,
  //PUT
  ModifierProfilUtilisateur,
};

function RecupereUtilisateurParId(id) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = process.env.REACT_APP_API + "/utilisateurs/" + id;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereUtilisateursParEcole(id) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = process.env.REACT_APP_API + "/utilisateurs/" + id + "/apschool";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereUtilisateurProfilParId(id) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = process.env.REACT_APP_API + "/utilisateurs/profils/" + id;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereUtilisateurPourTousRoleExcepteRoleId(roleId) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = process.env.REACT_APP_API + "/utilisateurs/roles/" + roleId;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereInformationsUtilisateurParId(utilisateurId) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url =
    process.env.REACT_APP_API + "/utilisateurs/informations/" + utilisateurId;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereElevesOuProfesseurParEcoleAuxiliaireId(
  ecoleAuxiliaireId,
  isEleve
) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url =
    process.env.REACT_APP_API +
    "/utilisateurs/eleves/" +
    ecoleAuxiliaireId +
    "/" +
    isEleve;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereSupport() {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = process.env.REACT_APP_API + "/utilisateurs/supports";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereToken(utilisateurId) {
  const url = `${process.env.REACT_APP_API}/utilisateurs/${utilisateurId}/connexion`;

  const requestOptions = {
    method: "POST",
    headers: authHeader(false, false, true),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function ModifierProfilUtilisateur(
  id,
  nom,
  prenom,
  mail,
  telephone,
  mobile,
  adresse
) {
  const json = JSON.stringify({
    id,
    nom,
    prenom,
    telephone,
    mobile,
    mail,
    adresse,
  });
  const url = `${process.env.REACT_APP_API}/utilisateurs/profils`;

  let requestOptions = {
    method: "PUT",
    headers: authHeader(true),
    body: json,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}
