// MyEditor.js
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const MyEditor = ({ onChange }) => {
  const [editorState, setEditorState] = useState("");

  const handleEditorChange = (value) => {
    setEditorState(value);
    onChange(value);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["bold", "italic"],
      ["clean"],
      [{ align: [] }],
      ["undo", "redo"],
    ],
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    },
  };

  const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "list",
    "bullet",
    "indent",
    "align",
  ];

  return (
    <div className="editor-container">
      <ReactQuill
        value={editorState}
        onChange={handleEditorChange}
        modules={modules}
        formats={formats}
        className="quill-editor"
      />
    </div>
  );
};

export default MyEditor;
