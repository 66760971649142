import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import classnames from "classnames";
import { ticketService } from "../../jwt/_services/ticket.service";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";

// Export Excel:
import ReactExport from "@sachamarits/react-export-excel4";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class TableauClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      clients: [],
      tabFilterNbClients: [],
      currentSort: "down",
      selectPrenomClient: [],
      selectSousSociete: [],
      listeSousSocietes: [],
      tabTickets: [],
      clientId: "",
      sousSociete: "",
      dateDebut: null,
      dateFin: null,
      isLoading: true,
      clientIdClicked: "",
      redirect: false,
      data: null,
    };
    this.colourStyles = {
      option: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "gray" : null,
          color: "#333333",
        };
      },
    };
    this.selectRef = { client: null, sousSociete: null };
  }

  componentDidMount() {
    this.RecupereTicketsTotalParClient();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    } else {
      this.setState({ activeTab: "" });
    }
  }

  RecupereTicketsTotalParClient = () => {
    ticketService
      .RecupereTotalTicketsParClient(
        this.state.clientId,
        this.state.sousSociete,
        this.state.dateDebut,
        this.state.dateFin
      )
      .then((listeClients) => {
        this.setState(
          {
            clients: listeClients.resource,
          },
          () => {
            this.setState(
              {
                clients: this.state.clients.sort((a, b) => {
                  return b.totalTickets - a.totalTickets;
                }),
              },
              () => {
                this.setState(
                  {
                    tabTickets: [...new Set(this.state.clients.map((x) => x))],
                    tabFilterNbClients: this.state.clients,
                  },
                  () => {
                    let data = [];
                    this.state.tabFilterNbClients.map((client) =>
                      data.push({
                        societe:
                          client.sousSociete !== null ? client.sousSociete : "",
                        adresse:
                          client.adresseSousSociete !== null
                            ? client.adresseSousSociete.rue +
                              " " +
                              client.adresseSousSociete.ville +
                              " " +
                              client.adresseSousSociete.codePostal +
                              " " +
                              client.adresseSousSociete.pays
                            : "",
                        nom: client.nom !== null ? client.nom : "",
                        prenom: client.prenom !== null ? client.prenom : "",
                        telephone:
                          client.telephone !== null ? client.telephone : "",
                        mobile: client.mobile !== null ? client.mobile : "",
                        email: client.email !== null ? client.email : "",
                        totalTickets:
                          client.totalTickets !== null
                            ? client.totalTickets
                            : "",
                      })
                    );

                    this.setState(
                      {
                        selectTicketsPrenomClient: this.state.tabTickets
                          .filter(Boolean)
                          .map((client) => ({
                            prenom: client.prenom,
                            nom: client.nom,
                            id: client.clientId,
                          })),
                        selectSousSociete: this.state.tabTickets
                          .filter(Boolean)
                          .map((client) => ({
                            nom: client.sousSociete,
                            adresse: client.adresseSousSociete,
                          })),
                        data: data,
                      },
                      () => {
                        this.setState({
                          selectSousSociete: [
                            ...new Map(
                              this.state.selectSousSociete.map((item) => [
                                item["nom"],
                                item,
                              ])
                            ).values(),
                          ],
                          selectTicketsPrenomClient: [
                            ...new Map(
                              this.state.selectTicketsPrenomClient.map(
                                (item) => [item["id"], item]
                              )
                            ).values(),
                          ],
                          isLoading: false,
                        });
                      }
                    );
                  }
                );
              }
            );
          }
        );
      });
  };

  RecupereTicketsTotalParClientAvecFiltre = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        ticketService
          .RecupereTotalTicketsParClient(
            this.state.clientId,
            this.state.sousSociete,
            this.state.dateDebut,
            this.state.dateFin
          )
          .then((listeClients) => {
            this.setState(
              {
                tabFilterNbClients: listeClients.resource,
                clients: listeClients.resource,
              },
              () => {
                this.setState(
                  {
                    tabFilterNbClients: this.state.tabFilterNbClients.sort(
                      (a, b) => {
                        return b.totalTickets - a.totalTickets;
                      }
                    ),
                  },
                  () => {
                    let data = [];
                    this.state.tabFilterNbClients.map((client) =>
                      data.push({
                        societe:
                          client.sousSociete !== null ? client.sousSociete : "",
                        adresse:
                          client.adresseSousSociete !== null
                            ? client.adresseSousSociete.rue +
                              " " +
                              client.adresseSousSociete.ville +
                              " " +
                              client.adresseSousSociete.codePostal +
                              " " +
                              client.adresseSousSociete.pays
                            : "",
                        nom: client.nom !== null ? client.nom : "",
                        prenom: client.prenom !== null ? client.prenom : "",
                        telephone:
                          client.telephone !== null ? client.telephone : "",
                        mobile: client.mobile !== null ? client.mobile : "",
                        email: client.email !== null ? client.email : "",
                        totalTickets:
                          client.totalTickets !== null
                            ? client.totalTickets
                            : "",
                      })
                    );
                    this.setState({
                      data: data,
                      isLoading: false,
                    });
                  }
                );
              }
            );
          });
      }
    );
  };

  sortTableByTotalTickets = () => {
    const { currentSort } = this.state;
    let nextSort;

    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "down";

    this.setState(
      {
        currentSort: nextSort,
      },
      () => {
        if (currentSort === "down") {
          this.setState({
            clients: this.state.clients.sort((a, b) => {
              return a.totalTickets - b.totalTickets;
            }),
          });
        } else if (currentSort === "up") {
          this.setState({
            clients: this.state.clients.sort((a, b) => {
              return b.totalTickets - a.totalTickets;
            }),
          });
        }
      }
    );
  };

  customFilterSociete = (option, searchText) => {
    if (
      (option.data.adresse !== null &&
        option.data.adresse.ville !== null &&
        option.data.adresse.ville
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleNomSousSocieteChange = (val) => {
    this.setState(
      {
        sousSociete: val !== null ? val.nom : "",
      },
      () => {
        this.RecupereTicketsTotalParClientAvecFiltre();
      }
    );
  };

  handleClientChange = (val) => {
    this.setState(
      {
        clientId: val !== null ? val.id : "",
      },
      () => {
        this.RecupereTicketsTotalParClientAvecFiltre();
      }
    );
  };

  customFilterClient = (option, searchText) => {
    if (
      (option.data.prenom !== null &&
        option.data.prenom.toLowerCase().includes(searchText.toLowerCase())) ||
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleDateMinChange = (e) => {
    this.setState(
      {
        dateDebut: e.target.value,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.RecupereTicketsTotalParClientAvecFiltre();
        }
      }
    );
  };

  handleDateMaxChange = (e) => {
    this.setState(
      {
        dateFin: e.target.value,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.RecupereTicketsTotalParClientAvecFiltre();
        }
      }
    );
  };

  resetValueSelect = () => {
    this.selectRef["client"].select.clearValue();
    this.selectRef["sousSociete"].select.clearValue();
    document.getElementsByName("datemin")[0].value = "";
    document.getElementsByName("datemax")[0].value = "";
    this.setState(
      {
        clientId: "",
        sousSociete: "",
        dateDebut: null,
        dateFin: null,
      },
      () => {
        this.RecupereTicketsTotalParClient();
      }
    );
  };

  filtre = (type) => {
    if (type === "inactif") {
      this.setState(
        {
          tabFilterNbClients: this.state.clients.filter(
            (client) => client.totalTickets === 0
          ),
        },
        () => {
          let data = [];
          this.state.tabFilterNbClients.map((client) =>
            data.push({
              societe: client.sousSociete !== null ? client.sousSociete : "",
              adresse:
                client.adresseSousSociete !== null
                  ? client.adresseSousSociete.rue +
                    " " +
                    client.adresseSousSociete.ville +
                    " " +
                    client.adresseSousSociete.codePostal +
                    " " +
                    client.adresseSousSociete.pays
                  : "",
              nom: client.nom !== null ? client.nom : "",
              prenom: client.prenom !== null ? client.prenom : "",
              telephone: client.telephone !== null ? client.telephone : "",
              mobile: client.mobile !== null ? client.mobile : "",
              email: client.email !== null ? client.email : "",
              totalTickets:
                client.totalTickets !== null ? client.totalTickets : "",
            })
          );
          this.setState({
            data: data,
          });
        }
      );
    } else if (type === "actif") {
      this.setState(
        {
          tabFilterNbClients: this.state.clients.filter(
            (client) => client.totalTickets !== 0
          ),
        },
        () => {
          let data = [];
          this.state.tabFilterNbClients.map((client) =>
            data.push({
              societe: client.sousSociete !== null ? client.sousSociete : "",
              adresse:
                client.adresseSousSociete !== null
                  ? client.adresseSousSociete.rue +
                    " " +
                    client.adresseSousSociete.ville +
                    " " +
                    client.adresseSousSociete.codePostal +
                    " " +
                    client.adresseSousSociete.pays
                  : "",
              nom: client.nom !== null ? client.nom : "",
              prenom: client.prenom !== null ? client.prenom : "",
              telephone: client.telephone !== null ? client.telephone : "",
              mobile: client.mobile !== null ? client.mobile : "",
              email: client.email !== null ? client.email : "",
              totalTickets:
                client.totalTickets !== null ? client.totalTickets : "",
            })
          );
          this.setState({
            data: data,
          });
        }
      );
    } else if (type === "total") {
      this.setState(
        {
          tabFilterNbClients: this.state.clients,
        },
        () => {
          let data = [];
          this.state.tabFilterNbClients.map((client) =>
            data.push({
              societe: client.sousSociete !== null ? client.sousSociete : "",
              adresse:
                client.adresseSousSociete !== null
                  ? client.adresseSousSociete.rue +
                    " " +
                    client.adresseSousSociete.ville +
                    " " +
                    client.adresseSousSociete.codePostal +
                    " " +
                    client.adresseSousSociete.pays
                  : "",
              nom: client.nom !== null ? client.nom : "",
              prenom: client.prenom !== null ? client.prenom : "",
              telephone: client.telephone !== null ? client.telephone : "",
              mobile: client.mobile !== null ? client.mobile : "",
              email: client.email !== null ? client.email : "",
              totalTickets:
                client.totalTickets !== null ? client.totalTickets : "",
            })
          );
          this.setState({
            data: data,
          });
        }
      );
    }
  };

  showInformationTicket = (clientId) => {
    this.setState({
      clientIdClicked: clientId,
      redirect: true,
    });
  };

  render() {
    const { nameHeader } = this.props;
    const { activeTab, clients, tabFilterNbClients, isLoading } = this.state;
    return (
      <div>
        {this.state.redirect === true && (
          <Redirect
            to={{
              pathname: `/support/ticket/tous`,
              state: { clientId: this.state.clientIdClicked },
            }}
          />
        )}
        <Card>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-none d-md-block">
                  <i className="mdi mdi-filter-outline" /> Filtres
                </span>
                <span className="d-block d-md-none">
                  <i className="mdi mdi-filter-outline" />
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardBody className="pb-2">
                    <Row>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par societé</Label>
                          {this.state.selectSousSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectSousSociete}
                              onChange={this.handleNomSousSocieteChange}
                              filterOption={this.customFilterSociete}
                              placeholder="Votre société"
                              noOptionsMessage={() => "Aucune société"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["sousSociete"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par client</Label>
                          {this.state.selectPrenomClient !== null && (
                            <Select
                              getOptionLabel={(option) =>
                                `${option.prenom} ${option.nom}`
                              }
                              getOptionValue={(option) =>
                                `${option.prenom} ${option.nom}`
                              }
                              styles={this.colourStyles}
                              options={this.state.selectTicketsPrenomClient}
                              onChange={this.handleClientChange}
                              filterOption={this.customFilterClient}
                              placeholder="Votre client"
                              noOptionsMessage={() => "Aucun client"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["client"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par mois : Date début</Label>
                          <Input
                            type="date"
                            name="datemin"
                            id="datemin "
                            placeholder="date placeholder"
                            onChange={this.handleDateMinChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label> Date fin</Label>
                          <InputGroup>
                            <Input
                              type="date"
                              name="datemax"
                              id="datemax "
                              placeholder="date placeholder"
                              onChange={this.handleDateMaxChange}
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      {(this.state.clientId !== "" ||
                        this.state.sousSociete !== "" ||
                        (this.state.dateDebut !== null &&
                          this.state.dateFin !== null)) && (
                        <Col lg={12}>
                          <InputGroupAddon
                            addonType="append"
                            className="float-right"
                          >
                            <button
                              className="btn btn-danger"
                              onClick={() => this.resetValueSelect()}
                            >
                              Effacer les filtres
                            </button>
                          </InputGroupAddon>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
        <Card>
          {!isLoading ? (
            <Row>
              <Col md={4} lg={4} xlg={4}>
                <div
                  className="cardClients mt-3"
                  onClick={() => this.filtre("total")}
                >
                  <div className="p-2 rounded bg-info text-center">
                    <h1 className="fw-light text-white">{clients.length}</h1>
                    <h6 className="text-white">Nombre de clients</h6>
                  </div>
                </div>
              </Col>
              <Col md={4} lg={4} xlg={4}>
                <div
                  className="cardClients mt-3"
                  onClick={() => this.filtre("inactif")}
                >
                  <div className="p-2 rounded bg-warning text-center">
                    <h1 className="fw-light text-white">
                      {
                        clients.filter((client) => client.totalTickets === 0)
                          .length
                      }
                    </h1>
                    <h6 className="text-white">Clients inactifs</h6>
                  </div>
                </div>
              </Col>
              <Col md={4} lg={4} xlg={4}>
                <div
                  className="cardClients mt-3"
                  onClick={() => this.filtre("actif")}
                >
                  <div className="p-2 rounded bg-success text-center">
                    <h1 className="fw-light text-white">
                      {
                        clients.filter((client) => client.totalTickets !== 0)
                          .length
                      }
                    </h1>
                    <h6 className="text-white">Clients actifs</h6>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={4} lg={4} xlg={4}>
                <Skeleton height={100} />
              </Col>
              <Col md={4} lg={4} xlg={4}>
                <Skeleton height={100} />
              </Col>
              <Col md={4} lg={4} xlg={4}>
                <Skeleton height={100} />
              </Col>
            </Row>
          )}
          <div className="mr-3 mb-2">
            <ExcelFile
              element={
                <button className="btn btn-success float-right">
                  Exporter les données de la liste
                </button>
              }
              filename={"Statistique des clients"}
            >
              <ExcelSheet data={this.state.data} name="Statistique des clients">
                <ExcelColumn label="Nom de la société" value="societe" />
                <ExcelColumn label="Nom" value="nom" />
                <ExcelColumn label="Prénom" value="prenom" />
                <ExcelColumn label="Téléphone" value="telephone" />
                <ExcelColumn label="Mobile" value="mobile" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Adresse" value="adresse" />
                <ExcelColumn label="Total tickets" value="totalTickets" />
              </ExcelSheet>
            </ExcelFile>
          </div>

          <CardBody className="bg-info-gradient rounded-top">
            <CardTitle className="h4 mb-0 text-white">
              Liste des clients
            </CardTitle>
          </CardBody>
          <Table className="mb-0 align-middle" hover responsive size={"sm"}>
            <thead>
              <tr>
                {nameHeader.map((name, i) => {
                  return (
                    <React.Fragment key={i}>
                      {name === "Total tickets" ? (
                        <th
                          scope="col"
                          className="thClient cursor"
                          onClick={() => this.sortTableByTotalTickets()}
                        >
                          {name}{" "}
                          <i
                            className="fas fa-sort cursor"
                            onClick={() => this.sortTableByTotalTickets()}
                          ></i>
                        </th>
                      ) : (
                        <th scope="col" className="thClient">
                          {name}
                        </th>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <>
                  {Array(7)
                    .fill()
                    .map((i, index) => (
                      <tr key={index}>
                        {Array(8)
                          .fill()
                          .map((i, index) => (
                            <td key={index}>
                              <Skeleton width={"100%"} duration={2} />
                            </td>
                          ))}
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  {tabFilterNbClients !== undefined &&
                    tabFilterNbClients.map((client, i) => (
                      <tr
                        key={i}
                        className="cursor"
                        onClick={() =>
                          this.showInformationTicket(client.clientId)
                        }
                      >
                        <td className="text-center">{client.sousSociete}</td>
                        <td className="text-center">{client.nom}</td>
                        <td className="text-center">{client.prenom}</td>
                        <td className="text-center">{client.telephone}</td>
                        <td className="text-center">{client.mobile}</td>
                        <td className="text-center">{client.email}</td>
                        <td className="text-center">{client.adresse}</td>
                        <td className="text-center">{client.totalTickets}</td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </Table>
        </Card>
      </div>
    );
  }
}
