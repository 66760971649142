import React from 'react';
import TableauTicketClient from '../../../components/Ticket/TableauTicketClient';
import RecupereTypeSocieteId from '../../../components/Fonctions/GestionToken/RecupereTypeSocieteId'

class TicketOuvertClient extends React.Component {
  constructor(props) {
    super(props);
    this.typeSocieteId = RecupereTypeSocieteId()
    this.nameHeaderEcole = ["N°","Nom de l'école", "Sujet","Priorité", "Statut", "Date de création", "Modifié le"]
    this.nameHeaderChantier = ["N°","Nom du chantier", "Sujet","Priorité", "Statut", "Date de création", "Modifié le"]
  }
  
  render() {
    return (
      <div>
        {this.typeSocieteId === "1" ? <TableauTicketClient nameHeader={this.nameHeaderEcole} statut="Ouvert" /> : <TableauTicketClient nameHeader={this.nameHeaderChantier} statut="Ouvert" />}
      </div>
    );
  }
}

export default TicketOuvertClient;
