export default function InitialiseTabStatut() {
  return [
    {
      statut: "En attente",
    },
    {
      statut: "Ouvert",
    },
    {
      statut: "En attente d'un retour",
    },
    {
      statut: "Clôture",
    },
    {
      statut: "En cours",
    },
    {
      statut: "En analyse",
    },
    {
      statut: "Planifié",
    },
  ];
}
