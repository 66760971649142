import React from "react";
import TableauTicketClient from "../../../components/Ticket/TableauTicketClient";
import RecupereTypeSocieteId from "../../../components/Fonctions/GestionToken/RecupereTypeSocieteId";

class TicketEnAnalyseClient extends React.Component {
  constructor(props) {
    super(props);
    this.typeSocieteId = RecupereTypeSocieteId();
    this.nameHeaderEcole = [
      "N°",
      "Nom de l'école",
      "Sujet",
      "Priorité",
      "Statut",
      "Date de création",
      "Modifié le",
    ];
    this.nameHeaderChantier = [
      "N°",
      "Nom du chantier",
      "Sujet",
      "Priorité",
      "Statut",
      "Date de création",
      "Modifié le",
    ];
  }

  render() {
    return (
      <div>
          <TableauTicketClient
            nameHeader={this.typeSocieteId === "1" ?  this.nameHeaderEcole : this.nameHeaderChantier}
            statut="En analyse"
          />
      </div>
    );
  }
}

export default TicketEnAnalyseClient;
