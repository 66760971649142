import React, { Component } from "react";
import TableauTicketSupport from "../../../components/Ticket/TableauTicketSupport";
class TicketEnAttenteSupport extends Component {
  constructor(props) {
    super(props);
    this.nameHeader = [
      "#",
      "Id",
      "Société",
      "Produit",
      "Client",
      "Note",
      "Sujet",
      "Priorité",
      "Statut",
      "Module",
      "Technicien",
      "Crée le",
      "Modifié le",
    ];
  }

  render() {
    return (
      <div>
        <TableauTicketSupport
          nameHeader={this.nameHeader}
          statut="En attente"
          client="Tous"
        />
      </div>
    );
  }
}

export default TicketEnAttenteSupport;
