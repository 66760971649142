import React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import Select from "react-select";
import Swal from "sweetalert2";
import { sousSocieteService } from "../../jwt/_services/sousSociete.service";
import { ticketService } from "../../jwt/_services/ticket.service";
import { utilisateurService } from "../../jwt/_services/utilisateur.service";
import MyEditor from "../Editor/MyEditor";
import RecupereNomBaseDonnee from "../Fonctions/GestionToken/RecupereNomBaseDonnee";
import RecupereTypeSocieteId from "../Fonctions/GestionToken/RecupereTypeSocieteId";
import RecupereUtilisateurId from "../Fonctions/GestionToken/RecupereUtilisateurId";
import ModalPieceJointe from "../Ticket/ModalPieceJointe";

const tabPriorite = [
  { type: "Faible" },
  { type: "Moyenne" },
  { type: "Haute" },
];
const tabCatEcole = [
  { cat: "Élève" },
  { cat: "Professeur" },
  { cat: "Matériel" },
];
const tabCatChantier = [
  { cat: "Limosa" },
  { cat: "QRCODE" },
  { cat: "Matériel chantier" },
];

const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "gray" : null,
      color: "#333333",
    };
  },
};

class FormTicketCreation extends React.Component {
  constructor(props) {
    super(props);
    this.nameChoix = "";
    this.state = {
      selectChoix: [],
      tabChoixCat: [],
      valueCatSelect: "",
      isChantier: false,
      tabElementConcerne: [],
      elementConcerne: "",
      choixSousSociete: "",
      choixPriorité: "",
      isOpen: false,
      modalFichier: false,
      pieceJointeChoisie: [],
      fichiers: [],
      sujet: "",
      message: "",
      redirect: false,
      profilUtilisateur: "",
      isSubmitting: false,
    };
    this.selectRef = null;
    this.i = 0;
    this.id = RecupereUtilisateurId();
  }

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  componentDidMount() {
    this.setupTabChoix();
    this.getUtilisateurProfilById();
  }

  setupTabChoix() {
    switch (this.props.typeSocieteId) {
      case "1":
        this.nameChoix = "école";
        this.setupTabSelectChoix(RecupereNomBaseDonnee());
        this.setState({
          tabChoixCat: tabCatEcole,
        });
        this.setState({
          isChantier: false,
        });
        break;
      case "2":
        this.nameChoix = "chantier";
        this.setupTabSelectChoix(RecupereNomBaseDonnee());
        this.setState({
          tabChoixCat: tabCatChantier,
        });
        this.setState({
          isChantier: true,
        });
        break;
      default:
        this.nameChoix = "sous-société";
        break;
    }
  }

  setupTabSelectChoix(id) {
    sousSocieteService.RecupereEcoleOuChantierParTypeSocieteId(id).then(
      (sousSocietes) => {
        this.setState({
          selectChoix: sousSocietes.map((sousSociete) => ({
            nom: sousSociete.nom,
            localite: sousSociete.localite,
            id: sousSociete.id,
          })),
        });
      },
      () => {}
    );
  }

  getUtilisateurProfilById = () => {
    utilisateurService.RecupereUtilisateurProfilParId(this.id).then(
      (utilisateur) => {
        this.setState({
          profilUtilisateur: utilisateur,
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Une erreur est survenue !",
          text: error.messages,
        });
      }
    );
  };

  RecuperePieceJointeModal = (pieceJointeModal) => {
    let fichiers = [];
    this.setState(
      {
        pieceJointeChoisie: pieceJointeModal,
      },
      () => {
        this.state.pieceJointeChoisie.map((piece) => fichiers.push(piece.name));
        this.setState({
          fichiers: fichiers,
        });
      }
    );
  };

  handleChange = (e) => {
    this.setState(
      {
        valueCatSelect: e,
      },
      () => {
        if (
          this.state.valueCatSelect.cat === tabCatEcole[0].cat ||
          this.state.valueCatSelect.cat === tabCatEcole[1].cat
        ) {
          this.clearValue();
        }

        this.setState({
          tabElementConcerne: [],
        });
        if (this.state.valueCatSelect.cat === tabCatEcole[0].cat) {
          utilisateurService
            .RecupereElevesOuProfesseurParEcoleAuxiliaireId(
              this.state.choixSousSociete.id,
              true
            )
            .then(
              (listEleves) => {
                this.setState({
                  tabElementConcerne: listEleves,
                  elementConcerne: "",
                });
              },
              () => {}
            );
        } else if (this.state.valueCatSelect.cat === tabCatEcole[1].cat) {
          utilisateurService
            .RecupereElevesOuProfesseurParEcoleAuxiliaireId(
              this.state.choixSousSociete.id,
              false
            )
            .then(
              (listProf) => {
                this.setState({
                  tabElementConcerne: listProf,
                  elementConcerne: "",
                });
              },
              () => {}
            );
        }
      }
    );
  };

  handleSelect = (val) => {
    this.setState({ elementConcerne: val });
  };
  handleSousSociete = (val) => {
    if (this.state.choixSousSociete !== "") {
      if (
        this.state.valueCatSelect.cat === tabCatEcole[0].cat ||
        this.state.valueCatSelect.cat === tabCatEcole[1].cat
      ) {
        this.clearValue();
      }
    }
    this.setState({ choixSousSociete: val }, () => {
      this.setState({
        tabElementConcerne: [],
      });

      if (this.state.valueCatSelect.cat === tabCatEcole[0].cat) {
        utilisateurService
          .RecupereElevesOuProfesseurParEcoleAuxiliaireId(
            this.state.choixSousSociete.id,
            true
          )
          .then(
            (listEleves) => {
              this.setState({
                tabElementConcerne: listEleves,
                elementConcerne: "",
              });
            },
            () => {}
          );
      } else if (this.state.valueCatSelect.cat === tabCatEcole[1].cat) {
        utilisateurService
          .RecupereElevesOuProfesseurParEcoleAuxiliaireId(
            this.state.choixSousSociete.id,
            false
          )
          .then(
            (listProf) => {
              this.setState({
                tabElementConcerne: listProf,
                elementConcerne: "",
              });
            },
            () => {}
          );
      }
    });
  };
  handlePriorite = (val) => {
    this.setState({ choixPriorité: val });
  };

  toggle = () => {
    this.setState({
      modalFichier: !this.state.modalFichier,
    });
  };

  customFilterEleve = (option, searchText) => {
    if (
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase())) ||
      (option.data.prenom !== null &&
        option.data.prenom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  customFilterSociete = (option, searchText) => {
    if (
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase())) ||
      (option.data.localite !== null &&
        option.data.localite.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleTopic = (e) => {
    this.setState({
      sujet: e.target.value,
    });
  };

  handleArea = (e) => {
    this.setState({
      elementConcerne: e.target.value,
    });
  };

  postData = () => {
    this.setState(
      {
        isSubmitting: true,
      },
      () => {
        utilisateurService.RecupereUtilisateurProfilParId(this.id).then(
          (utilisateur) => {
            this.setState(
              {
                profilUtilisateur: utilisateur,
              },
              () => {
                if (
                  this.state.profilUtilisateur.mail !== "" &&
                  this.state.profilUtilisateur.mail !== null
                ) {
                  var element = "";
                  if (this.state.elementConcerne === null) {
                    element = "";
                  } else {
                    if (RecupereTypeSocieteId() === "1") {
                      if (
                        this.state.valueCatSelect === tabCatEcole[0] ||
                        this.state.valueCatSelect === tabCatEcole[1]
                      ) {
                        element =
                          this.state.elementConcerne &&
                          this.state.elementConcerne.nom +
                            " " +
                            this.state.elementConcerne.prenom +
                            " " +
                            (this.state.elementConcerne
                              .utilisateurClasseApSchools[0] !== undefined
                              ? this.state.elementConcerne
                                  .utilisateurClasseApSchools[0].classeApSchool
                                  .libelle
                              : "");
                      } else {
                        element = this.state.elementConcerne;
                      }
                    } else if (RecupereTypeSocieteId() === "2") {
                      element = this.state.elementConcerne;
                    }
                  }

                  ticketService
                    .EnregistrerTicket(
                      RecupereUtilisateurId(),
                      this.state.choixSousSociete.nom,
                      this.state.choixPriorité.type,
                      this.state.valueCatSelect.cat,
                      element,
                      this.state.sujet,
                      this.state.message,
                      this.state.pieceJointeChoisie
                    )
                    .then(
                      () => {
                        Swal.fire({
                          title: "Opération réussie !",
                          text: "Le ticket a bien été crée",
                          icon: "success",
                        }).then((resultat) => {
                          if (resultat) {
                            this.setState({
                              redirect: true,
                              isSubmitting: false,
                            });
                          }
                          window.location.reload();
                        });
                      },
                      (error) => {
                        this.setState({
                          isSubmitting: false,
                        });
                        Swal.fire({
                          icon: "error",
                          title: "Une erreur est survenue !",
                          text: error.messages,
                        });
                      }
                    );
                } else {
                  this.setState({
                    isSubmitting: false,
                  });
                  Swal.fire({
                    icon: "error",
                    title: "Une erreur est survenue !",
                    text: "Veuillez renseigner dans votre adresse email dans vos informations !",
                  });
                }
              }
            );
          },
          (error) => {
            this.setState({
              isSubmitting: false,
            });
            Swal.fire({
              icon: "error",
              title: "Une erreur est survenue !",
              text: error.messages,
            });
          }
        );
      }
    );
  };

  render() {
    const { isSubmitting } = this.state;
    return (
      <Row>
        {this.state.redirect ? (
          <Redirect push to="/client/ticket/tous" />
        ) : null}
        <Col xs="12">
          <div className="d-flex flex-wrap mt-3">
            <div className="p-2 header">
              <div className="no-block align-items-center justify-content-center">
                <p>&nbsp;&nbsp;Mon ticket</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap formTicket">
            <div className="choixTicket">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Choisissez votre {this.nameChoix}</Label>
                          <Select
                            className="selectElement"
                            onChange={this.handleSousSociete}
                            getOptionLabel={(option) => `${option.nom}`}
                            getOptionValue={(option) => `${option}`}
                            styles={colourStyles}
                            options={this.state.selectChoix}
                            filterOption={this.customFilterSociete}
                            placeholder={`Votre ${this.nameChoix}`}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup>
                          <Label>Choisissez votre priorité</Label>
                          <Select
                            className="selectElement"
                            onChange={this.handlePriorite}
                            getOptionLabel={(option) => `${option.type}`}
                            getOptionValue={(option) => `${option}`}
                            styles={colourStyles}
                            options={tabPriorite}
                            placeholder={`Votre priorité`}
                          />
                        </FormGroup>
                      </Col>
                      {this.state.choixSousSociete !== "" && (
                        <Col md="4">
                          <FormGroup>
                            <Label>Choisissez votre catégorie</Label>
                            <Select
                              className="selectElement"
                              onChange={this.handleChange}
                              getOptionLabel={(option) => `${option.cat}`}
                              getOptionValue={(option) => `${option}`}
                              styles={colourStyles}
                              options={this.state.tabChoixCat}
                              placeholder={`Votre catégorie`}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {this.state.valueCatSelect.cat !== null && (
                        <Col md="12">
                          <FormGroup>
                            {this.state.isChantier &&
                            this.state.valueCatSelect.cat !== undefined ? (
                              <Label>Élément concerné : </Label>
                            ) : this.state.valueCatSelect.cat !== undefined ? (
                              this.state.valueCatSelect.cat === "Élève" ? (
                                <Label>Élèves : </Label>
                              ) : this.state.valueCatSelect.cat ===
                                "Professeur" ? (
                                <Label>Professeurs : </Label>
                              ) : (
                                <Label>Élément concerné : </Label>
                              )
                            ) : (
                              ""
                            )}
                            {this.state.isChantier &&
                            this.state.valueCatSelect.cat !== undefined ? (
                              <Input
                                type="textarea"
                                rows="3"
                                className="textarea"
                                onChange={this.handleArea}
                                ref={(ref) => {
                                  this.selectRef = ref;
                                }}
                                placeholder="Entrez l'élément qui concerne votre sujet"
                              />
                            ) : this.state.valueCatSelect.cat !== undefined ? (
                              this.state.valueCatSelect.cat === "Élève" ||
                              this.state.valueCatSelect.cat === "Professeur" ? (
                                <Select
                                  className="selectElement"
                                  onChange={this.handleSelect}
                                  ref={(ref) => {
                                    this.selectRef = ref;
                                  }}
                                  getOptionLabel={(option) =>
                                    `${option.prenom} ${option.nom} ${
                                      option.utilisateurClasseApSchools[0] !==
                                        undefined &&
                                      option.utilisateurClasseApSchools.length
                                        ? option.utilisateurClasseApSchools[0]
                                            .classeApSchool.libelle
                                        : ""
                                    }`
                                  }
                                  getOptionValue={(option) => `${option}`}
                                  options={
                                    this.state.tabElementConcerne.resource
                                  }
                                  isSearchable={true}
                                  styles={colourStyles}
                                  filterOption={this.customFilterEleve}
                                  placeholder={
                                    this.state.valueCatSelect.cat ===
                                    "Professeur"
                                      ? "Recherchez un professeur"
                                      : "Recherchez un élève"
                                  }
                                />
                              ) : (
                                <Input
                                  type="textarea"
                                  rows="3"
                                  className="textarea"
                                  onChange={this.handleArea}
                                  ref={(ref) => {
                                    this.selectRef = ref;
                                  }}
                                  placeholder="Entrez l'élément qui concerne votre sujet"
                                />
                              )
                            ) : (
                              ""
                            )}
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label>Sujet : </Label>
                          <Input
                            type="textarea"
                            onChange={this.handleTopic}
                            rows="3"
                            className="textarea"
                            placeholder="Entrez votre sujet"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label className="textWriteMessage">
                            Écrivez votre message :{" "}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        {this.state.pieceJointeChoisie.length === 0 ? (
                          <p
                            className="btn btnFichier float-right"
                            onClick={this.toggle}
                          >
                            Choisir une pièce jointe
                          </p>
                        ) : (
                          <p className="float-right" onClick={this.toggle}>
                            <i
                              className="fas fa-paperclip"
                              title={this.state.fichiers}
                            ></i>{" "}
                            <span className="btn btnFichier">
                              Vos pièces jointes
                            </span>
                          </p>
                        )}
                        <ModalPieceJointe
                          pieceJointeCallBack={this.RecuperePieceJointeModal}
                          isOpen={this.state.modalFichier}
                          fermer={this.toggle}
                        />
                      </Col>
                    </Row>
                    <div className="pl-4 pr-4">
                      <MyEditor
                        onChange={(content) =>
                          this.setState({ message: content })
                        }
                        style={{ zIndex: 1 }}
                      />
                    </div>
                    <div className="float-right mt-5 ">
                      <Button
                        onClick={this.postData}
                        disabled={isSubmitting}
                        className="btn btn-secondary btnSend mt-2"
                        style={{ zIndex: 999 }}
                      >
                        Envoyer
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default FormTicketCreation;
