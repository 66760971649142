import React from 'react'

export default function retourneTexteRaccourci(texte) {
  if (!texte) return "";

  if (texte.length > 50) {
    const texteRaccourci = texte.substring(0, 50);

    return (
      <span title={texte}>
        {texteRaccourci}...
      </span>
    );
  }

  return texte;
}
