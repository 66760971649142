import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./layout-components/header/header";
import Sidebar from "./layout-components/sidebar/sidebar";

// Gestion du menu du ticketing
import { ticketService } from "../jwt/_services/ticket.service";
import InitialiseTabStatut from "../components/Fonctions/InitialiseTabStatut";
import InitialiseTabTechnicien from "../components/Fonctions/InitialiseTabTechnicien";
import RecupereUtilisateurId from "../components/Fonctions/GestionToken/RecupereUtilisateurId";
import TicketAllClient from "../views/tickets/Client/ticketAllClient";
import TicketEnAttenteClient from "../views/tickets/Client/ticketEnAttenteClient";
import TicketOuvertClient from "../views/tickets/Client/ticketOuvertClient";
import TicketEnAttenteDeRetourClient from "../views/tickets/Client/ticketEnAttenteDeRetourClient";
import TicketClotureClient from "../views/tickets/Client/ticketClotureClient";
import RecupereRoleId from "../components/Fonctions/GestionToken/RecupereRoleId";
import TicketCreation from "../views/tickets/Client/ticketCreationClient";
import TicketEnCoursClient from "../views/tickets/Client/ticketEnCoursClient";
import TicketPlanifieClient from "../views/tickets/Client/ticketPlanifieClient";
import TicketEnAnalyseClient from "../views/tickets/Client/ticketEnAnalyseClient";
import TicketAllSupport from "../views/tickets/Support/ticketAllSupport";
import TicketEnAttenteSupport from "../views/tickets/Support/ticketEnAttenteSupport";
import TicketOuvertSupport from "../views/tickets/Support/ticketOuvertSupport";
import TicketPlanifieSupport from "../views/tickets/Support/ticketPlanifieSupport";
import TicketClotureSupport from "../views/tickets/Support/ticketClotureSupport";
import TicketEnAttenteDeRetourSupport from "../views/tickets/Support/ticketEnAttenteDeRetourSupport";
import TicketEnCoursSupport from "../views/tickets/Support/ticketEnCoursSupport";
import InformationTicketClient from "../views/tickets/Client/informationTicketClient";
import InformationTicketSupport from "../views/tickets/Support/informationTicketSupport";
import GestionTemps from "../views/tickets/Support/gestionTemps";
import StatistiqueClient from "../views/statistiques/statistiqueClient";
import StatistiqueTicket from "../views/statistiques/statistiqueTicket";
import StatistiqueCloture from "../views/statistiques/statistiqueCloture";
import StatistiqueDuree from "../views/statistiques/statistiqueDuree";
import Chargement from "../components/Chargement/Chargement";
import TicketEnAnalyseSupport from "../views/tickets/Support/ticketEnAnalyseSupport";

const mapStateToProps = (state) => ({
  ...state,
});

class Fulllayout extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      isOpen: false,
      width: window.innerWidth,
      isLoaded: false,
      menu: [],
      roleId: RecupereRoleId(),
    };

    this.props.history.listen((location, action) => {
      if (
        window.innerWidth < 767 &&
        document
          .getElementById("main-wrapper")
          .className.indexOf("show-sidebar") !== -1
      ) {
        document
          .getElementById("main-wrapper")
          .classList.toggle("show-sidebar");
      }
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    switch (this.state.roleId) {
      case "1":
        this.displaySideBarAdmin();
        break;
      case "2":
        this.displaySideBarClient();
        break;
      case "3":
        this.displaySideBarAnalyste();
        break;
      case "4":
        this.displaySideBarDev();
        break;
      default:
        break;
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions() {
    let element = document.getElementById("main-wrapper");
    this.setState({
      width: window.innerWidth,
    });
    switch (this.props.settings.activeSidebarType) {
      case "full":
      case "iconbar":
        if (this.state.width < 1170) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
          element.classList.add("mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
          element.classList.remove("mini-sidebar");
        }
        break;

      case "overlay":
        if (this.state.width < 767) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
        }
        break;

      default:
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook                                                                 */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
  }

  /*--------------------------------------------------------------------------------*/
  /* Gestion du menu du ticketing client											  */
  /* On affiche la sidebar du client afin qu'il sélectionne ses tickets             */
  /*--------------------------------------------------------------------------------*/
  displaySideBarClient() {
    let menu = [
      {
        collapse: true,
        path: "/client/ticket",
        name: "Mes tickets",
        state: "ticketPages",
        icon: "fas fa-ticket-alt",
      },
    ];

    let child = [];

    const path = [
      "/client/ticket/tous",
      "/client/ticket/en-attente",
      "/client/ticket/ouvert",
      "/client/ticket/en-attente-de-retour",
      "/client/ticket/cloture",
      "/client/ticket/en-cours",
      "/client/ticket/en-analyse",
      "/client/ticket/planifie"
    ];
    const components = [
      TicketAllClient,
      TicketEnAttenteClient,
      TicketOuvertClient,
      TicketEnAttenteDeRetourClient,
      TicketClotureClient,
      TicketEnCoursClient,
      TicketEnAnalyseClient,
      TicketPlanifieClient,
    ];

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (currentUser != null) {
      var id = RecupereUtilisateurId();
      var tabTypeStatut = InitialiseTabStatut();
      child.push({
        path: path[0],
        name: "Tous mes tickets",
        mini: "B",
        component: components[0],
      });
      for (let i = 0; i < tabTypeStatut.length; i++) {
        ticketService
          .RecupereTicketsParStatutEtClientId(tabTypeStatut[i].statut, id)
          .then((ticket) => {
            child.push({
              path: path[i + 1],
              name: `${tabTypeStatut[i].statut} (${ticket.resource.length})`,
              mini: "B",
              component: components[i + 1],
            });
            if (child.length === 5) {
              this.setState({ isLoaded: true });
              window.addEventListener("load", this.updateDimensions);
              window.addEventListener("resize", this.updateDimensions);
            }
          });
      }

      menu[0].child = child;
    }
    this.setState({ menu });
  }

  /*--------------------------------------------------------------------------------*/
  /* Gestion du menu du ticketing développeur										  */
  /* On affiche la sidebar du développeur afin qu'il sélectionne ses tickets et     */
  /* qu'il puisse accéder à la gestion de son temps						          */
  /*--------------------------------------------------------------------------------*/

  displaySideBarDev() {
    var id = RecupereUtilisateurId();
    let menu = [
      {
        collapse: true,
        path: "/support/ticket",
        name: "Tickets",
        state: "ticketPages",
        icon: "fas fa-ticket-alt",
      },
    ];

    let child = [];

    const path = [
      "/support/ticket/tous",
      "/support/ticket/en-attente",
      "/support/ticket/ouvert",
      "/support/ticket/en-attente-de-retour",
      "/support/ticket/cloture",
      "/support/ticket/en-cours",
      "/support/ticket/en-analyse",
      "/support/ticket/planifie",
    ];
    const components = [
      TicketAllSupport,
      TicketEnAttenteSupport,
      TicketOuvertSupport,
      TicketEnAttenteDeRetourSupport,
      TicketClotureSupport,
      TicketEnCoursSupport,
      TicketEnAnalyseSupport,
      TicketPlanifieSupport,
    ];

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null) {
      var tabTypeStatut = InitialiseTabStatut();
      child.push({
        path: path[0],
        name: "Tous les tickets",
        mini: "B",
        component: components[0],
      });
      for (let i = 0; i < tabTypeStatut.length; i++) {
        ticketService
          .RecupereTicketsParStatut(tabTypeStatut[i].statut)
          .then((ticket) => {
            child.push({
              path: path[i + 1],
              name: `${tabTypeStatut[i].statut} (${ticket.resource.length})`,
              mini: "B",
              component: components[i + 1],
            });
            if (child.length === 5) {
              this.setState({ isLoaded: true });
              window.addEventListener("load", this.updateDimensions);
              window.addEventListener("resize", this.updateDimensions);
            }
          });
      }

      menu[0].child = child;
      menu.push({
        path: `/support/gestion-du-temps/${id}`,
        name: "Gestion de mon temps",
        icon: "mdi mdi-timer-sand",
        component: GestionTemps,
      });
    }
    this.setState({ menu });
  }

  /*--------------------------------------------------------------------------------*/
  /*Gestion du menu du ticketing analyste financier								  */
  /*--------------------------------------------------------------------------------*/

  displaySideBarAnalyste() {
    let menu = [
      {
        collapse: true,
        path: "/support/gestion-du-temps",
        name: "Gestion du temps",
        state: "tempsPages",
        icon: "mdi mdi-timer-sand",
      },
      {
        collapse: true,
        path: "/support/statistique",
        name: "Statistiques",
        state: "statistiquesPages",
        icon: "mdi mdi-chart-bar",
      },
    ];

    let child = [];

    let childStat = [];
    let pathStat = [
      "/support/statistique/ticket",
      "/support/statistique/client",
      "/support/statistique/duree",
      "/support/statistique/cloture",
    ];
    let nameStat = ["Ticket", "Client", "Durée", "Clôture"];
    const componentStats = [
      StatistiqueTicket,
      StatistiqueClient,
      StatistiqueDuree,
      StatistiqueCloture,
    ];

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null) {
      child.push({
        path: "/support/gestion-du-temps/tous",
        name: "Tous les techniciens",
        mini: "B",
        component: GestionTemps,
      });

      InitialiseTabTechnicien().then((tech) => {
        for (let i = 0; i < tech.length; i++) {
          child.push({
            path: `/support/gestion-du-temps/${tech[i].id}`,
            name: `${tech[i].prenom}`,
            mini: "B",
            component: GestionTemps,
          });
        }
      });
    }

    for (let i = 0; i < pathStat.length; i++) {
      childStat.push({
        path: pathStat[i],
        name: nameStat[i],
        mini: "B",
        component: componentStats[i],
      });
    }

    menu[0].child = child;
    menu[1].child = childStat;

    this.setState({ menu, isLoaded: true });
  }

  displaySideBarAdmin() {
    let menu = [
      {
        collapse: true,
        path: "/support/ticket",
        name: "Tickets",
        state: "ticketPages",
        icon: "fas fa-ticket-alt",
      },
      {
        collapse: true,
        path: "/support/gestion-du-temps",
        name: "Gestion du temps",
        state: "tempsPages",
        icon: "mdi mdi-timer-sand",
      },
      {
        collapse: true,
        path: "/support/statistique",
        name: "Statistiques",
        state: "statistiquesPages",
        icon: "mdi mdi-chart-bar",
      },
    ];

    let childTemps = [];
    let childStat = [];
    let childTicket = [];
    const pathTickets = [
      "/support/ticket/tous",
      "/support/ticket/en-attente",
      "/support/ticket/ouvert",
      "/support/ticket/attente-de-retour",
      "/support/ticket/cloture",
      "/support/ticket/en-cours",
      "/support/ticket/en-analyse",
      "/support/ticket/planifie",
    ];
    const componentsTickets = [
      TicketAllSupport,
      TicketEnAttenteSupport,
      TicketOuvertSupport,
      TicketEnAttenteDeRetourSupport,
      TicketClotureSupport,
      TicketEnCoursSupport,
      TicketEnAnalyseSupport,
      TicketPlanifieSupport,
    ];
    const pathStat = [
      "/support/statistique/ticket",
      "/support/statistique/client",
      "/support/statistique/duree",
      "/support/statistique/cloture",
    ];
    const nameStat = ["Ticket", "Client", "Durée", "Clôture"];
    const componentStats = [
      StatistiqueTicket,
      StatistiqueClient,
      StatistiqueDuree,
      StatistiqueCloture,
    ];

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (currentUser != null) {
      var tabTypeStatut = InitialiseTabStatut();
      childTicket.push({
        path: pathTickets[0],
        name: "Tous les tickets",
        mini: "B",
        component: componentsTickets[0],
      });
      for (let i = 0; i < tabTypeStatut.length; i++) {
        ticketService
          .RecupereTicketsParStatut(tabTypeStatut[i].statut)
          .then((ticket) => {
            childTicket.push({
              path: pathTickets[i + 1],
              name: `${tabTypeStatut[i].statut} (${ticket.resource.length})`,
              mini: "B",
              component: componentsTickets[i + 1],
            });
            if (childTicket.length === 5) {
              this.setState({ isLoaded: true });
              window.addEventListener("load", this.updateDimensions);
              window.addEventListener("resize", this.updateDimensions);
            }
          });
      }
      childTemps.push({
        path: "/support/gestion-du-temps/tous",
        name: "Tous les techniciens",
        mini: "B",
        component: GestionTemps,
      });

      InitialiseTabTechnicien().then((tech) => {
        for (let i = 0; i < tech.length; i++) {
          childTemps.push({
            path: `/support/gestion-du-temps/${tech[i].id}`,
            name: `${tech[i].prenom}`,
            mini: "B",
            component: GestionTemps,
          });
        }
      });
      for (let i = 0; i < pathStat.length; i++) {
        childStat.push({
          path: pathStat[i],
          name: nameStat[i],
          mini: "B",
          component: componentStats[i],
        });
      }
      menu[0].child = childTicket;
      menu[1].child = childTemps;
      menu[2].child = childStat;
      this.setState({ menu, isLoaded: true });
    }
  }

  render() {
    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    const { isLoaded, menu } = this.state;

    if (!isLoaded) {
      return <Chargement />;
    } else {
      return (
        <div
          id="main-wrapper"
          dir={this.props.settings.activeDir}
          data-theme={this.props.settings.activeTheme}
          data-layout={this.props.settings.activeThemeLayout}
          data-sidebartype={this.props.settings.activeSidebarType}
          data-sidebar-position={this.props.settings.activeSidebarPos}
          data-header-position={this.props.settings.activeHeaderPos}
          data-boxed-layout={this.props.settings.activeLayout}
        >
          {/*--------------------------------------------------------------------------------*/}
          {/* Header                                                                         */}
          {/*--------------------------------------------------------------------------------*/}
          <Header />
          {/*--------------------------------------------------------------------------------*/}
          {/* Sidebar                                                                        */}
          {/*--------------------------------------------------------------------------------*/}
          <Sidebar {...this.props} routes={menu} />
          {/*--------------------------------------------------------------------------------*/}
          {/* Page Main-Content                                                              */}
          {/*--------------------------------------------------------------------------------*/}
          <div className="page-wrapper d-block">
            <div className="page-content container-fluid">
              <Switch>
                <Route
                  path="/client/ticket/creation"
                  component={TicketCreation}
                />
                <Route
                  path="/client/ticket/information/:id"
                  render={(props) => (
                    <InformationTicketClient id={props.match.params.id} />
                  )}
                />
                <Route
                  path="/support/ticket/tous"
                  render={(props) => (
                    <TicketAllSupport
                      clientId={
                        props.location.state !== undefined
                          ? props.location.state.clientId
                          : "Tous"
                      }
                    />
                  )}
                />
                <Route
                  path="/support/ticket/information/:id"
                  render={(props) => (
                    <InformationTicketSupport
                      id={props.match.params.id}
                      client={
                        props.location.state !== undefined
                          ? props.location.state.client
                          : "Tous"
                      }
                      type={
                        props.location.state !== undefined
                          ? props.location.state.type
                          : ""
                      }
                    />
                  )}
                />
                <Route
                  path="/support/gestion-du-temps/:id"
                  render={(props) =>
                    props.match.params.id === "tous" ? (
                      props.location.state !== undefined &&
                      props.location.state.type === "tempsDev" ? (
                        <GestionTemps
                          utilisateurId={props.match.params.id}
                          type="tempsDev"
                        />
                      ) : (
                        <GestionTemps utilisateurId={props.match.params.id} />
                      )
                    ) : (
                      <GestionTemps
                        utilisateurId={props.match.params.id}
                        type="tempsDev"
                      />
                    )
                  }
                />
                {menu.map((prop, key) => {
                  if (prop.navlabel) {
                    return null;
                  } else if (prop.collapse) {
                    return prop.child.map((prop2, key2) => {
                      if (prop2.collapse) {
                        return prop2.subchild.map((prop3, key3) => {
                          return (
                            <Route
                              path={prop3.path}
                              component={prop3.component}
                              key={key3}
                            />
                          );
                        });
                      }
                      return (
                        <Route
                          path={prop2.path}
                          component={prop2.component}
                          key={key2}
                        />
                      );
                    });
                  } else if (prop.redirect) {
                    return (
                      <Redirect from={prop.path} to={prop.pathTo} key={key} />
                    );
                  } else {
                    return (
                      <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                  }
                })}
              </Switch>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default connect(mapStateToProps)(Fulllayout);
