import React from 'react';
import { Row, Col } from 'reactstrap';
import BackButton from "../../../components/General/BackButton";
import FormTicketCreation from "../../../components/Ticket/FormTicketCreation";
import Informations from "../../../components/Ticket/Informations";
import RecupereTypeSocieteId from "../../../components/Fonctions/GestionToken/RecupereTypeSocieteId"
import {utilisateurService} from '../../../jwt/_services/utilisateur.service'
import Chargement from '../../../components/Chargement/Chargement';
import RecupereUtilisateurId from '../../../components/Fonctions/GestionToken/RecupereUtilisateurId'

class TicketCreationClient extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      client: '',
      isLoading: true
    }
    this.utilisateurId = RecupereUtilisateurId()
    this.typeSocieteId = RecupereTypeSocieteId()
  }

  componentDidMount(){
    this.RecupereUtilisateurParId()
  }

  RecupereUtilisateurParId(){
      utilisateurService.RecupereUtilisateurProfilParId(this.utilisateurId).then(
          (utilisateur) => {
              this.setState({
                  client: utilisateur
              },()=>{
                this.setState({
                  isLoading: false
                })
              })
          }
      ) 
    }
  
  render() {
    return (
      <div>
      {this.state.isLoading ? <Chargement /> : 
        <>
          <BackButton location={this.props.location} path="/client/ticket/tous" />
          <Row>
            <Col lg="8" md="12">
              <FormTicketCreation typeSocieteId={this.typeSocieteId}/>
            </Col>
            <Col lg="4" md="12">
              <Informations infoUtil={this.state.client} header="Mes informations" ticketId="creation"/>
            </Col>
          </Row>
        </>
      }
      </div>
    );
  }
}

export default TicketCreationClient;
