import React, { Component } from 'react'
import {Row, Col, Input} from 'reactstrap'
import {ticketService} from '../../jwt/_services/ticket.service'
import FormGroup from 'reactstrap/lib/FormGroup'
import Swal from "sweetalert2";

export default class CardNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            note: '',
            newNote: '',
            send: false,
            display: true
        }
    }
    componentDidMount(){
        this.getNoteByTicketId()
    }
    getNoteByTicketId(){
        ticketService.RecupereTicketsParId(this.props.ticketId).then(
            (ticket) => {
                this.setState({
                    note: ticket.resource.note,
                    newNote: ticket.resource.note
                })
            }, () => {
                
            }
        )
    }
    
    handleArea = (e) => {
        this.setState({
            newNote: e.target.value,
            send: false
        },() => {
            if(this.state.note === null && this.state.newNote === "" ){
                this.getNoteByTicketId()
            }
        })
    }

    saveNoteTicket = () => {
        ticketService.ModifierNoteTicket(this.props.ticketId, this.state.newNote).then(
            () => {
                Swal.fire({title : "Opération réussie !", text: "La note a bien été modifiée", icon: "success"}).then((resultat) => { if(resultat) {
                    this.setState({ send: true})}
                    this.getNoteByTicketId()
                });
                
            }, (error) => {
                Swal.fire({
                    icon: "error",
                    title: "Une erreur est survenue !",
                    text: error.messages
                });
            }
        )
    }
    render() {
        return (
            <Row>
                <Col xs="12">
                    <div className="d-flex flex-wrap mt-3">
                        <div className="p-2 header titleHeaderInfo">
                            <div>
                                <p className="cursor ml-4" onClick={() => {this.setState({display: !this.state.display})}}>Note {this.state.display ? <i className="fas fa-chevron-down chevronDown float-right mr-2" ></i> : <i className="fas fa-chevron-up float-right" ></i>}</p>
                            </div>
                        </div>
                        {this.state.display && 
                            <div className="note roundedBottom">
                                <FormGroup className="mt-2 mx-2">
                                    <Input type="textarea" rows="4" className="textarea" placeholder="Écrivez une note" value={this.state.newNote !== null ? this.state.newNote : "" } onChange={this.handleArea} />
                                </FormGroup>
                                {!this.state.send && (this.state.note !== this.state.newNote) && <p className="btn btnValiderNote mr-1 float-right" onClick={this.saveNoteTicket}>Valider</p>}
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        )
    }
}
