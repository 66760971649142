import {authHeader, handleResponse } from '../_helpers';


export const moduleService = {
    // (GET)
    RecupereModules,

};

function RecupereModules(){
    const requestOptions = {method: "GET",headers: authHeader(true)};
    let url = process.env.REACT_APP_API + "/modules/";

    return fetch(url, requestOptions)
                .then(handleResponse)
                .then((reponse) => {
                    return reponse;
                });
}
