import React, { Component } from 'react';
import {Row, Col,Modal,ModalHeader, ModalBody,Label,FormGroup} from 'reactstrap'
import {ticketService} from '../../jwt/_services/ticket.service'
import dateFormat from 'dateformat'
import Swal from "sweetalert2";
import InitialiseTabStatut from '../Fonctions/InitialiseTabStatut'
import {utilisateurService} from '../../jwt/_services/utilisateur.service'
import {sousCategorieService} from '../../jwt/_services/sousCategorie.service'
import {categorieService} from '../../jwt/_services/categorie.service'
import {moduleService} from '../../jwt/_services/module.service'

class CardInformationTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticket: props.ticket,
            support: null,
            select: {},
            send: false,
            modal: false,
            modalTechnicien: false,
            modalTimer: false,
            resetModal: false,
            categories: 0,
            sousCategories: 0,
            categorieId: 0,
            labelCategorie: "",
            sousCategorieId: 0,
            labelSousCategorie: "",
            time: props.ticket !== undefined ? props.ticket.tempsTechnicien : 0,
            modules:[],
            moduleId:0,
            oldTime: 0,
            idInterval: 0,
            display: true
        }
        this.selectTicketsStatut = InitialiseTabStatut()   
        this.colourStyles = {option: (styles, { isFocused }) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? "gray" : null,
                    color: "#333333"
                };
            }
        };   
    }
    componentDidMount(){
        this.RecupereModules();
        this.RecupereCategories();
        this.RecupereSousCategories();
        this.RecupereTicketsParId();
        this.RecupereUtilisateurSupport();
    }

    componentWillUnmount() {
        clearInterval(this.state.idInterval);
    }

    RecupereTicketsParId = () => {
        ticketService.RecupereTicketsParId(this.props.ticketId).then(
            (ticket) => {
                this.setState({
                    ticket: ticket.resource,
                    select  : {statut: ticket.resource.statut, technicien: ticket.resource.technicienId, facturation: ticket.resource.facturation, priorite: ticket.resource.priorite,module: ticket.ressource?.moduleId  ? ticket.ressource?.moduleId : this.state.modules[0] !== undefined ? this.state.modules[0].id : 0,categorie: ticket.resource.categorieId !== null ? ticket.resource.categorieId : this.state.categories[0] !== undefined ? this.state.categories[0].id : [],sousCategorie: ticket.resource.sousCategorieId === undefined ? ticket.resource.sousCategorieId : (this.state.sousCategories[0] !== null && this.state.sousCategories[0] !== undefined) ? this.state.sousCategories[0].id : 0}
                },()=> {
                    this.setState({
                        categorieId: this.state.ticket.categorieId !== null ? this.state.ticket.categorieId : 0,
                        sousCategorieId: this.state.ticket.sousCategorieId !== null ?  this.state.ticket.sousCategorieId : 0,
                        moduleId: this.state.ticket.moduleId ? this.state.ticket.moduleId : 0,
                    },() => {
                        if(JSON.parse(localStorage.getItem("timerStart")) !== null && JSON.parse(localStorage.getItem("timerStart")) === this.state.ticket.id){
                            ticketService.ModifierDateChronometreTicket(this.state.ticket.id,false,false).then(
                                (ticket) => {
                                    if(ticket.resource.tempsTechnicien !== null){
                                        this.setState({
                                            idInterval: 1,
                                            time : ticket.resource.tempsTechnicien
                                        },()=> {
                                            this.start()
                                        })
                                    }   
                                },(error) => {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Une erreur est survenue !",
                                        text: error.messages
                                    });
                                }
                            )
                        }else{
                            if(this.state.ticket.tempsTechnicien !== null){
                                this.setState({
                                    time : this.state.ticket.tempsTechnicien
                                })
                            }  
                        } 
                    })
                                                            
                })
            }, (error) => {
                Swal.fire({
                    icon: "error",
                    title: "Une erreur est survenue !",
                    text: error.messages
                });
            }
        )
    }

    RecupereUtilisateurSupport = () => {
        utilisateurService.RecupereUtilisateurPourTousRoleExcepteRoleId(2).then(
            (utilisateur) => {
                this.setState({
                    support: utilisateur
                })
            }
        )
    } 

    RecupereCategories = () => {
        categorieService.RecupereCategories().then(
            (categories) => {
                this.setState({
                    categories: categories.resource
                })
            }
        )
    }

    RecupereSousCategories = () => {
        sousCategorieService.RecupereSousCategories().then(
            (sousCategories) => {
                this.setState({
                    sousCategories: sousCategories.resource
                })
            }
        )
    }

    RecupereModules = () => {
        moduleService.RecupereModules().then(
            (modules) => {
                this.setState({
                    modules: modules.resource
                })
            }
        )
    }

    handleChange = (e) => {
        let select = this.state.select;
        if(e.target.value === "Tous") {
            select[e.target.name] = null
        }else{
            select[e.target.name] = e.target.value
        }
        this.setState({
            select: select,
            send: false
        })
    }

    handleCategorieChange = (e) => {
        let select = this.state.select;
        select["categorie"] = e.target.value
        this.setState({
            select: select
        })
    }

    handleSousCategorieChange = (e) => {
        let select = this.state.select;
        select["sousCategorie"] = e.target.value
        this.setState({
            select: select
        })
    }

    handleModuleChange = (e) => {
        let select = this.state.select;
        select["module"] = e.target.value
        this.setState({
            select: select
        })
    }

    editTicket = () => {
        if(this.state.select["statut"] === "Clôture"){
            this.openModal()
        }else if(this.state.select["statut"] === "En cours"){
            this.openModalTechnicien();
        }else{
            ticketService.ModifierInformationTicket(this.props.ticketId,this.state.select["statut"],this.state.select["technicien"],this.state.select["facturation"],this.state.select["priorite"],0,0).then(
                () => {
                    this.setState({
                        send:true,
                        idInterval: 0
                    }, () => {
                        Swal.fire({title : "Opération réussie !", text: "Les informations ont été modifiées", icon: "success"}).then((resultat) => 
                        { 
                            if(resultat) {
                                if((this.state.ticket.statut === "Clôture" && this.state.select["statut"] !== "Clôture") ||(this.state.ticket.statut !== "Clôture" && this.state.select["statut"] === "Clôture")){
                                    window.location.reload()
                                }
                                this.RecupereTicketsParId()
                            }
                        });
                        
                    })
                    
                }
            )
        }  
    }


    openModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    openModalTimer = () => {
        this.setState({
            modalTimer: !this.state.modalTimer,
            oldTime: this.state.time
        });
    }

    resetModal = () => {
        this.setState({
            resetModal: !this.state.resetModal
        });
    }

    validerTimer = () => {
        ticketService.ModifierTempsTechnicienTicket(this.state.ticket.id,this.state.time).then(
            () => {
                this.setState({
                    modalTimer: !this.state.modalTimer
                });
            }
        )
        
    }


    sendCloture = () => {
        let error = false;
        if(this.state.categories.length === 0){
             Swal.fire({icon: "error",
                        title: "Une erreur est survenue !",
                        text: "Veuillez contacter votre administrateur afin d'ajouter une catégorie de clôture !"
            })
            error = true;
        }
        if(this.state.select["categorie"] === 0 && this.state.select["sousCategorie"] === 0 ){
            Swal.fire({icon: "error",
                        title: "Une erreur est survenue !",
                        text: "Veuillez choisir une catégorie et sous-catégorie de clôture !"
            })
            error = true;
        }else{
            if(this.state.select["categorie"] === 0){
                Swal.fire({icon: "error",
                        title: "Une erreur est survenue !",
                        text: "Veuillez choisir une catégorie de clôture !"
                })
                error = true;
            }else if(this.state.select["sousCategorie"] === 0 && this.state.ticket.client.typeBaseDonnees === "APSCHOOL"){
                Swal.fire({icon: "error",
                        title: "Une erreur est survenue !",
                        text: "Veuillez choisir une sous-catégorie de clôture !"
                })
                error = true;
            }
        }
        if (this.state.select["module"] === 0) {
            Swal.fire({
                icon: "error",
                title: "Une erreur est survenue !",
                text: "Veuillez attribuer ce ticket à un module !"
            })
            error = true;
        }
        if(!error){
            ticketService.ModifierInformationTicket(this.props.ticketId,this.state.select["statut"],this.state.select["technicien"],this.state.select["facturation"],this.state.select["priorite"],this.state.select["categorie"],this.state.select["sousCategorie"],this.state.select["module"]).then(
                () => {
                    this.setState({
                        send:true,
                        idInterval: 0
                    }, () => {
                        
                        Swal.fire({title : "Opération réussie !", text: "Les informations ont été modifiées", icon: "success"}).then((resultat) => 
                        { 
                            if(resultat) {
                                if((this.state.ticket.statut === "Clôture" && this.state.select["statut"] !== "Clôture") ||(this.state.ticket.statut !== "Clôture" && this.state.select["statut"] === "Clôture")){
                                    window.location.reload()
                                }else if(this.state.ticket.statut === "Clôture" && this.state.select["statut"] === "Clôture"){
                                    this.setState({
                                        send: false
                                    },()=>{
                                        this.openModal()
                                    })
                                }
                                this.RecupereTicketsParId()
                            }
                        });
                    })
                }
            )
        }
        
    }

    start = () => {
        const id = setInterval(() => {
            this.setState({
                time: this.state.time + 1000
            });
        }, 1000);

        this.setState({
            idInterval: id
        });

    };

    startOrPause = () => {
        if(JSON.parse(localStorage.getItem("timerStart")) === null || JSON.parse(localStorage.getItem("timerStart")) === this.state.ticket.id){
            if (this.state.idInterval === 0) {
                localStorage.setItem("timerStart", this.state.ticket.id);
                ticketService.ModifierDateChronometreTicket(this.state.ticket.id,true,false).then(
                    () => {
                        this.start();
                    }, (error) => {
                        Swal.fire({
                            icon: "error",
                            title: "Une erreur est survenue !",
                            text: error.messages
                        });
                    }
                )
            } else {
                localStorage.removeItem("timerStart");
                ticketService.ModifierDateChronometreTicket(this.state.ticket.id,false,false).then(
                    (ticket) => {
                        clearInterval(this.state.idInterval);
                        this.setState({
                            idInterval: 0,
                            time: ticket.resource.tempsTechnicien

                        });
                    }, (error) => {
                        Swal.fire({
                            icon: "error",
                            title: "Une erreur est survenue !",
                            text: error.messages
                        });
                    }
                )
            }    
        }else{
            Swal.fire({
                icon: "error",
                title: "Une erreur est survenue !",
                text: `Veuillez mettre le ticket ${JSON.parse(localStorage.getItem("timerStart"))} en pause avant de commencer un autre !`
            });
        }
            
    };

    reset = () => {
        ticketService.ModifierDateChronometreTicket(this.state.ticket.id,false,true).then(
            () => {
                clearInterval(this.state.idInterval);
                this.setState({
                    time: 0,
                    idInterval: 0
                },()=>{
                    this.resetModal()
                });
            }, (error) => {
                Swal.fire({
                    icon: "error",
                    title: "Une erreur est survenue !",
                    text: error.messages
                });
            }
        )
        
    };

    openModalTechnicien = () => {
        this.setState({
            modalTechnicien: !this.state.modalTechnicien
        })
    }

    sendTechnicien = () => {
        ticketService.ModifierInformationTicket(this.props.ticketId,this.state.select["statut"],this.state.select["technicien"],this.state.select["facturation"],this.state.select["priorite"],0,0).then(
            () => {
                this.setState({
                    send:true,
                    idInterval: 0
                }, () => {
                    Swal.fire({title : "Opération réussie !", text: "Les informations ont été modifiées et l'email a bien été envoyé", icon: "success"}).then((resultat) => 
                    { 
                        if(resultat.isConfirmed) {
                            this.RecupereTicketsParId();
                            this.openModalTechnicien();
                        }
                    });
                    
                })
                
            }
        )
    }

    render() {
        let ms = this.state.time;
        let second = String(Math.floor(ms / 1000));
        let minute = String(Math.floor((second % 3600) / 60));
        let heure = String(Math.floor(second / 3600));
        let seconde = String(Math.floor(second % 60)); 
        return (
            <Row>
                <Col xs="12">
                    <div className="d-flex flex-wrap mt-3">
                        <div className="p-2 header titleHeaderInfo">
                            <div>
                                <p className="cursor ml-4" onClick={() => {this.setState({display: !this.state.display})}}>Informations du ticket {this.state.display ? <i className="fas fa-chevron-down chevronDown float-right mr-2" ></i> : <i className="fas fa-chevron-up float-right" ></i>}
                                </p>
                            </div>
                        </div>
                        {this.state.display && 
                        <>
                        {this.props.type === "client" ? 
                            <div className="informationsTicket">
                                <div className="mt-3">
                                    {this.state.ticket !== null && this.state.ticket !== undefined && (this.state.ticket.statut === "En attente" ?
                                    <p className="badge badge-pill bg-warning text-white">{ this.state.ticket.statut}</p> :
                                    this.state.ticket.statut === "Clôture" ? <p className="badge badge-pill bg-success text-white">{this.state.ticket.statut}</p> :
                                    this.state.ticket.statut === "Ouvert" ? <p className="badge badge-pill bg-danger text-white">{this.state.ticket.statut}</p> :
                                    this.state.ticket.statut === "En cours" ? <span className="badge badge-pill bg-secondary text-white">{this.state.ticket.statut}</span>:
                                    <p className="badge badge-pill bg-primary text-white">{this.state.ticket.statut}</p>)}
                                    {this.state.ticket !== null && this.state.ticket !== undefined && <p>{dateFormat(this.state.ticket.dateCreation,"dd/mm/yyyy HH:MM")}</p>}
                                    <p>Priorité : {this.state.ticket !== null && this.state.ticket !== undefined && (this.state.ticket.priorite === "Haute" ? <span className="badge badge-pill bg-danger text-white">{this.state.ticket.priorite}</span> : this.state.ticket.priorite === "Moyenne" ? <span className="badge badge-pill bg-warning text-white">{this.state.ticket.priorite}</span> : <span className="badge badge-pill bg-successV2 text-white">{this.state.ticket.priorite}</span>)}</p>
                                </div>
                            </div>
                        : <div className="informationsTicket roundedBottom">
                                <div className="mt-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="ml-2">
                                            {this.state.ticket !== null &&
                                                <select className="custom-select" name="statut" defaultValue={this.state.ticket.statut} onChange={this.handleChange} >
                                                    {this.selectTicketsStatut.length !== 0 && this.selectTicketsStatut.map((statut,i) => {
                                                        return <option key={i} >{statut.statut}</option> 
                                                    })}
                                                </select>
                                            }
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mr-2">
                                            {this.state.select["technicien"] !== undefined && 
                                                <select className="custom-select" name="technicien" value={this.state.select["technicien"]} onChange={this.handleChange}>
                                                    <option>Tous</option>
                                                    {this.state.support !== null && this.state.support.map((tech,i) =>{
                                                        return <option key={i} value={tech.id}>{tech.prenom}</option> 
                                                    }) }
                                                </select>
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="ml-2 mt-2 mb-2">
                                             {this.state.ticket !== null && 
                                                <select className="custom-select" name="facturation" defaultValue={this.state.ticket.facturation} onChange={this.handleChange}>
                                                    <option>Non-facturé</option>
                                                    <option>A facturer</option>
                                                    <option>Facturé</option>
                                                </select>
                                             }
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mt-2 mb-2 mr-2">
                                             {this.state.ticket !== null && 
                                                <select className="custom-select" name="priorite" defaultValue={this.state.ticket.priorite} onChange={this.handleChange}>
                                                    <option>Faible</option>
                                                    <option>Moyenne</option>
                                                    <option>Haute</option>
                                                </select>
                                             }
                                            </div>
                                        </Col> 
                                        <Col lg={12}>
                                            <div className="chrono">
                                                <p>{heure.padStart(2, "0")}</p>
                                                <p>:</p>
                                                <p>{minute.padStart(2, "0")}</p>
                                                <p>:</p>
                                                <p>{seconde.padStart(2, "0")}</p>
                                            </div>
                                            <div className="time">
                                                <p className="heure"> Heures </p>
                                                <p className="minute"> Minutes </p>
                                                <p className="seconde"> Secondes </p>
                                            </div>
                                            <div className="button">
                                                <button className="up" onClick={this.startOrPause}>
                                                   {this.state.idInterval !== 0 ? "Arrêter" : "Commencer"}
                                                </button>
                                                <button className="down" onClick={this.resetModal}>
                                                    Réinitialiser
                                                </button>
                                                <button className="edit" onClick={this.openModalTimer}>
                                                    Modifier
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {this.state.ticket !== null && this.state.select !== undefined && !this.state.send && ((this.state.select["statut"] !== this.state.ticket.statut) 
                                    || (this.state.select["technicien"] !== this.state.ticket.technicienId)
                                    || (this.state.select["priorite"] !== this.state.ticket.priorite)
                                    || (this.state.select["facturation"] !== this.state.ticket.facturation)
                                    || (this.state.select["statut"] === "Clôture"))
                                    && <p className="btn btnValiderNote mr-1 float-right" onClick={this.editTicket}>{this.state.ticket.statut === "Clôture" && this.state.select["statut"] === "Clôture" ? "Modifier" : "Valider"}</p>
                                     }
                                </div>
                            </div>
                        }
                        </>
                        }
                         <Modal isOpen={this.state.modal} toggle={this.openModal} size="lg" className="Modal" >
                            <ModalHeader toggle={this.openModal}>
                                {this.state.ticket !== null &&  this.state.ticket !== undefined && this.state.ticket.client.typeBaseDonnees !== "APSCHOOL" ? (<p className="h3">Choix de la catégorie et du module de clôture</p>) : (<p className="h3">Choix des catégories et du module de clôture</p>)}   
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label>Catégorie :</Label>
                                            {this.state.categories !== 0 &&
                                                <select className="custom-select" name="categorie" defaultValue={this.state.categorieId} onChange={this.handleCategorieChange}>
                                                    {this.state.categories.map((cat,i) =>{
                                                            return <option key={i} value={cat.id}>{cat.nom}</option> 
                                                        }) 
                                                    }
                                                </select>
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            {this.state.ticket !== null && this.state.ticket !== undefined && this.state.ticket.client.typeBaseDonnees === "APSCHOOL" && this.state.sousCategories !== null &&
                                            <>
                                                <Label>Sous-Catégorie :</Label>
                                                {this.state.sousCategories !== 0 &&
                                                    <select className="custom-select" name="sousCategorie" defaultValue={this.state.sousCategorieId} onChange={this.handleSousCategorieChange}>
                                                        {this.state.sousCategories.map((sousCat,i) =>{
                                                                return <option key={i} value={sousCat.id}>{sousCat.nom}</option> 
                                                            }) 
                                                        }
                                                    </select>
                                                }
                                            </>
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col lg={12}>
                                        
                                        <FormGroup>
                                            {this.state.ticket && this.state.ticket.client.typeBaseDonnees === "APSCHOOL" && this.state.modules !== null &&
                                            <>
                                                <Label>Module concerné :</Label>
                                                <select className="custom-select" name="sousCategorie" defaultValue={this.state.moduleId} onChange={this.handleModuleChange}>
                                                    {this.state.modules.map((module,i) =>{
                                                            return <option key={i} value={module.id}>{module.nom}</option> 
                                                        }) 
                                                    }
                                                </select>
                                            </>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="text-right">
                                        <p onClick={this.sendCloture} className="btn btn-success cursor text-white">Valider</p>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.modalTimer} toggle={this.openModalTimer} size="lg" className="Modal" >
                            <ModalHeader toggle={this.openModalTimer}>
                                   Modifier le chronomètre
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg={12} className="Countdown-display Countdown-displayTop">
                                        <button onClick={() => {this.setState({time: this.state.time + 3600000})}}>&#8679;</button>
                                        <button onClick={() => {this.setState({time: this.state.time + 60000})}}>&#8679;</button>
                                        <button onClick={() => {this.setState({time: this.state.time + 1000})}}>&#8679;</button>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="chronoModal">
                                            <p>{heure.padStart(2, "0")}</p>
                                            <p>:</p>
                                            <p>{minute.padStart(2, "0")}</p>
                                            <p>:</p>
                                            <p>{seconde.padStart(2, "0")}</p>
                                        </div>
                                    </Col>
                                    <Col lg={12} className="Countdown-display Countdown-displayBot">
                                        <button onClick={() => {if(this.state.time > 3600000)this.setState({time: this.state.time - 3600000})}}>&#8681;</button>
                                        <button onClick={() => {if(this.state.time > 60000)this.setState({time: this.state.time - 60000})}}>&#8681;</button>
                                        <button onClick={() => {if(this.state.time >= 1000)this.setState({time: this.state.time - 1000})}}>&#8681;</button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="text-right">
                                        {this.state.time !== this.state.oldTime && <p onClick={this.validerTimer} className="btn btn-success cursor text-white">Valider</p>}
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                         <Modal isOpen={this.state.resetModal} toggle={this.resetModal} size="lg" className="Modal" >
                            <ModalHeader toggle={this.resetModal}>
                                <p className="h3">Êtes-vous sûr ?</p>
                            </ModalHeader>
                            <ModalBody>
                            <div>
                                <span className="textDeco">Voulez-vous vraiment réinitialiser le chronomètre ? </span>
                            </div>
                            <div className="text-center">
                                <span onClick={this.reset} className="btn btnModalYes btnModal text-white">Oui</span>
                                <span onClick={this.resetModal} className="btn btnModalNo btnModal text-white">Non</span>
                            </div>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.modalTechnicien} toggle={this.openModalTechnicien} size="m" className="Modal" >
                            <ModalHeader toggle={this.openModalTechnicien}>
                                Choix du technicien   
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mr-2">
                                        {this.state.select["technicien"] !== undefined && 
                                            <select className="custom-select" name="technicien" defaultValue={this.state.select["technicien"]} onChange={this.handleChange}>
                                                <option>Tous</option>
                                                {this.state.support !== null && this.state.support.map((tech,i) =>{
                                                    return <option key={i} value={tech.id}>{tech.prenom}</option> 
                                                }) }
                                            </select>
                                        }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="text-right">
                                        <p onClick={() => this.sendTechnicien()} className="btn btn-success cursor text-white">Valider</p>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default CardInformationTicket;