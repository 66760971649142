import React, { Component } from 'react'
import TableauClient from '../../components/Statistiques/TableauClient'

export default class StatistiqueClient extends Component {
    constructor(props) {
        super(props);
        this.nameHeader = ["Type de société","Nom", "Prénom", "Téléphone","Mobile", "Email","Adresse","Total tickets"]
    }
    render() {
        return (
            <div>
                <TableauClient nameHeader={this.nameHeader} />
            </div>
        )
    }
}
