export default function RecupereNomBaseDonnee() {
  // On récupère le Token de l'utilisateur connecté
  const currentToken = JSON.parse(localStorage.getItem("currentUser")).token;

  // On récupère le payload du Token
  let base64Url = currentToken.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  // On retourne l'id du type de société de l'utilisateur connecté
  return JSON.parse(jsonPayload).TypeDB;
}