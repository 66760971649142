import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
import Informations from "../../../components/Ticket/Informations";
import CardInformationTicket from "../../../components/Ticket/CardInformationTicket";
import MessagerieTicket from "../../../components/Ticket/MessagerieTicket";
import BackButton from "../../../components/General/BackButton";
import Sticky from "react-sticky-el";
import RecupereUtilisateurId from "../../../components/Fonctions/GestionToken/RecupereUtilisateurId";
import { ticketService } from "../../../jwt/_services/ticket.service";
import Chargement from "../../../components/Chargement/Chargement";
import { utilisateurService } from "../../../jwt/_services/utilisateur.service";
import { messageService } from "../../../jwt/_services/message.service";
import { fichierService } from "../../../jwt/_services/fichier.service";

class InformationTicketClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket: null,
      messagesFromDb: [],
      messages: [],
      classCloture: "",
      insideClassCloture: "",
      fichiers: [],
      client: "",
      isLoading: true,
      redirect: false,
      messageIsLoading: true,
      fichiersIsLoading: true,
      message: "",
      typeSociete: "",
    };
    this.utilisateurId = RecupereUtilisateurId();
  }

  handleCallback = (childMessage) => {
    this.setState({ message: childMessage });
  };

  // Recuperer le ticket en fonction de l'id props du ticket afin de regarder si l'id utilisateur client est pareil et donc affiché ou non les informations
  RecupereTicketParId() {
    ticketService.RecupereTicketsParId(this.props.id).then((ticket) => {
      this.setState(
        {
          ticket: ticket.resource,
        },
        () => {
          if (this.state.ticket == null) {
            this.setState({
              redirect: true,
            });
          } else {
            utilisateurService
              .RecupereUtilisateurProfilParId(this.state.ticket.clientId)
              .then((utilisateur) => {
                this.setState(
                  {
                    client: utilisateur,
                  },
                  () => {
                    if (
                      this.state.client.typeSociete !== null &&
                      this.state.client.typeSociete !== undefined
                    ) {
                      switch (this.state.client.typeSociete.id) {
                        case 1:
                          this.setState(
                            {
                              typeSociete: "APSCHOOL",
                            },
                            () => {
                              this.setState({
                                isLoading: false,
                              });
                            }
                          );
                          break;
                        case 2:
                          this.setState(
                            {
                              typeSociete: "APCHANTIER",
                            },
                            () => {
                              this.setState({
                                isLoading: false,
                              });
                            }
                          );
                          break;
                        case 3:
                          this.setState(
                            {
                              typeSociete: "APSELF",
                            },
                            () => {
                              this.setState({
                                isLoading: false,
                              });
                            }
                          );
                          break;
                        default:
                          break;
                      }
                    }
                  }
                );
              });
            messageService
              .RecupereTousMessagesParTicket(this.props.id)
              .then((message) => {
                this.setState(
                  {
                    messagesFromDb: message.resource,
                  },
                  () => {
                    let messages = message.resource;
                    this.state.messagesFromDb.map((message, index) => {
                      messages[index].typeUtilisateur =
                        parseInt(this.utilisateurId) ===
                        parseInt(message.utilisateurId)
                          ? "emetteur"
                          : "destinataire";
                      messages[index].utilisateurId = message.utilisateurId;
                      return messages[index];
                    });
                    messages.sort((a, b) => {
                      return (
                        new Date(b.dateCreation) - new Date(a.dateCreation)
                      );
                    });

                    this.setState({ messages }, () => {
                      this.setState({
                        messageIsLoading: false,
                      });
                      this.RecupereTousFichiersParMessage();
                    });
                  }
                );
              });
            this.state.ticket.statut === "Clôture"
              ? this.setState({ classCloture: "listMessagesCloture" })
              : this.setState({ classCloture: "listMessages" });
            this.state.ticket.statut === "Clôture"
              ? this.setState({
                  insideClassCloture: "insideMessagerieCloture",
                })
              : this.setState({ insideClassCloture: "insideMessagerie" });
          }
        }
      );
    });
  }

  RecupereTousFichiersParMessage = () => {
    this.state.messages.map((message) => {
      fichierService.RecupereTousFichiersParMessage(message.id).then(
        (fichiers) => {
          if (fichiers !== null && fichiers !== undefined) {
            if (fichiers.resource.length > 0) {
              let fichiersState = this.state.fichiers;
              fichiers.resource.map((fichier) => fichiersState.push(fichier));
              this.setState({ fichiers: fichiersState }, () => {
                this.setState({ fichiersIsLoading: false });
              });
            }
          }
        },
        () => {}
      );
      return null;
    });
  };

  componentDidMount() {
    this.RecupereTicketParId();
  }

  render() {
    return (
      <div>
        {this.state.redirect && <Redirect to="/client/ticket/tous" />}
        {this.state.isLoading ? (
          <Chargement />
        ) : (
          this.state.ticket !== null && (
            <>
              <BackButton
                path="/client/ticket/tous"
                messages={this.state.message}
                informationClient="info"
              />

              <Row>
                <Col lg="8" md="12">
                  <MessagerieTicket
                    ticket={this.state.ticket}
                    fichiers={this.state.fichiers}
                    classCloture={this.state.classCloture}
                    insideClassCloture={this.state.insideClassCloture}
                    ticketId={this.props.id}
                    messages={this.state.messages}
                    messageCallback={this.handleCallback}
                    typeSociete={this.state.typeSociete}
                  />
                </Col>
                <Col lg="4" md="12">
                  <Sticky topOffset={-80} stickyStyle={{ top: 80 }}>
                    <CardInformationTicket
                      ticketId={this.props.id}
                      type="client"
                    />
                    <Informations
                      infoUtil={this.state.client}
                      ticket={this.state.ticket}
                      header="Mes informations"
                      ticketId={this.props.id}
                    />
                  </Sticky>
                </Col>
              </Row>
            </>
          )
        )}
      </div>
    );
  }
}

export default InformationTicketClient;
