import React, { Component } from 'react'
import {Card,CardBody,CardTitle,Table} from 'reactstrap'
import dateFormat from 'dateformat'
import { Redirect} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import retourneTechnicien from '../Fonctions/Utils/retourneTechnicien';
import retourneTexteRaccourci from '../Fonctions/Utils/retourneTexteRaccourci';

export default class TableauDuree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            id: '',
            tickets: [],
            currentSort: ""
        }
        this.header = ["N°","Nom de la société","Type de société","Prénom du client","Sujet","Priorité","Technicien","Date de création","Type de catégorie","Module","Total","Réponse support","Réponse client"]
    }

    componentDidMount(){
        this.setState({
            tickets: this.props.tickets.sort((a,b) => {
                            return a.tempsTotal - b.tempsTotal
                        })
        })
    }

    showInformationTicket = (id) => {
        this.setState({
            redirect: true,
            id: id
        })
    }

    sortTableByTotalTempsTickets = (type) => {
        const { currentSort,tickets } = this.state;
		let nextSort;

		if (currentSort === 'down') nextSort = 'up';
		else if (currentSort === 'up') nextSort = 'down';

		this.setState({
			currentSort: nextSort
		},() => {
            if(currentSort === 'down'){
                switch (type) {
                    case "total":
                        this.setState({
                            tickets: tickets.sort((a,b) => {
                                    return a.tempsTotal - b.tempsTotal
                                })
                        })
                        break;
                    case "support":
                        this.setState({
                            tickets: tickets.sort((a,b) => {
                                    return a.tempsSupport - b.tempsSupport
                                })
                        })
                        break;
                    case "client":
                        this.setState({
                            tickets: tickets.sort((a,b) => {
                                    return a.tempsClient - b.tempsClient
                                })
                        })
                        break;
                    default:
                        break;
                }
                
            }else if(currentSort === "up"){
                switch (type) {
                    case "total":
                        this.setState({
                            tickets: tickets.sort((a,b) => {
                                    return b.tempsTotal - a.tempsTotal
                                })
                        })
                        break;
                    case "support":
                        this.setState({
                            tickets: tickets.sort((a,b) => {
                                    return b.tempsSupport - a.tempsSupport
                                })
                        })
                        break;
                    case "client":
                        this.setState({
                            tickets: tickets.sort((a,b) => {
                                    return b.tempsClient - a.tempsClient
                                })
                        })
                        break;
                    default:
                        break;
                }
                
            }
        });
    }

    render() {
        const {tickets} = this.state
        return (
            <div>
                {this.state.redirect === true && <Redirect to={{pathname : `/support/ticket/information/${this.state.id}`, state: {type: 'duree'}}}/>}  
                <Card>
                    <CardBody className="bg-info-gradient rounded-top">
                        <CardTitle className="h4 mb-0 text-white">Liste des tickets</CardTitle>
                    </CardBody>
                    <Table className="mb-0 align-middle" hover responsive size={"sm"}>
                        <thead>
                            <tr>
                                {this.header.map((name,i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                {name === "Total" ? 
                                                    <th scope="col" className="cursor" onClick={() => this.sortTableByTotalTempsTickets("total")}>{name} <i className="fas fa-sort"></i></th>
                                                : name === "Réponse support" ? 
                                                    <th scope="col" className="cursor" onClick={() => this.sortTableByTotalTempsTickets("support")}>{name} <i className="fas fa-sort"></i></th>
                                                : name === "Réponse client" ? 
                                                    <th scope="col" className="cursor" onClick={() => this.sortTableByTotalTempsTickets("client")}>{name} <i className="fas fa-sort"></i></th>
                                                : <th scope="col">{name}</th>}
                                            </React.Fragment>
                                        )
                                    })}
                            </tr>
                        </thead>
                        <tbody>
                        {this.props.isLoading ? 
                            <>
                                {Array(7).fill().map((i,index) => (
                                    <tr key={index}>
                                        {Array(13).fill().map((i,index) => (
                                            <td key={index}>
                                                <Skeleton width={'100%'} duration={2} />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </> 
                        :
                        <>
                            {tickets !== null && tickets.map((ticket) => (
                                <React.Fragment key={ticket.id}>
                                    <tr className="cursor" onClick={() => this.showInformationTicket(ticket.id)}>
                                        <td className="text-center">{ticket.id}</td>
                                        <td className="text-center">{ticket.sousSociete}</td>
                                        <td className="text-center">{ticket.typeSociete}</td>
                                        <td className="text-center">{ticket.prenomClient}</td>
                                        <td className="text-center">{retourneTexteRaccourci(ticket.sujet)}</td>
                                        <td className="text-center h5">{ticket.priorite === "Haute" ? <span className="badge badge-pill bg-danger text-white">{ticket.priorite}</span> : ticket.priorite === "Moyenne" ? <span className="badge badge-pill bg-warning text-white">{ticket.priorite}</span> : <span className="badge badge-pill bg-successV2 text-white">{ticket.priorite}</span>}</td>
                                        <td className="text-center">{retourneTechnicien(ticket.prenomTechnicien)}</td>
                                        <td className="text-center">{dateFormat(ticket.dateCreation,"dd/mm/yyyy HH:MM")}</td>
                                        <td className="text-center">{ticket.typeCategorie}</td>
                                        <td className="text-center">{ticket.module}</td>
                                        <td className="text-center cursor">{`${String(Math.floor((ticket.tempsTotal/86400)))}j ${String(Math.floor((ticket.tempsTotal % 86400) / 3600))}h ${String(Math.floor(((ticket.tempsTotal % 86400) % 3600) / 60))}m ${String(Math.floor(((ticket.tempsTotal % 86400) % 3600) % 60) )}s`}</td>
                                        <td className="text-center cursor">{`${String(Math.floor((ticket.tempsSupport/86400)))}j ${String(Math.floor((ticket.tempsSupport % 86400) / 3600))}h ${String(Math.floor(((ticket.tempsSupport % 86400) % 3600) / 60))}m ${String(Math.floor(((ticket.tempsSupport % 86400) % 3600) % 60))}s`}</td>
                                        <td className="text-center cursor">{`${String(Math.floor((ticket.tempsClient/86400)))}j ${String(Math.floor((ticket.tempsClient % 86400) / 3600))}h ${String(Math.floor(((ticket.tempsClient % 86400) % 3600) / 60))}m ${String(Math.floor(((ticket.tempsClient % 86400) % 3600) % 60))}s`}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </>
                        }
                        </tbody>
                    </Table>
                </Card>
            </div>
        )
    }
}
