import React, { Component } from "react";
import classnames from "classnames";
import RecupererUtilisateurId from "../Fonctions/GestionToken/RecupereUtilisateurId";
import dateFormat from "dateformat";
import { ticketService } from "../../jwt/_services/ticket.service";
import RecupereTypeSocieteId from "../Fonctions/GestionToken/RecupereTypeSocieteId";
import InitialiseTabStatut from "../Fonctions/InitialiseTabStatut";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { utilisateurService } from "../../jwt/_services/utilisateur.service";

class TableauTicketClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      tickets: [],
      tabTickets: [],
      tabFilter: [],
      nomSociete: "",
      dateDebut: new Date(),
      dateFin: new Date(),
      statut: props.statut,
      priorite: "",
      redirect: false,
      id: 0,
      isLoading: true,
      utilisateurId: -1,
    };
    this.selectTicketsStatut = InitialiseTabStatut();
    this.selectTicketsPriorite = [
      { priorite: "Faible" },
      { priorite: "Moyenne" },
      { priorite: "Haute" },
    ];
    this.selectTicketsNomSociete = [];
    this.selectTicketsUtilisateur = [];
    this.utilisateurId = RecupererUtilisateurId();
    this.typeSocieteId = RecupereTypeSocieteId();
    this.colourStyles = {
      option: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "gray" : null,
          color: "#333333",
        };
      },
    };
    this.selectRef = {
      societe: null,
      type: null,
      priorite: null,
      statut: null,
      utilisateur: null,
    };
  }

  RecupereUtilisateursParEcole = () => {
    utilisateurService
      .RecupereUtilisateursParEcole(this.utilisateurId)
      .then((result) => {
        this.selectTicketsUtilisateur = result;
      });
  };

  RecupereTicketUtilisateurId = () => {
    /* On initialise les variables pour faire la recherche avec les filtres de bases et on remplit les listes de sélection */
    this.setState(
      {
        nomSociete: "Tous",
        utilisateurId: -1,
        dateDebut: null,
        dateFin: null,
        statut: this.props.statut,
        priorite: "Toutes",
      },
      () => {
        ticketService
          .RecupereTicketsParClientIdAvecFiltre(
            this.utilisateurId,
            this.state.nomSociete,
            this.state.statut,
            this.state.priorite,
            this.state.dateDebut,
            this.state.dateFin,
            this.state.utilisateurId
          )
          .then(
            (listTickets) => {
              this.setState(
                {
                  tickets: listTickets.resource,
                  tabFilter: listTickets.resource,
                },
                () => {
                  this.selectTicketsNomSociete = this.state.tabFilter.map(
                    (ticket) => ({
                      nom: ticket.sousSociete.nom,
                      adresse: ticket.sousSociete.adresse,
                    })
                  );
                  this.selectTicketsNomSociete = [
                    ...new Map(
                      this.selectTicketsNomSociete.map((item) => [
                        item["nom"],
                        item,
                      ])
                    ).values(),
                  ];
                  this.setState({ isLoading: false });
                }
              );
            },
            () => {
              this.setState({ isLoading: false });
            }
          );
      }
    );
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    } else {
      this.setState({ activeTab: "" });
    }
  }

  componentDidMount() {
    this.RecupereUtilisateursParEcole();
    this.RecupereTicketUtilisateurId();
  }

  RecupereTicketsParClientIdAvecFiltre = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        ticketService
          .RecupereTicketsParClientIdAvecFiltre(
            this.utilisateurId,
            this.state.nomSociete,
            this.state.statut,
            this.state.priorite,
            this.state.dateDebut,
            this.state.dateFin,
            this.state.utilisateurId
          )
          .then(
            (listTickets) => {
              this.setState(
                {
                  tabFilter: listTickets.resource,
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              );
            },
            () => {
              this.setState(
                {
                  tabFilter: [],
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              );
            }
          );
      }
    );
  };
  handleNameChange = (val) => {
    this.setState(
      {
        nomSociete: val !== null ? val.nom : "Tous",
      },
      () => {
        this.RecupereTicketsParClientIdAvecFiltre();
      }
    );
  };

  customFilterSociete = (option, searchText) => {
    if (
      (option.data.adresse !== null &&
        option.data.adresse.ville !== null &&
        option.data.adresse.ville
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (option.data !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleStatusChange = (val) => {
    this.setState(
      {
        statut: val !== null ? val.statut : this.props.statut,
      },
      () => {
        this.RecupereTicketsParClientIdAvecFiltre();
      }
    );
  };

  handleUtilisateurChange = (utilisateur) => {
    this.setState(
      { utilisateurId: utilisateur === null ? -1 : utilisateur.id },
      () => {
        this.RecupereTicketsParClientIdAvecFiltre();
      }
    );
  };

  handlePrioriteChange = (val) => {
    this.setState(
      {
        priorite: val !== null ? val.priorite : "Toutes",
      },
      () => {
        this.RecupereTicketsParClientIdAvecFiltre();
      }
    );
  };

  handleDateMinChange = (e) => {
    this.setState(
      {
        dateDebut: e.target.value,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.RecupereTicketsParClientIdAvecFiltre();
        }
      }
    );
  };

  resetValueSelect = () => {
    this.RecupereTicketUtilisateurId();
    this.selectRef["societe"].select.clearValue();
    this.selectRef["utilisateur"].select.clearValue();
    this.selectRef["priorite"].select.clearValue();
    if (this.props.statut === "Tous") {
      this.selectRef["statut"].select.clearValue();
    }
    document.getElementsByName("datemin")[0].value = "";
    document.getElementsByName("datemax")[0].value = "";
  };

  handleDateMaxChange = (e) => {
    this.setState(
      {
        dateFin: e.target.value,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.RecupereTicketsParClientIdAvecFiltre();
        }
      }
    );
  };

  showInformationTicket = (id) => {
    this.setState({
      redirect: true,
      id: id,
    });
  };

  render() {
    const { nameHeader } = this.props;
    const { tabFilter, activeTab, isLoading } = this.state;
    return (
      <div>
        {this.state.redirect === true && (
          <Redirect to={`/client/ticket/information/${this.state.id}`} />
        )}
        <Card>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-none d-md-block">
                  <i className="mdi mdi-filter-outline" /> Filtres
                </span>
                <span className="d-block d-md-none">
                  <i className="mdi mdi-filter-outline" />
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardBody className="pb-2">
                    <Row>
                      <Col lg={3}>
                        <FormGroup>
                          {this.typeSocieteId === "1" ? (
                            <Label>Rechercher par école</Label>
                          ) : (
                            <Label>Rechercher par chantier</Label>
                          )}
                          {this.state.selectTicketsNomSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.selectTicketsNomSociete}
                              onChange={this.handleNameChange}
                              filterOption={this.customFilterSociete}
                              placeholder="Votre societé/ville"
                              noOptionsMessage={() => "Aucune société"}
                              ref={(ref) => {
                                this.selectRef["societe"] = ref;
                              }}
                              isClearable
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par priorité</Label>
                          {this.selectTicketsPriorite !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.priorite}`}
                              getOptionValue={(option) => `${option.priorite}`}
                              styles={this.colourStyles}
                              options={this.selectTicketsPriorite}
                              onChange={this.handlePrioriteChange}
                              noOptionsMessage={() => "Aucune priorité"}
                              placeholder="Votre priorité"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["priorite"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par mois : Date début</Label>
                          <Input
                            type="date"
                            onChange={this.handleDateMinChange}
                            name="datemin"
                            id="datemin "
                            placeholder="date placeholder"
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label> Date fin</Label>
                          <InputGroup>
                            <Input
                              type="date"
                              onChange={this.handleDateMaxChange}
                              name="datemax"
                              id="datemax "
                              placeholder="date placeholder"
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {this.props.statut === "Tous" && (
                        <Col lg={3}>
                          <FormGroup>
                            <Label>Rechercher par statut</Label>
                            {this.selectTicketsStatut !== null && (
                              <Select
                                getOptionLabel={(option) => `${option.statut}`}
                                getOptionValue={(option) => `${option.statut}`}
                                styles={this.colourStyles}
                                options={this.selectTicketsStatut}
                                onChange={this.handleStatusChange}
                                noOptionsMessage={() => "Aucune statut"}
                                placeholder="Votre statut"
                                isClearable
                                ref={(ref) => {
                                  this.selectRef["statut"] = ref;
                                }}
                              />
                            )}
                          </FormGroup>
                        </Col>
                      )}
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par utilisateur</Label>
                          {this.selectTicketsUtilisateur !== null &&
                            this.selectTicketsUtilisateur.length !== 0 && (
                              <Select
                                getOptionLabel={(option) =>
                                  `${option.nom} ${option.prenom}`
                                }
                                getOptionValue={(option) => `${option.id}`}
                                styles={this.colourStyles}
                                options={this.selectTicketsUtilisateur}
                                onChange={this.handleUtilisateurChange}
                                placeholder="Veuillez sélectionner un utilisateur"
                                isClearable
                                ref={(ref) => {
                                  this.selectRef["utilisateur"] = ref;
                                }}
                              />
                            )}
                        </FormGroup>
                      </Col>
                      {(this.state.nomSociete !== "Tous" ||
                        this.state.statut !== this.props.statut ||
                        this.state.priorite !== "Toutes" ||
                        (this.state.dateDebut !== null &&
                          this.state.dateFin !== null)) && (
                        <Col lg={12}>
                          <InputGroupAddon
                            addonType="append"
                            className="float-right"
                          >
                            <button
                              className="btn btn-danger"
                              onClick={() => this.resetValueSelect()}
                            >
                              Effacer les filtres
                            </button>
                          </InputGroupAddon>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
        <Card>
          <CardBody className="bg-info-gradient rounded-top">
            <CardTitle className="h4 text-white">
              Liste des tickets{" "}
              <Link
                to="/client/ticket/creation"
                className="btn btn-secondary createBtn float-right"
              >
                Créer un ticket
              </Link>
            </CardTitle>
          </CardBody>
          <Table className="mb-0 align-middle" hover responsive size={"sm"}>
            <thead>
              <tr>
                {nameHeader.map((name, i) => {
                  return (
                    <th scope="col" key={i}>
                      {name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {/* On affiche une liste squelette en attendant que la liste soit chargée */}
              {isLoading ? (
                <>
                  {Array(7)
                    .fill()
                    .map((i, index) => (
                      <tr key={index}>
                        {Array(7)
                          .fill()
                          .map((i, index) => (
                            <td key={index}>
                              <Skeleton width={"100%"} duration={2} />
                            </td>
                          ))}
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  {tabFilter !== undefined &&
                    tabFilter.map((ticket) => (
                      <tr
                        key={ticket.id}
                        className="trTab"
                        onClick={() => this.showInformationTicket(ticket.id)}
                      >
                        <td className="text-center">{ticket.id}</td>
                        <td className="text-center tdNomSociete">
                          {ticket.sousSociete.nom}
                        </td>
                        <td className="text-center tdSujet">{ticket.sujet}</td>
                        <td className="text-center h5">
                          {ticket.priorite === "Haute" ? (
                            <span className="badge badge-pill bg-danger text-white">
                              {ticket.priorite}
                            </span>
                          ) : ticket.priorite === "Moyenne" ? (
                            <span className="badge badge-pill bg-warning text-white">
                              {ticket.priorite}
                            </span>
                          ) : (
                            <span className="badge badge-pill bg-successV2 text-white">
                              {ticket.priorite}
                            </span>
                          )}
                        </td>
                        <td className="text-center tdStatut">
                          {ticket.statut === "En attente" ? (
                            <span className="badge badge-pill bg-warning text-white">
                              {ticket.statut}
                            </span>
                          ) : ticket.statut === "Clôture" ? (
                            <span className="badge badge-pill bg-success text-white">
                              {ticket.statut}
                            </span>
                          ) : ticket.statut === "Ouvert" ? (
                            <span className="badge badge-pill bg-danger text-white">
                              {ticket.statut}
                            </span>
                          ) : ticket.statut === "En cours" ? (
                            <span className="badge badge-pill bg-secondary text-white">
                              {ticket.statut}
                            </span>
                          ) : (
                            <span className="badge badge-pill bg-primary text-white">
                              {ticket.statut}
                            </span>
                          )}{" "}
                        </td>
                        <td className="text-center">
                          {dateFormat(ticket.dateCreation, "dd/mm/yyyy HH:MM")}
                        </td>
                        <td className="text-center tddate">
                          {ticket.dateModification !== null
                            ? dateFormat(
                                ticket.dateModification,
                                "dd/mm/yyyy HH:MM"
                              )
                            : ""}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </Table>
        </Card>
      </div>
    );
  }
}

export default TableauTicketClient;
