import React, { Component } from "react";
import classnames from "classnames";
import InitialiseTabStatut from "../Fonctions/InitialiseTabStatut";
import { ticketService } from "../../jwt/_services/ticket.service";
import RecupereUtilisateurId from "../Fonctions/GestionToken/RecupereUtilisateurId";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import retourneTechnicien from "../Fonctions/Utils/retourneTechnicien";
import retourneTexteRaccourci from "../Fonctions/Utils/retourneTexteRaccourci";
import retourneProduit from "../Fonctions/Utils/retourneProduit";
import Swal from "sweetalert2";
class TableauTicketSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      tickets: [],
      selectTicketsNomSociete: [],
      selectTicketsPrenomClient: [],
      selectTicketsPrenomTechnicien: [],
      selectTicketsTypeSociete: [],
      tabTicketsPrenomClient: [],
      tabTicketsPrenomTechnicien: [],
      tabFilter: [],
      sousSociete: "",
      typeSociete: "",
      client: props.client === "Tous" ? props.client : parseInt(props.client),
      technicien: "",
      dateDebut: null,
      dateFin: null,
      statut: props.statut,
      priorite: "",
      isLoading: true,
      isExportDownloading: false,
    };
    this.classTrTab = "";
    this.id = RecupereUtilisateurId();
    this.selectTicketsStatut = InitialiseTabStatut();
    this.selectTicketsPriorite = [
      { nom: "Faible" },
      { nom: "Moyenne" },
      { nom: "Haute" },
    ];
    this.colourStyles = {
      option: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "gray" : null,
          color: "#333333",
        };
      },
    };
    this.selectRef = {
      societe: null,
      type: null,
      priorite: null,
      client: null,
      technicien: null,
      statut: null,
    };
  }

  componentDidMount() {
    this.getTickets();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    } else {
      this.setState({ activeTab: "" });
    }
  }

  getTickets = () => {
    /* On initialise les variables pour faire la recherche avec les filtres de bases et on remplit les listes de sélection */
    this.setState(
      {
        sousSociete: "Toutes",
        typeSociete: "Toutes",
        client:
          this.props.client === "Tous"
            ? this.props.client
            : parseInt(this.props.client),
        technicien: "Tous",
        dateDebut: null,
        dateFin: null,
        statut: this.props.statut,
        priorite: "Toutes",
        isLoading: true,
      },
      () => {
        ticketService
          .RecupereTicketsAvecFiltre(
            this.state.sousSociete,
            this.state.typeSociete,
            this.state.client,
            this.state.statut,
            this.state.priorite,
            this.state.technicien,
            this.state.dateDebut,
            this.state.dateFin
          )
          .then(
            (ticketsDb) => {
              this.setState(
                {
                  tickets: ticketsDb.resource,
                  tabFilter: ticketsDb.resource,
                },
                () => {
                  this.setState(
                    {
                      selectTicketsNomSociete: this.state.tickets.map(
                        (ticket) => ({
                          nom: ticket.nomSousSociete,
                          adresse: ticket.adresse,
                        })
                      ),
                      selectTicketsPrenomClient: this.state.tickets.map(
                        (client) => ({
                          id: client.clientId,
                          prenom: client.prenomClient,
                        })
                      ),
                      selectTicketsTypeSociete: this.state.tickets.map(
                        (ticket) => ({
                          nom: ticket.typeSocieteClient,
                        })
                      ),
                      selectTicketsPrenomTechnicien: this.state.tickets
                        .filter(Boolean)
                        .map((tech) => ({
                          id: tech.technicienId,
                          prenom: tech.prenomTechnicien,
                        })),
                    },
                    () => {
                      this.setState({
                        isLoading: false,
                        // on retire les doublons dans les listes en fonction d'un attribut
                        selectTicketsNomSociete: [
                          ...new Map(
                            this.state.selectTicketsNomSociete.map((item) => [
                              item["nom"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTicketsTypeSociete: [
                          ...new Map(
                            this.state.selectTicketsTypeSociete.map((item) => [
                              item["nom"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTicketsPrenomClient: [
                          ...new Map(
                            this.state.selectTicketsPrenomClient.map((item) => [
                              item["id"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTicketsPrenomTechnicien: [
                          ...new Map(
                            this.state.selectTicketsPrenomTechnicien.map(
                              (item) => [item["id"], item]
                            )
                          ).values(),
                        ],
                      });
                    }
                  );
                }
              );
            },
            () => {
              this.setState({
                isLoading: false,
              });
            }
          );
      }
    );
  };

  getTicketsWithFilter = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        // On récupère la liste avec les différents filtres
        ticketService
          .RecupereTicketsAvecFiltre(
            this.state.sousSociete,
            this.state.typeSociete,
            this.state.client,
            this.state.statut,
            this.state.priorite,
            this.state.technicien,
            this.state.dateDebut,
            this.state.dateFin
          )
          .then(
            (ticketsDb) => {
              this.setState(
                {
                  tabFilter: ticketsDb.resource,
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              );
            },
            () => {
              this.setState(
                {
                  tabFilter: [],
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              );
            }
          );
      }
    );
  };

  handleNomSousSocieteChange = (val) => {
    this.setState(
      {
        sousSociete: val !== null ? val.nom : "Toutes",
      },
      () => {
        this.getTicketsWithFilter();
      }
    );
  };

  customFilterSociete = (option, searchText) => {
    if (
      (option?.data?.adresse !== null &&
        option?.data?.adresse?.ville !== null &&
        option?.data?.adresse?.ville
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (option?.data !== null &&
        option?.data?.nom?.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleTypeSocieteChange = (val) => {
    this.setState(
      {
        typeSociete: val !== null ? val.nom : "Toutes",
      },
      () => {
        this.getTicketsWithFilter();
      }
    );
  };

  handleStatutChange = (val) => {
    this.setState(
      {
        statut: val !== null ? val.statut : this.props.statut,
      },
      () => {
        this.getTicketsWithFilter();
      }
    );
  };

  handleTechnicienChange = (val) => {
    this.setState(
      {
        technicien: val !== null ? val.id : "Tous",
      },
      () => {
        this.getTicketsWithFilter();
      }
    );
  };
  customFilterTechnicien = (option, searchText) => {
    if (
      option.data.prenom !== null &&
      option.data.prenom.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleClientChange = (val) => {
    this.setState(
      {
        client: val !== null ? val.id : this.props.client,
      },
      () => {
        this.getTicketsWithFilter();
      }
    );
  };

  customFilterClient = (option, searchText) => {
    if (
      option.data.prenom !== null &&
      option.data.prenom.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  handlePrioriteChange = (val) => {
    this.setState(
      {
        priorite: val !== null ? val.nom : "Toutes",
      },
      () => {
        this.getTicketsWithFilter();
      }
    );
  };

  handleDateMinChange = (e) => {
    this.setState(
      {
        dateDebut: e.target.value,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.getTicketsWithFilter();
        }
      }
    );
  };

  handleDateMaxChange = (e) => {
    this.setState(
      {
        dateFin: e.target.value,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.getTicketsWithFilter();
        }
      }
    );
  };

  resetValueSelect = () => {
    this.selectRef["societe"].select.clearValue();
    this.selectRef["type"].select.clearValue();
    this.selectRef["priorite"].select.clearValue();
    if (this.props.client === "Tous") {
      this.selectRef["client"].select.clearValue();
    }
    this.selectRef["technicien"].select.clearValue();
    if (this.props.statut === "Tous") {
      this.selectRef["statut"].select.clearValue();
    }

    document.getElementsByName("datemin")[0].value = "";
    document.getElementsByName("datemax")[0].value = "";
    this.getTickets();
  };

  showInformationTicket = (id) => {
    this.setState({
      redirect: true,
      id: id,
    });
  };

  setClassTr = (technicienId) => {
    parseInt(this.id) === parseInt(technicienId)
      ? (this.classTrTab = "trTabTechnicien")
      : (this.classTrTab = "trTab");
  };

  exporterLesTickets = () => {
    this.setState(
      {
        isExportDownloading: true,
      },
      () => {
        ticketService
          .ExporterExcelTickets()
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(new Blob([blob]));

            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute(
              "download",
              `Liste des tickets ${new Date().toLocaleDateString()} .xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            window.URL.revokeObjectURL(blobUrl);
            this.setState({ isExportDownloading: false });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Une erreur est survenue",
              text: error.messages,
            });
            this.setState({ isExportDownloading: false });
          });
      }
    );
  };

  render() {
    const { nameHeader } = this.props;
    const { activeTab, tabFilter, isLoading, isExportDownloading } = this.state;
    return (
      <div>
        {this.state.redirect === true && (
          <Redirect
            to={{
              pathname: `/support/ticket/information/${this.state.id}`,
              state: { client: this.props.client },
            }}
          />
        )}
        <Card>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-none d-md-block">
                  <i className="mdi mdi-filter-outline" /> Filtres
                </span>
                <span className="d-block d-md-none">
                  <i className="mdi mdi-filter-outline" />
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                <span className="d-none d-md-block">
                  <i className="mdi mdi-cog-outline" /> Actions
                </span>
                <span className="d-block d-md-none">
                  <i className="mdi mdi-cog-outline" />
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardBody className="pb-2">
                    <Row>
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par société</Label>

                          {this.state.selectTicketsNomSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsNomSociete}
                              onChange={this.handleNomSousSocieteChange}
                              filterOption={this.customFilterSociete}
                              placeholder="Votre societé/ville"
                              noOptionsMessage={() => "Aucune société"}
                              ref={(ref) => {
                                this.selectRef["societe"] = ref;
                              }}
                              isClearable
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par type</Label>
                          {this.state.selectTicketsTypeSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsTypeSociete}
                              onChange={this.handleTypeSocieteChange}
                              noOptionsMessage={() => "Aucun type"}
                              placeholder="Votre type"
                              ref={(ref) => {
                                this.selectRef["type"] = ref;
                              }}
                              isClearable
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par priorité</Label>
                          {this.selectTicketsPriorite !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.selectTicketsPriorite}
                              onChange={this.handlePrioriteChange}
                              noOptionsMessage={() => "Aucune priorité"}
                              placeholder="Votre priorité"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["priorite"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par client</Label>
                          {this.state.selectTicketsPrenomClient !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.prenom}`}
                              getOptionValue={(option) => `${option.prenom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsPrenomClient}
                              onChange={this.handleClientChange}
                              filterOption={this.customFilterClient}
                              placeholder="Votre client"
                              noOptionsMessage={() => "Aucun client"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["client"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par technicien</Label>
                          {this.state.selectTicketsPrenomTechnicien !==
                            null && (
                            <Select
                              getOptionLabel={(option) => `${option.prenom}`}
                              getOptionValue={(option) => `${option.prenom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsPrenomTechnicien}
                              onChange={this.handleTechnicienChange}
                              filterOption={this.customFilterTechnicien}
                              placeholder="Votre technicien"
                              noOptionsMessage={() => "Aucun technicien"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["technicien"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {this.props.statut === "Tous" && (
                        <Col lg={2}>
                          <FormGroup>
                            <Label>Rechercher par statut</Label>
                            {this.selectTicketsStatut !== null && (
                              <Select
                                getOptionLabel={(option) => `${option.statut}`}
                                getOptionValue={(option) => `${option.statut}`}
                                styles={this.colourStyles}
                                options={this.selectTicketsStatut}
                                onChange={this.handleStatutChange}
                                noOptionsMessage={() => "Aucune statut"}
                                placeholder="Votre statut"
                                isClearable
                                ref={(ref) => {
                                  this.selectRef["statut"] = ref;
                                }}
                              />
                            )}
                          </FormGroup>
                        </Col>
                      )}
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par mois : Date début</Label>
                          <Input
                            type="date"
                            name="datemin"
                            id="datemin "
                            placeholder="date placeholder"
                            onChange={this.handleDateMinChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label> Date fin</Label>
                          <InputGroup>
                            <Input
                              type="date"
                              name="datemax"
                              id="datemax "
                              placeholder="date placeholder"
                              onChange={this.handleDateMaxChange}
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      {(this.state.sousSociete !== "Toutes" ||
                        this.state.typeSociete !== "Toutes" ||
                        this.state.client !== "Tous" ||
                        this.state.technicien !== "Tous" ||
                        this.state.statut !== this.props.statut ||
                        this.state.priorite !== "Toutes" ||
                        (this.state.dateDebut !== null &&
                          this.state.dateFin !== null)) && (
                        <Col lg={12}>
                          <InputGroupAddon
                            addonType="append"
                            className="float-right"
                          >
                            <button
                              className="btn btn-danger"
                              onClick={() => this.resetValueSelect()}
                            >
                              Effacer les filtres
                            </button>
                          </InputGroupAddon>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col lg={3}>
                  <Button
                    className="m-3"
                    color="info"
                    onClick={() => this.exporterLesTickets()}
                    disabled={isExportDownloading}
                  >
                    Exporter
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
        <Card>
          <CardBody className="bg-info-gradient rounded-top">
            <CardTitle className="h4 mb-0 text-white">
              Liste des tickets
            </CardTitle>
          </CardBody>
          <Table className="mb-0 align-middle" hover responsive size={"sm"}>
            <thead>
              <tr>
                {nameHeader.map((name, i) => {
                  return (
                    <th scope="col" className="th" key={i}>
                      {name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                /* On affiche une liste squelette en attendant que la liste soit chargée */
                <>
                  {Array(8)
                    .fill()
                    .map((i, index) => (
                      <tr key={index}>
                        {Array(13)
                          .fill()
                          .map((i, index) => (
                            <td key={index}>
                              <Skeleton width={"100%"} duration={2} />
                            </td>
                          ))}
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  {tabFilter !== undefined &&
                    tabFilter.map((ticket) => (
                      <React.Fragment key={ticket.id}>
                        {this.setClassTr(ticket.technicienId)}
                        <tr
                          className={this.classTrTab}
                          onClick={() => this.showInformationTicket(ticket.id)}
                        >
                          <td className="text-right spaceTd">
                            <div className="d-flex align-items-center justify-content-center">
                              {!ticket.isOpen && (
                                <div class="notify-new">
                                  <div class="pulse"></div>
                                  <div class="marker"></div>
                                </div>
                              )}
                              <span
                                className="ml-3"
                                style={{ marginRight: !ticket.isOpen ? 15 : 0 }}
                              >
                                {ticket.id}
                              </span>
                            </div>
                          </td>
                          <td className="text-center spaceTd">
                            {ticket.sousSocieteId}
                          </td>
                          <td className="text-center spaceTd">
                            {ticket.nomSousSociete}
                          </td>
                          <td className="text-center spaceTd">
                            {retourneProduit(ticket.typeSocieteClient)}
                          </td>
                          <td className="text-center spaceTd">
                            {ticket.prenomClient}
                          </td>
                          <td className="text-center spaceTd noteTd">
                            {retourneTexteRaccourci(ticket.note)}
                          </td>
                          <td className="text-center spaceTd sujetTd">
                            {retourneTexteRaccourci(ticket.sujet)}
                          </td>
                          <td className="text-center spaceTd h5">
                            {ticket.priorite === "Haute" ? (
                              <span className="badge badge-pill bg-danger text-white">
                                {ticket.priorite}
                              </span>
                            ) : ticket.priorite === "Moyenne" ? (
                              <span className="badge badge-pill bg-warning text-white">
                                {ticket.priorite}
                              </span>
                            ) : (
                              <span className="badge badge-pill bg-successV2 text-white">
                                {ticket.priorite}
                              </span>
                            )}
                          </td>
                          <td className="text-center spaceTd h5">
                            {ticket.statut === "En attente" ? (
                              <span className="badge badge-pill bg-warning text-white">
                                {ticket.statut}
                              </span>
                            ) : ticket.statut === "Clôture" ? (
                              <span className="badge badge-pill bg-success text-white">
                                {ticket.statut}
                              </span>
                            ) : ticket.statut === "Ouvert" ? (
                              <span className="badge badge-pill bg-danger text-white">
                                {ticket.statut}
                              </span>
                            ) : ticket.statut === "En cours" ? (
                              <span className="badge badge-pill bg-secondary text-white">
                                {ticket.statut}
                              </span>
                            ) : (
                              <span className="badge badge-pill bg-primary text-white">
                                {ticket.statut}
                              </span>
                            )}
                          </td>
                          <td className="text-center spaceTd sujetTd">
                            {ticket.module}
                          </td>
                          <td className="text-center spaceTd">
                            {retourneTechnicien(ticket.prenomTechnicien)}
                          </td>
                          <td className="text-center spaceTd dateTd">
                            {ticket.dateCreation !== null
                              ? new Date(ticket.dateCreation).toLocaleString()
                              : ""}
                          </td>
                          <td className="text-center spaceTd dateTd">
                            {ticket.dateModification !== null
                              ? new Date(
                                  ticket.dateModification
                                ).toLocaleString()
                              : ""}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </>
              )}
            </tbody>
          </Table>
        </Card>
      </div>
    );
  }
}

export default TableauTicketSupport;
