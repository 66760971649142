import dateFormat from "dateformat";
import React, { Component } from "react";
import html from "react-inner-html";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { utilisateurService } from "../../jwt/_services/utilisateur.service";

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageRaw: this.props.message,
      message: Buffer.from(this.props.message.contenu, "base64").toString(
        "utf-8"
      ),
      fichiers: this.props.fichiers,
      utilisateur: null,
      modal: false,
    };
  }

  componentDidMount() {
    utilisateurService
      .RecupereUtilisateurParId(this.props.message.utilisateurId)
      .then(
        (utilisateur) => {
          this.setState({
            utilisateur: utilisateur,
          });
        },
        () => {}
      );
  }

  openModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  closeModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { message, fichiers } = this.props;
    return (
      <div className="messages">
        {message.typeUtilisateur === "emetteur" &&
          this.state.utilisateur !== null && (
            <div className="mr-2 pr-3">
              <p className="headerMessageEmetteur">
                <span className="textName">
                  &nbsp; {this.state.utilisateur.prenom}
                </span>{" "}
                &nbsp;{dateFormat(message.dateCreation, "dd/mm/yyyy HH:MM")}{" "}
                {fichiers !== undefined && fichiers.length > 0 && (
                  <span
                    className="btn btnFichierMessage"
                    onClick={this.openModal}
                  >
                    <i className="fa fa-download"></i> Voir les fichiers
                  </span>
                )}
              </p>
              <p className="emetteur" {...html(this.state.message)}></p>
            </div>
          )}

        {message.typeUtilisateur === "destinataire" &&
          this.state.utilisateur !== null && (
            <div className="ml-2 pl-3">
              <p className="headerMessageDestinataire">
                <span className="textName">
                  {this.state.utilisateur.prenom}
                </span>{" "}
                &nbsp;{dateFormat(message.dateCreation, "dd/mm/yyyy HH:MM")}{" "}
                {fichiers !== undefined && fichiers.length > 0 && (
                  <span
                    className="btn btnFichierMessage"
                    onClick={this.openModal}
                  >
                    <i className="fa fa-download"></i> Voir les fichiers
                  </span>
                )}
              </p>
              <p className="destinataire " {...html(this.state.message)}></p>
            </div>
          )}
        <Modal
          isOpen={this.state.modal}
          toggle={this.openModal}
          size="lg"
          className="Modal"
        >
          <ModalHeader toggle={this.openModal}>
            {this.props.fichiers.length <= 1 ? (
              <p className="h3">Nom du fichier</p>
            ) : (
              <p className="h3">Nom des fichiers</p>
            )}
          </ModalHeader>
          <ModalBody>
            <Table responsive>
              <tbody>
                {this.props.fichiers.map((fichier, index) => (
                  <tr key={index}>
                    <td className="w-50">
                      <a
                        href={`${process.env.REACT_APP_URL}/fichiers/${fichier.chemin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {fichier.nom}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Message;
