import React, { Component } from "react";
import RecupereRoleId from "../../components/Fonctions/GestionToken/RecupereRoleId";
import GraphiqueTicket from "../../components/Statistiques/GraphiqueTicket";
import { ticketService } from "../../jwt/_services/ticket.service";
import { utilisateurService } from "../../jwt/_services/utilisateur.service";
import Chargement from "../../components/Chargement/Chargement";
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
} from "reactstrap";
import classnames from "classnames";
import dateFormat from "dateformat";
import InitialiseTabStatut from "../../components/Fonctions/InitialiseTabStatut";
import TableauTicket from "../../components/Statistiques/TableauTicket";

// Export Excel:
import ReactExport from "@sachamarits/react-export-excel4";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class StatistiqueTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      tickets: null,
      tabFilterTickets: null,
      tabDisplayFilterTickets: null,
      isLoading: true,
      selectSousSociete: [],
      selectTypeSociete: [],
      selectTicketsClient: [],
      selectTicketsCategorie: [],
      sousSociete: "",
      typeSociete: "",
      clientId: "",
      technicienId: "",
      technicien: "",
      dateDebut: null,
      dateFin: null,
      statut: "",
      priorite: "",
      categorie: "",
      selected: "graphique",
      exportIsLoaded: false,
      dataGraphique: null,
      dataListe: null,
    };
    this.selectTicketsStatut = InitialiseTabStatut();
    this.roleId = RecupereRoleId();
    this.selectTicketsTechnicien = [
      { technicienId: 0, prenomTechnicien: "Non-attribué" },
    ];
    this.selectTicketsPriorite = [
      { nom: "Faible" },
      { nom: "Moyenne" },
      { nom: "Haute" },
    ];
    // Initialise le graphique avec la bonne configuration
    this.optionsbar = {
      colors: ["#26BEBE"],
      dataLabels: {
        enabled: false,
      },
      chart: {
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            let technicienId =
              this.selectTicketsTechnicien[config.dataPointIndex].technicienId;
            if (technicienId === 0) {
              technicienId = "Non-attribué";
            }
            this.setState(
              {
                technicienId: technicienId,
                technicien:
                  this.selectTicketsTechnicien[config.dataPointIndex]
                    .prenomTechnicien,
                selected: "liste",
              },
              () => {
                if (this.state.activeTab !== "1") {
                  this.toggle("1");
                }
                this.RecupereTicketsStatistiqueTicketsFiltre();
              }
            );
          },
          dataPointMouseEnter: function (event) {
            event.path[0].style.cursor = "pointer";
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontFamily: "Open sans",
          },
          formatter: (value) => {
            return value;
          },
        },
        min: 0,
        tickAmount: 1,
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "13px",
            fontFamily: "Open sans",
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
        },
      },
      tooltip: {
        style: {
          fontFamily: "Open sans",
        },
        y: {
          show: false,
          formatter: (value) => {
            return value;
          },
        },
      },
    };
    // Initialise les données sur l'axe des x du graphique
    this.seriesbar = [
      {
        name: "Tickets",
        data: [],
      },
    ];
    this.colourStyles = {
      option: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "gray" : null,
          color: "#333333",
        };
      },
    };
    this.selectRef = {
      client: null,
      sousSociete: null,
      type: null,
      statut: null,
      technicien: null,
      priorite: null,
      categorie: null,
    };
  }
  componentDidMount() {
    this.RecupereTicketsStatistiques();
  }
  /*
   *   Récupère l'ensemble des tickets et crée les différentes listes de sélection pour les filtres et
   *   remplit les données pour l'axe des x et des y pour le graphique
   */
  RecupereTicketsStatistiques = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        ticketService
          .RecupereStatistiqueTickets(
            this.state.sousSociete,
            this.state.typeSociete,
            this.state.clientId,
            this.state.dateDebut,
            this.state.dateFin,
            this.state.statut,
            this.state.technicienId,
            this.state.priorite,
            this.state.categorie
          )
          .then((tickets) => {
            this.setState(
              {
                tickets: tickets.resource,
                tabFilterTickets: tickets.resource,
              },
              () => {
                this.setState(
                  {
                    selectSousSociete: this.state.tickets.map((ticket) => ({
                      nom: ticket.sousSociete,
                      adresse: ticket.adresseSousSociete,
                    })),
                    selectTypeSociete: this.state.tickets.map((ticket) => ({
                      nom: ticket.typeSociete,
                    })),
                    selectTicketsClient: this.state.tickets.map((client) => ({
                      prenom: client.prenomClient,
                      nom: client.nomClient,
                      id: client.clientId,
                    })),
                    selectTicketsCategorie: this.state.tickets.map(
                      (ticket) => ({
                        nom: ticket.categorie,
                      })
                    ),
                  },
                  () => {
                    this.setState(
                      {
                        selectSousSociete: [
                          ...new Map(
                            this.state.selectSousSociete.map((item) => [
                              item["nom"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTypeSociete: [
                          ...new Map(
                            this.state.selectTypeSociete.map((item) => [
                              item["nom"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTicketsClient: [
                          ...new Map(
                            this.state.selectTicketsClient.map((item) => [
                              item["id"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTicketsCategorie: [
                          ...new Map(
                            this.state.selectTicketsCategorie.map((item) => [
                              item["nom"],
                              item,
                            ])
                          ).values(),
                        ],
                      },
                      () => {
                        let tabNbTicketTechnicien = [];
                        var enterOnce = false;
                        let dataListe = [];

                        this.state.tabFilterTickets.map((ticket) => {
                          dataListe.push({
                            id: ticket.id !== null ? ticket.id : "",
                            societe:
                              ticket.sousSociete !== null
                                ? ticket.sousSociete
                                : "",
                            type:
                              ticket.typeSociete !== null
                                ? ticket.typeSociete
                                : "",
                            client:
                              ticket.prenomClient !== null
                                ? ticket.prenomClient
                                : "",
                            sujet: ticket.sujet !== null ? ticket.sujet : "",
                            statut: ticket.statut !== null ? ticket.statut : "",
                            dateCreation:
                              ticket.dateCreation !== null
                                ? dateFormat(
                                    ticket.dateCreation,
                                    "dd/mm/yyyy HH:MM"
                                  )
                                : "",
                            technicien:
                              ticket.prenomTechnicien !== null
                                ? ticket.prenomTechnicien
                                : "",
                            categorie:
                              ticket.categorie !== null ? ticket.categorie : "",
                            priorite:
                              ticket.priorite !== null ? ticket.priorite : "",
                          });
                          var trouve = false;
                          if (!enterOnce) {
                            let info = {
                              technicienId: ticket.technicienId,
                              nbTickets: 1,
                              prenomTechnicien: ticket.prenomTechnicien,
                            };
                            tabNbTicketTechnicien.push(info);
                            enterOnce = true;
                          } else {
                            for (
                              let index = 0;
                              index < tabNbTicketTechnicien.length;
                              index++
                            ) {
                              if (
                                ticket.technicienId !== null &&
                                ticket.technicienId ===
                                  tabNbTicketTechnicien[index].technicienId
                              ) {
                                trouve = true;
                                tabNbTicketTechnicien[index].nbTickets++;
                              }
                            }
                            if (!trouve && ticket.technicienId !== null) {
                              let info = {
                                technicienId: ticket.technicienId,
                                nbTickets: 1,
                                prenomTechnicien: ticket.prenomTechnicien,
                              };
                              tabNbTicketTechnicien.push(info);
                            }
                          }
                          return ticket;
                        });
                        let tabNomTech = ["Non-attribué"];
                        let tabNbTicket = [this.state.tabFilterTickets.length];
                        utilisateurService.RecupereSupport().then((support) => {
                          for (let index = 0; index < support.length; index++) {
                            let trouve = false;
                            for (
                              let i = 0;
                              i < tabNbTicketTechnicien.length &&
                              i < support.length;
                              i++
                            ) {
                              if (
                                support[index].id ===
                                tabNbTicketTechnicien[i].technicienId
                              ) {
                                trouve = true;
                                let info = {
                                  technicienId:
                                    tabNbTicketTechnicien[i].technicienId,
                                  prenomTechnicien:
                                    tabNbTicketTechnicien[i].prenomTechnicien,
                                };
                                this.selectTicketsTechnicien.push(info);
                                tabNomTech.push(
                                  tabNbTicketTechnicien[i].prenomTechnicien
                                );
                                tabNbTicket.push(
                                  tabNbTicketTechnicien[i].nbTickets
                                );
                              }
                            }
                            if (!trouve) {
                              let info = {
                                technicienId: support[index].id,
                                prenomTechnicien: support[index].prenom,
                              };
                              this.selectTicketsTechnicien.push(info);
                              tabNomTech.push(support[index].prenom);
                              tabNbTicket.push(0);
                            }
                          }
                          for (let j = 0; j < tabNbTicket.length - 1; j++) {
                            tabNbTicket[0] -= tabNbTicket[j + 1];
                          }
                          this.optionsbar = {
                            ...this.optionsbar,
                            xaxis: {
                              ...this.optionsbar.xaxis,
                              categories: tabNomTech,
                            },
                          };
                          this.seriesbar = [
                            {
                              name: "Tickets",
                              data: tabNbTicket,
                            },
                          ];
                          this.selectTicketsTechnicien = [
                            ...new Map(
                              this.selectTicketsTechnicien.map((item) => [
                                item["technicienId"],
                                item,
                              ])
                            ).values(),
                          ];
                          let dataGraphique = [];
                          for (
                            let index = 0;
                            index < tabNomTech.length;
                            index++
                          ) {
                            dataGraphique.push({
                              technicien:
                                tabNomTech[index] !== null
                                  ? tabNomTech[index]
                                  : "",
                              totalTickets:
                                tabNbTicket[index] !== null
                                  ? tabNbTicket[index]
                                  : "",
                            });
                          }
                          this.setState({
                            dataGraphique: dataGraphique,
                            dataListe: dataListe,
                            isLoading: false,
                          });
                        });
                      }
                    );
                  }
                );
              }
            );
          });
      }
    );
  };

  /*
   *   Récupère l'ensemble des tickets en fonction des différents filtres et
   *   remplit les données pour l'axe des x et des y pour le graphique en fonction de ses données
   */
  RecupereTicketsStatistiqueTicketsFiltre = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.optionsbar.xaxis.categories = [];
        this.seriesbar[0].data = [];
        let tabNbTicketTechnicien = [];
        var enterOnce = false;
        ticketService
          .RecupereStatistiqueTickets(
            this.state.sousSociete,
            this.state.typeSociete,
            this.state.clientId,
            this.state.dateDebut,
            this.state.dateFin,
            this.state.statut,
            this.state.technicienId,
            this.state.priorite,
            this.state.categorie
          )
          .then((tickets) => {
            this.setState(
              {
                tabFilterTickets: tickets.resource,
              },
              () => {
                let dataListe = [];
                this.state.tabFilterTickets.map((ticket) => {
                  dataListe.push({
                    id: ticket.id !== null ? ticket.id : "",
                    societe:
                      ticket.sousSociete !== null ? ticket.sousSociete : "",
                    type: ticket.typeSociete !== null ? ticket.typeSociete : "",
                    client:
                      ticket.prenomClient !== null ? ticket.prenomClient : "",
                    sujet: ticket.sujet !== null ? ticket.sujet : "",
                    statut: ticket.statut !== null ? ticket.statut : "",
                    dateCreation:
                      ticket.dateCreation !== null
                        ? dateFormat(ticket.dateCreation, "dd/mm/yyyy HH:MM")
                        : "",
                    technicien:
                      ticket.prenomTechnicien !== null
                        ? ticket.prenomTechnicien
                        : "",
                    categorie:
                      ticket.categorie !== null ? ticket.categorie : "",
                    priorite: ticket.priorite !== null ? ticket.priorite : "",
                  });
                  var trouve = false;
                  if (!enterOnce) {
                    let info = {
                      technicienId: ticket.technicienId,
                      nbTickets: 1,
                      prenomTechnicien: ticket.prenomTechnicien,
                    };
                    tabNbTicketTechnicien.push(info);
                    enterOnce = true;
                  } else {
                    for (
                      let index = 0;
                      index < tabNbTicketTechnicien.length;
                      index++
                    ) {
                      if (
                        ticket.technicienId !== null &&
                        ticket.technicienId ===
                          tabNbTicketTechnicien[index].technicienId
                      ) {
                        trouve = true;
                        tabNbTicketTechnicien[index].nbTickets++;
                      }
                    }
                    if (!trouve && ticket.technicienId !== null) {
                      let info = {
                        technicienId: ticket.technicienId,
                        nbTickets: 1,
                        prenomTechnicien: ticket.prenomTechnicien,
                      };
                      tabNbTicketTechnicien.push(info);
                    }
                  }
                  return ticket;
                });
                let tabNomTech = ["Non-attribué"];
                let tabNbTicket = [this.state.tabFilterTickets.length];
                utilisateurService.RecupereSupport().then((support) => {
                  for (let index = 0; index < support.length; index++) {
                    let trouve = false;
                    for (
                      let i = 0;
                      i < tabNbTicketTechnicien.length && i < support.length;
                      i++
                    ) {
                      if (
                        support[index].id ===
                        tabNbTicketTechnicien[i].technicienId
                      ) {
                        trouve = true;
                        let info = {
                          technicienId: tabNbTicketTechnicien[i].technicienId,
                          prenomTechnicien:
                            tabNbTicketTechnicien[i].prenomTechnicien,
                        };
                        this.selectTicketsTechnicien.push(info);
                        tabNomTech.push(
                          tabNbTicketTechnicien[i].prenomTechnicien
                        );
                        tabNbTicket.push(tabNbTicketTechnicien[i].nbTickets);
                      }
                    }
                    if (!trouve) {
                      let info = {
                        technicienId: support[index].id,
                        prenomTechnicien: support[index].prenom,
                      };
                      this.selectTicketsTechnicien.push(info);
                      tabNomTech.push(support[index].prenom);
                      tabNbTicket.push(0);
                    }
                  }
                  for (let j = 0; j < tabNbTicket.length - 1; j++) {
                    tabNbTicket[0] -= tabNbTicket[j + 1];
                  }
                  this.optionsbar = {
                    ...this.optionsbar,
                    xaxis: {
                      ...this.optionsbar.xaxis,
                      categories: tabNomTech,
                    },
                  };
                  this.seriesbar = [
                    {
                      name: "Tickets",
                      data: tabNbTicket,
                    },
                  ];
                  this.selectTicketsTechnicien = [
                    ...new Map(
                      this.selectTicketsTechnicien.map((item) => [
                        item["technicienId"],
                        item,
                      ])
                    ).values(),
                  ];
                  let dataGraphique = [];
                  for (let index = 0; index < tabNomTech.length; index++) {
                    dataGraphique.push({
                      technicien:
                        tabNomTech[index] !== null ? tabNomTech[index] : "",
                      totalTickets:
                        tabNbTicket[index] !== null ? tabNbTicket[index] : "",
                    });
                  }
                  this.setState({
                    dataGraphique: dataGraphique,
                    dataListe: dataListe,
                    isLoading: false,
                  });
                });
              }
            );
          });
      }
    );
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    } else {
      this.setState({ activeTab: "" });
    }
  }

  handleSousSociete = (val) => {
    this.setState(
      {
        sousSociete: val !== null ? val.nom : "",
      },
      () => {
        this.RecupereTicketsStatistiqueTicketsFiltre();
      }
    );
  };

  handleTypeSociete = (val) => {
    this.setState(
      {
        typeSociete: val !== null ? val.nom : "",
      },
      () => {
        this.RecupereTicketsStatistiqueTicketsFiltre();
      }
    );
  };

  handleClientChange = (val) => {
    this.setState(
      {
        clientId: val !== null ? val.id : "",
      },
      () => {
        this.RecupereTicketsStatistiqueTicketsFiltre();
      }
    );
  };

  handleTechnicienChange = (val) => {
    if (val !== null && val.prenomTechnicien === "Non-attribué") {
      this.setState(
        {
          technicienId: "Non-attribué",
          technicien: val.prenomTechnicien,
        },
        () => {
          this.RecupereTicketsStatistiqueTicketsFiltre();
        }
      );
    } else {
      this.setState(
        {
          technicienId: val !== null ? val.technicienId : "",
          technicien: val !== null ? val.prenomTechnicien : "",
        },
        () => {
          this.RecupereTicketsStatistiqueTicketsFiltre();
        }
      );
    }
  };

  handleDateMinChange = (e) => {
    this.setState(
      {
        dateDebut: e.target.value,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.RecupereTicketsStatistiqueTicketsFiltre();
        }
      }
    );
  };

  handleDateMaxChange = (e) => {
    this.setState(
      {
        dateFin: e.target.value,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.RecupereTicketsStatistiqueTicketsFiltre();
        }
      }
    );
  };
  handleStatutChange = (val) => {
    this.setState(
      {
        statut: val !== null ? val.statut : "",
      },
      () => {
        this.RecupereTicketsStatistiqueTicketsFiltre();
      }
    );
  };

  handlePrioriteChange = (val) => {
    this.setState(
      {
        priorite: val !== null ? val.nom : "",
      },
      () => {
        this.RecupereTicketsStatistiqueTicketsFiltre();
      }
    );
  };

  handleCategorieChange = (val) => {
    this.setState(
      {
        categorie: val !== null ? val.nom : "",
      },
      () => {
        this.RecupereTicketsStatistiqueTicketsFiltre();
      }
    );
  };

  customFilterClient = (option, searchText) => {
    if (
      (option.data.prenom !== null &&
        option.data.prenom.toLowerCase().includes(searchText.toLowerCase())) ||
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  customFilterSousSociete = (option, searchText) => {
    if (
      (option.data.adresse !== null &&
        option.data.adresse.ville !== null &&
        option.data.adresse.ville
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetValueSelect = () => {
    this.selectRef["type"].select.clearValue();
    this.selectRef["sousSociete"].select.clearValue();
    this.selectRef["client"].select.clearValue();
    this.selectRef["statut"].select.clearValue();
    this.selectRef["technicien"].select.clearValue();
    this.selectRef["priorite"].select.clearValue();
    this.selectRef["categorie"].select.clearValue();
    document.getElementsByName("datemin")[0].value = "";
    document.getElementsByName("datemax")[0].value = "";
    this.setState(
      {
        typeSociete: "",
        sousSociete: "",
        clientId: "",
        technicienId: "",
        technicien: "",
        dateDebut: null,
        dateFin: null,
        statut: "",
        priorite: "",
        categorie: "",
      },
      () => {
        this.RecupereTicketsStatistiques();
      }
    );
  };

  render() {
    const { activeTab } = this.state;
    return (
      <div>
        <Card>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-none d-md-block">
                  <i className="mdi mdi-filter-outline" /> Filtres
                </span>
                <span className="d-block d-md-none">
                  <i className="mdi mdi-filter-outline" />
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardBody className="pb-2">
                    <Row>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par societé</Label>
                          {this.state.selectSousSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectSousSociete}
                              onChange={this.handleSousSociete}
                              filterOption={this.customFilterSousSociete}
                              noOptionsMessage={() => "Aucune société"}
                              placeholder="Votre société"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["sousSociete"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par type</Label>
                          {this.state.selectTypeSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTypeSociete}
                              onChange={this.handleTypeSociete}
                              noOptionsMessage={() => "Aucun type"}
                              placeholder="Votre type"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["type"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par client</Label>
                          {this.state.selectPrenomClient !== null && (
                            <Select
                              getOptionLabel={(option) =>
                                `${option.prenom} ${option.nom}`
                              }
                              getOptionValue={(option) =>
                                `${option.prenom} ${option.nom}`
                              }
                              styles={this.colourStyles}
                              options={this.state.selectTicketsClient}
                              onChange={this.handleClientChange}
                              filterOption={this.customFilterClient}
                              placeholder="Votre client"
                              noOptionsMessage={() => "Aucun client"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["client"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par mois : Date début</Label>
                          <Input
                            type="date"
                            name="datemin"
                            id="datemin "
                            placeholder="date placeholder"
                            onChange={this.handleDateMinChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label> Date fin</Label>
                          <InputGroup>
                            <Input
                              type="date"
                              name="datemax"
                              id="datemax "
                              placeholder="date placeholder"
                              onChange={this.handleDateMaxChange}
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par statut</Label>
                          {this.selectTicketsStatut !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.statut}`}
                              getOptionValue={(option) => `${option.statut}`}
                              styles={this.colourStyles}
                              options={this.selectTicketsStatut}
                              onChange={this.handleStatutChange}
                              noOptionsMessage={() => "Aucun statut"}
                              placeholder="Votre statut"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["statut"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par technicien</Label>
                          {this.selectTicketsTechnicien !== null && (
                            <Select
                              getOptionLabel={(option) =>
                                `${option.prenomTechnicien}`
                              }
                              getOptionValue={(option) =>
                                `${option.prenomTechnicien}`
                              }
                              styles={this.colourStyles}
                              options={this.selectTicketsTechnicien}
                              onChange={this.handleTechnicienChange}
                              value={
                                this.state.technicien !== "" && {
                                  technicienId: this.state.technicienId,
                                  prenomTechnicien: this.state.technicien,
                                }
                              }
                              placeholder={"Votre technicien"}
                              noOptionsMessage={() => "Aucun technicien"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["technicien"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par priorité</Label>
                          {this.selectTicketsPriorite !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.selectTicketsPriorite}
                              onChange={this.handlePrioriteChange}
                              noOptionsMessage={() => "Aucune priorité"}
                              placeholder="Votre priorité"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["priorite"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par catégorie</Label>
                          {this.state.selectTicketsCategorie !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsCategorie}
                              onChange={this.handleCategorieChange}
                              noOptionsMessage={() => "Aucune catégorie"}
                              placeholder="Votre catégorie"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["categorie"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
                {(this.state.typeSociete !== "" ||
                  this.state.sousSociete !== "" ||
                  this.state.clientId !== "" ||
                  this.state.statut !== "" ||
                  this.state.technicienId !== "" ||
                  this.state.priorite !== "" ||
                  this.state.categorie !== "" ||
                  (this.state.dateDebut !== null &&
                    this.state.dateFin !== null)) && (
                  <Col lg={12}>
                    <InputGroupAddon
                      addonType="append"
                      className="float-right mb-2 mr-2"
                    >
                      <button
                        className="btn btn-danger"
                        onClick={() => this.resetValueSelect()}
                      >
                        Effacer les filtres
                      </button>
                    </InputGroupAddon>
                  </Col>
                )}
              </Row>
            </TabPane>
          </TabContent>
        </Card>

        <div className="bg-white pt-2">
          <ButtonGroup className="mb-1 ml-2">
            <button
              className="btn selectButton"
              onClick={() => this.setState({ selected: "graphique" })}
              active={(this.state.selected === "graphique").toString()}
            >
              Graphique
            </button>
            <button
              className="btn selectButton"
              onClick={() => this.setState({ selected: "liste" })}
              active={(this.state.selected === "graphique").toString()}
            >
              Liste
            </button>
          </ButtonGroup>
          <div className="float-right mr-3">
            {this.state.selected === "graphique" ? (
              <ExcelFile
                element={
                  <button className="btn btn-success">
                    Exporter les données du graphique
                  </button>
                }
                filename={"Statistique des tickets"}
              >
                <ExcelSheet
                  data={this.state.dataGraphique}
                  name="Statistique des tickets"
                >
                  <ExcelColumn label="Technicien" value="technicien" />
                  <ExcelColumn label="Total" value="totalTickets" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              <ExcelFile
                element={
                  <button className="btn btn-success">
                    Exporter les données de la liste
                  </button>
                }
                filename={"Statistique des tickets"}
              >
                <ExcelSheet
                  data={this.state.dataListe}
                  name="Statistique des tickets"
                >
                  <ExcelColumn label="Id" value="id" />
                  <ExcelColumn label="Nom de la société" value="societe" />
                  <ExcelColumn label="Type de société" value="type" />
                  <ExcelColumn label="Prénom du client" value="client" />
                  <ExcelColumn label="Sujet" value="sujet" />
                  <ExcelColumn label="Statut" value="statut" />
                  <ExcelColumn label="Date de création" value="dateCreation" />
                  <ExcelColumn label="Technicien" value="technicien" />
                  <ExcelColumn label="Catégorie" value="categorie" />
                  <ExcelColumn label="Priorité" value="priorite" />
                </ExcelSheet>
              </ExcelFile>
            )}
          </div>

          {!this.state.isLoading && (
            <p className="h2 ml-2 font-weight-bold">
              Total : {this.state.tabFilterTickets.length}
            </p>
          )}
          {this.state.selected === "graphique" ? (
            this.state.isLoading ? (
              <Chargement />
            ) : (
              <>
                <GraphiqueTicket
                  optionsbar={this.optionsbar}
                  seriesbar={this.seriesbar}
                />
              </>
            )
          ) : (
            <TableauTicket
              tickets={this.state.tabFilterTickets}
              isLoading={this.state.isLoading}
            />
          )}
        </div>
      </div>
    );
  }
}
