import React from "react";
import logoAPSchool from "../../../assets/images/logo-apschool.png";
import logoAPChantier from "../../../assets/images/logo-apchantier.png";

export default function retourneProduit(produit, taille = 22) {
  if (!produit) return "";

  if (produit === "APSCHOOL")
    return <img src={logoAPSchool} width={`${taille}px`} height={`${taille}px`} alt="Logo d'APSchool" />

if (produit === "APCHANTIER")
    return <img src={logoAPChantier} width={`${taille}px`} height={`${taille}px`} alt="Logo d'APChantier" />

  return produit;
}
