import React, { Component } from "react";
import { Col, Row } from "reactstrap";

export default class GraphiqueCloture extends Component {
  render() {
    const {
      optionsPieSociete,
      seriesPieSociete,
      optionsdoughnut,
      seriesdoughnut,
      optionsPieSousCategorie,
      seriesPieSousCategorie,
      nbTickets,
      optionsModule,
      seriesModule,
    } = this.props;
    return (
      <div>
        {nbTickets !== 0 ? (
          <>
            <Row>
              <Col lg={4}></Col>
              <Col lg={4}></Col>
              <Col lg={4}></Col>
            </Row>
            <Row>
              <Col lg={4}></Col>
              <Col lg={4}></Col>
              <Col lg={4}></Col>
            </Row>
          </>
        ) : (
          <p className="h1 text-center p-5 font-weight-bold">
            Aucun ticket clôturé
          </p>
        )}
      </div>
    );
  }
}
