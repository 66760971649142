import {authHeader, handleResponse } from '../_helpers';
import RecupereUtilisateurId from '../../components/Fonctions/GestionToken/RecupereUtilisateurId'


export const sousSocieteService = {
    // (GET)
    RecupereEcoleOuChantierParTypeSocieteId
};

// Récupérer tous les tickets du client
function RecupereEcoleOuChantierParTypeSocieteId(nomDb){
    const requestOptions = {method: "GET",headers: authHeader()};
    let url = '';
    
    
    switch (nomDb) {
        case "APSCHOOL":
            url = process.env.REACT_APP_API + "/sousSocietes/ecoles/" + RecupereUtilisateurId();
            break;  
        default:
            url = process.env.REACT_APP_API + "/sousSocietes/chantiers/" + nomDb;
            break;
    }
    

    return fetch(url, requestOptions)
                .then(handleResponse)
                .then((reponse) => {
                    return reponse;
                });
}
