import React from "react";
import { Col, Row, Button } from "reactstrap";
import { utilisateurService } from "../../jwt/_services/utilisateur.service";
import RecupereUtilisateurId from "../Fonctions/GestionToken/RecupereUtilisateurId";
import Swal from "sweetalert2";
import ModalProfil from "../Profil/ModalProfil";

class Informations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalProfil: false,
      profilUtilisateur: "",
      display: true,
      infoUtil: props.infoUtil,
    };
    this.id = RecupereUtilisateurId();
  }

  componentDidMount() {
    this.getUtilisateurProfilById();
  }

  getUtilisateurProfilById = () => {
    utilisateurService.RecupereUtilisateurProfilParId(this.id).then(
      (utilisateur) => {
        this.setState({
          profilUtilisateur: utilisateur,
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Une erreur est survenue !",
          text: error.messages,
        });
      }
    );
  };

  toggleModal = () => {
    this.setState(
      {
        modalProfil: !this.state.modalProfil,
      },
      () => {
        this.getUtilisateurProfilById();
        if (this.props.ticketId === "creation") {
          utilisateurService
            .RecupereUtilisateurProfilParId(this.id)
            .then((utilisateur) => {
              this.setState(
                {
                  infoUtil: utilisateur,
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              );
            });
        } else {
          utilisateurService
            .RecupereUtilisateurProfilParId(this.props.ticket.clientId)
            .then((utilisateur) => {
              this.setState(
                {
                  infoUtil: utilisateur,
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              );
            });
        }
      }
    );
  };

  connectToClientAccount = () => {
    utilisateurService
      .RecupereToken(this.props.ticket.clientId)
      .then((currentUser) =>
        window.open(
          `https://plateforme.apschool.be/authentication/login/${currentUser.token}`
        )
      );
  };

  render() {
    const { ticket } = this.props;
    const { infoUtil, profilUtilisateur } = this.state;
    return (
      <Row>
        <Col xs="12">
          <div className="d-flex flex-wrap">
            <div className="p-2 header titleHeaderInfo">
              <div>
                <p
                  className="cursor ml-4"
                  onClick={() => {
                    this.setState({ display: !this.state.display });
                  }}
                >
                  {this.props.header}{" "}
                  {this.state.display ? (
                    <i className="fas fa-chevron-down chevronDown float-right mr-2"></i>
                  ) : (
                    <i className="fas fa-chevron-up float-right mr-2"></i>
                  )}
                </p>
              </div>
            </div>
            {this.state.display && (
              <div className="informations">
                <p className="nom d-flex align-items-center justify-content-center">
                  Client :{" "}
                  <span className="span ml-1">
                    {infoUtil.nom} {infoUtil.prenom}
                  </span>
                  {profilUtilisateur.role?.nom !== "Client" && (
                    <Button
                      onClick={this.connectToClientAccount}
                      className="btn btn-secondary btn-connection round ml-2"
                    >
                      Connexion <i className="mdi mdi-open-in-new" />
                    </Button>
                  )}
                </p>
                <p className="email">
                  Email :{" "}
                  <span className="span">
                    {infoUtil.mail != null && infoUtil.mail}
                  </span>
                </p>
                <p className="tel">
                  Téléphone :{" "}
                  <span className="span">
                    {infoUtil.telephone != null && infoUtil.telephone}
                  </span>
                </p>
                <p className="tel">
                  Mobile :{" "}
                  <span className="span">
                    {infoUtil.mobile != null && infoUtil.mobile}
                  </span>
                </p>
                <p className="adresse">
                  Adresse :{" "}
                  <span className="span">
                    {infoUtil.adresse != null &&
                      infoUtil.adresse.rue != null &&
                      infoUtil.adresse.rue}{" "}
                    {infoUtil.adresse != null &&
                      infoUtil.adresse.ville != null &&
                      infoUtil.adresse.ville}{" "}
                    {infoUtil.adresse != null &&
                      infoUtil.adresse.codePostal != null &&
                      infoUtil.adresse.codePostal}{" "}
                    {infoUtil.adresse != null &&
                      infoUtil.adresse.pays != null &&
                      infoUtil.adresse.pays}
                  </span>
                </p>
                {((ticket !== undefined &&
                  ticket.clientId !== undefined &&
                  parseInt(ticket.clientId) === parseInt(this.id)) ||
                  this.props.ticketId === "creation") && (
                  <div className="float-right divBtnSend">
                    <Button
                      className="btn btn-secondary btnEdit mt-2"
                      onClick={this.toggleModal}
                    >
                      Modifier mes informations
                    </Button>
                    <ModalProfil
                      isOpen={this.state.modalProfil}
                      utilisateur={this.state.profilUtilisateur}
                      fermer={this.toggleModal}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default Informations;
