import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import RecupereRoleId from '../Fonctions/GestionToken/RecupereRoleId';

class BackButton extends Component {
  constructor(props) {
    super(props);
    this.roleId = RecupereRoleId();
    this.state = {
      modal: false,
      redirect: false,
      path:
        props.type === 'tickets'
          ? '/support/statistique/ticket'
          : props.type === 'duree'
          ? '/support/statistique/duree'
          : props.type === 'cloture'
          ? '/support/statistique/cloture'
          : props.type === 'gestionTemps'
          ? '/support/gestion-du-temps/tous'
          : props.path,
    };
  }

  openModal = () => this.setState({modal: !this.state.modal});
  redirectModal = () => this.setState({redirect: true});
  closeModal = () => this.setState({modal: !this.state.modal}, () => window.location.reload());

  render() {

    const { redirect, path, modal } = this.state;
    const { messages, informationClient, type, client, minified } = this.props;

    return (
      <div>
        {redirect && (
          path === '/support/gestion-du-temps/tous' && this.roleId !== '3' && this.roleId !== '1' ? (
            <Redirect push to={{ pathname: path, state: { type: 'tempsDev' } }} />
          ) : (
            <Redirect push to={path} />
          )
        )}

        { !minified ? (
          <span onClick={messages !== '' ? this.openModal : this.redirectModal} className="btn btn-secondary backButton">
            Retour
          </span>
        ) : (
          <span onClick={messages !== '' ? this.openModal : this.redirectModal} className="btn btn-secondary backButton d-flex align-items-center justify-content-center mx-2" style={{height: 40, width: 40, fontSize: 20}}>
            <i className='mdi mdi-chevron-left' />
          </span>
        )}

        <Modal isOpen={modal} toggle={this.openModal} size="lg" className="Modal">
          <ModalHeader toggle={this.openModal}>Êtes-vous sûr ?</ModalHeader>
          <ModalBody>
            <div>
              {window.location.pathname === '/client/ticket/creation' ? (
                <span className="textDeco">Voulez-vous vraiment supprimez votre ticket ?</span>
              ) : informationClient === 'info' ? (
                <span className="textDeco">Voulez-vous vraiment retourner à la liste des tickets ?</span>
              ) : client !== 'Tous' ? (
                <span className="textDeco">Voulez-vous vraiment retourner à la liste des statistiques clients ?</span>
              ) : type === 'tickets' ? (
                <span className="textDeco">Voulez-vous vraiment retourner à la liste des statistiques tickets ?</span>
              ) : type === 'duree' ? (
                <span className="textDeco">Voulez-vous vraiment retourner à la liste des statistiques durées ?</span>
              ) : type === 'cloture' ? (
                <span className="textDeco">Voulez-vous vraiment retourner à la liste des statistiques clôture ?</span>
              ) : type === 'gestionTemps' ? (
                <span className="textDeco">Voulez-vous vraiment retourner à la liste de la gestion du temps ?</span>
              ) : (
                <span className="textDeco">Voulez-vous vraiment retourner à la liste des tickets ?</span>
              )}
            </div>
            <div className="text-center">
              <span onClick={this.redirectModal} className="btn btnModalYes btnModal text-white">
                Oui
              </span>
              <span onClick={this.openModal} className="btn btnModalNo btnModal text-white">
                Non
              </span>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default BackButton;
