import React, { Component } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import { messageService } from "../../jwt/_services/message.service";
import { ticketService } from "../../jwt/_services/ticket.service";
import MyEditor from "../Editor/MyEditor";
import RecupereRoleId from "../Fonctions/GestionToken/RecupereRoleId";
import RecupereUtilisateurId from "../Fonctions/GestionToken/RecupereUtilisateurId";
import retourneProduit from "../Fonctions/Utils/retourneProduit";
import ModalPieceJointe from "../Ticket/ModalPieceJointe";
import Message from "./Message";

class MessagerieTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFichier: false,
      pieceJointeChoisie: [],
      modal: false,
      message: "",
      fichiers: [],
    };
    this.roleId = RecupereRoleId();
    this.utilisateurId = RecupereUtilisateurId();
  }

  RecuperePieceJointeModal = (pieceJointeModal) => {
    let fichiers = [];
    this.setState(
      {
        pieceJointeChoisie: pieceJointeModal,
      },
      () => {
        this.state.pieceJointeChoisie.map((piece) => fichiers.push(piece.name));
        this.setState({
          fichiers: fichiers,
        });
      }
    );
  };

  toggle = () => {
    this.setState({
      modalFichier: !this.state.modalFichier,
    });
  };

  openModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  closeModal = () => {
    this.setState(
      {
        modal: !this.state.modal,
      },
      () => {
        Swal.fire({
          title: "Opération réussie !",
          text: "Le message a bien été envoyé",
          icon: "success",
        }).then((resultat) => {
          if (resultat) {
            window.location.reload();
          }
        });
      }
    );
  };

  sendMail = () => {
    this.setState(
      {
        modal: !this.state.modal,
      },
      () => {
        let sujet = "Réponse support APKiosk";
        messageService
          .EnvoyerMail(
            sujet,
            this.props.client.mail,
            this.utilisateurId,
            this.props.ticketId
          )
          .then(
            () => {
              Swal.fire({
                title: "Opération réussie !",
                text: "Le message et le mail ont bien été envoyé",
                icon: "success",
              }).then((resultat) => {
                if (resultat) {
                  window.location.reload();
                }
              });
            },
            (error) => {
              Swal.fire({
                icon: "error",
                title: "Une erreur est survenue !",
                text: error.messages,
              }).then((resultat) => {
                if (resultat) {
                  window.location.reload();
                }
              });
            }
          );
      }
    );
  };

  sendMessage = () => {
    /* On envoie le message en fonction de si c'est le client ou le support on modifie différement le ticket */
    messageService
      .EnregistrerMessage(
        this.utilisateurId,
        this.props.ticketId,
        this.state.message,
        this.state.pieceJointeChoisie
      )
      .then(
        (message) => {
          // support
          if (
            parseInt(this.props.ticket.clientId) !==
            parseInt(message.resource.utilisateurId)
          ) {
            ticketService
              .ModifierTicket(
                this.props.ticketId,
                "En attente d'un retour",
                this.utilisateurId
              )
              .then(() => {
                this.openModal();
              });
          }
          // client
          else {
            ticketService
              .ModifierTicket(this.props.ticketId, "Ouvert", null)
              .then(() => {
                Swal.fire({
                  title: "Opération réussie !",
                  text: "Le message a bien été envoyé",
                  icon: "success",
                }).then((resultat) => {
                  if (resultat) {
                    window.location.reload();
                  }
                });
              });
          }
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue !",
            text: error.messages,
          });
        }
      );
  };

  retourneFichier(messageId) {
    let fichiers = this.props.fichiers;
    fichiers = fichiers.filter((f) => f.messageId === messageId);

    return fichiers !== null ? fichiers : [];
  }

  handleChangeMessage = (e) => {
    this.setState(
      {
        message: e.editor.getData(),
      },
      () => {
        this.props.messageCallback(this.state.message);
      }
    );
  };

  render() {
    const { messages, ticket, typeSociete, backButton } = this.props;

    return (
      <Row>
        <Col xs="12">
          <div className="d-flex flex-wrap">
            <div className="p-2 topHeaderMessagerie d-flex align-items-center">
              {backButton}
              <div className="flex-grow-1 ml-1">
                <Row>
                  <Col>
                    {this.roleId === "2" ? (
                      <div className="mx-1 mt-2 h4 text-white">
                        {ticket !== null && (
                          <p className="d-flex align-items-center">
                            <span className="font-weight-bold">N°</span>
                            {ticket.id}
                            <span className="font-weight-bold ml-1"> - </span>
                            {ticket.sousSocieteId}
                            <span className="font-weight-bold ml-1"> - </span>
                            {typeSociete}
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="mx-1 mt-2 h4 text-white d-flex align-items-center justify-content-between">
                        {ticket && (
                          <>
                            <p className="mb-0">
                              #{ticket.id} {ticket?.sousSociete.nom}
                            </p>
                            {retourneProduit(typeSociete, 26)}
                          </>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mx-1 h4 text-white d-flex justify-content-between">
                      {ticket && (
                        <>
                          <p className="mb-1">
                            <b className="d-none-mobile">Sujet:</b>{" "}
                            {ticket.sujet}
                          </p>
                          <p className="mb-1">
                            <b className="d-none-mobile">Élément:</b>{" "}
                            {ticket.element}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
        <Col xs="12">
          <div className="d-flex flex-wrap">
            <div className={this.props.insideClassCloture + " mb-3"}>
              {ticket !== null && ticket.statut !== "Clôture" && (
                <div className="writeMessage">
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <p className="textWriteMessage pl-3">
                          Écrivez votre message :{" "}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="4" className="pr-4">
                      {this.state.pieceJointeChoisie.length === 0 ? (
                        <p
                          className="btn btnFichier mr-3 float-right"
                          onClick={this.toggle}
                        >
                          Choisir une pièce jointe
                        </p>
                      ) : (
                        <p className="mr-3 float-right" onClick={this.toggle}>
                          <i
                            className="fas fa-paperclip"
                            title={this.state.fichiers}
                          ></i>{" "}
                          <span className="btn btnFichier">
                            Vos pièces jointes
                          </span>
                        </p>
                      )}
                      <ModalPieceJointe
                        pieceJointeCallBack={this.RecuperePieceJointeModal}
                        isOpen={this.state.modalFichier}
                        fermer={this.toggle}
                      />
                    </Col>
                  </Row>
                  <div className="pl-4 pr-4">
                    <MyEditor
                      onChange={(content) =>
                        this.setState({ message: content })
                      }
                      style={{ zIndex: 1 }}
                    />
                  </div>

                  <div className="float-right mt-5">
                    <Button
                      onClick={this.sendMessage}
                      className="btn btn-secondary mr-4 btnSend mt-2"
                      style={{ zIndex: 999 }}
                    >
                      Envoyer
                    </Button>
                  </div>
                </div>
              )}
              <p className="textDiscussion ml-3 pl-3 mt-5">Discussion</p>
              <div className={this.props.classCloture + " mb-3"}>
                {messages.length !== 0 &&
                  messages.map((message, i) => {
                    return (
                      <Message
                        key={i}
                        message={message}
                        fichiers={this.retourneFichier(message.id)}
                      />
                    );
                  })}
              </div>
            </div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.openModal}
              size="lg"
              className="Modal"
            >
              <ModalHeader toggle={this.openModal}>Êtes-vous sûr ?</ModalHeader>
              <ModalBody>
                <div>
                  <span className="textDeco">
                    Voulez-vous envoyer un mail au client ?
                  </span>
                </div>
                <div className="text-center">
                  <span
                    onClick={this.sendMail}
                    className="btn btnModalYes btnModal text-white"
                  >
                    Oui
                  </span>
                  <span
                    onClick={this.closeModal}
                    className="btn btnModalNo btnModal text-white"
                  >
                    Non
                  </span>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </Col>
      </Row>
    );
  }
}

export default MessagerieTicket;
