import React, {Component} from "react";

import {connect} from "react-redux";
import {
    Navbar,
    NavbarBrand,
    Collapse,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import Swal from "sweetalert2";
import {authenticationService} from '../../../jwt/_services/authentication.service';

import logolighticon from "../../../assets/images/icone-logo-apkiosk-blanc.svg";
import logolighttext from "../../../assets/images/logo-light-text.png";



import {
    setDir,
    setHeaderPos,
    setLayout,
    setLogoBg,
    setNavbarBg,
    setSidebarBg,
    setSidebarPos,
    setTheme
} from "../../../redux/settings/action";

const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = (dispatch) => ({
    setLogoBg: (payload) => dispatch(setLogoBg(payload)),
    setNavbarBg: (payload) => dispatch(setNavbarBg(payload)),
    setSidebarBg: (payload) => dispatch(setSidebarBg(payload)),
    setTheme: (payload) => dispatch(setTheme(payload)),
    setDir: (payload) => dispatch(setDir(payload)),
    setSidebarPos: (payload) => dispatch(setSidebarPos(payload)),
    setHeaderPos: (payload) => dispatch(setHeaderPos(payload)),
    setLayout: (payload) => dispatch(setLayout(payload))
});


class Header extends Component {
    
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.showMobilemenu = this.showMobilemenu.bind(this);
        this.sidebarHandler = this.sidebarHandler.bind(this);
        this.state = {
            isOpen: false,
            modal: false
        };       
    }    
      
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    openModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    closeConnection = () => {
        if(JSON.parse(localStorage.getItem("timerStart")) !== null){
            Swal.fire({
                icon: "error",
                title: "Une erreur est survenue !",
                text: `Veuillez mettre le ticket ${JSON.parse(localStorage.getItem("timerStart"))} en pause avant de vous déconnecter !`
            });
        }else{
            this.setState({
                modal: !this.state.modal
            })
            authenticationService.logout();
            window.location.reload()
        }
    }
    
    showMobilemenu() {
        
        document.getElementById("main-wrapper").classList.toggle("show-sidebar");
    }
        
    sidebarHandler = () => {
        let element = document.getElementById("main-wrapper");
        switch (this.props.settings.activeSidebarType) {
            case "full":
            case "iconbar":
                element.classList.toggle("mini-sidebar");
                if (element.classList.contains("mini-sidebar")) {
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                }
                else {
                    element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
                }
                break;
            
            case "overlay":
            case "mini-sidebar":
                element.classList.toggle("full");
                if (element.classList.contains("full")) {
                    element.setAttribute("data-sidebartype", "full");
                }
                else {
                    element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
                }
                break;
            
            default:
        }
    };
        
    render() {
        
        return (
            <header className="topbar navbarbg" data-navbarbg={this.props.settings.activeNavbarBg}>
                <Navbar
                    className={"top-navbar navbar-dark"}
                    expand="md"
                >
                    <div className="navbar-header" id="logobg" data-logobg={this.props.settings.activeLogoBg}>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        <span className="nav-toggler d-block d-md-none text-white" onClick={this.showMobilemenu}>
                            <i className="ti-menu ti-close"/>
                        </span>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
                        {/*--------------------------------------------------------------------------------*/}
                        <NavbarBrand href="/" className="navbarBrand">
                            <b className="logo-icon">
                                <img src={logolighticon} width="55px" alt="homepage" className="light-logo"/>
                            </b>
							<span className="logo-text">
                                <img src={logolighttext} width="180px" className="light-logo" alt="homepage"/>
                            </span>
                        </NavbarBrand>
                        {/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span
							className="topbartoggler d-block d-md-none text-white"
							onClick={this.toggle}
						>
							<i className="ti-more" />
						</span>
                    </div>
                    <Collapse
						className="navbarbg"
						isOpen={this.state.isOpen}
						navbar
						data-navbarbg={this.props.settings.activeNavbarBg}>
                        
                        <Nav className="ml-auto float-right" navbar>
                            <UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret>
                                    <span onClick={this.openModal} className="btn text-white">Se déconnecter</span>
                                    
							    </DropdownToggle>
                            </UncontrolledDropdown>
                        </Nav>
                    
                        
                    </Collapse>
                   
                </Navbar>
                <Modal isOpen={this.state.modal} toggle={this.openModal} size="lg" className="Modal">
                    <ModalHeader toggle={this.openModal}>Êtes-vous sûr ?</ModalHeader>
                    <ModalBody>
                        <div>
                            <span className="textDeco">Voulez-vous vraiment vous déconnecter ?</span>
                        </div>
                        <div className="text-center">
                            <span onClick={this.closeConnection} className="btn btnModalYes btnModal text-white">Oui</span>
                            <span onClick={this.openModal} className="btn btnModalNo btnModal text-white">Non</span>
                        </div>
                    </ModalBody>
                </Modal>
            </header>
        );
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Header));
