import React, { Component } from 'react';
import {Modal,Table,ModalHeader, ModalBody,ModalFooter} from 'reactstrap'


export default class ModalPieceJointe extends Component {
    constructor(props){
        super(props);
        this.state = {
            piecesJointes: []
        }
    }

     handleFiles = (e) => { // Affichage des fichiers qui vont être envoyés
        e.preventDefault()
        let files = e.currentTarget.files;
        let piecesJointesArr = Array.prototype.slice.call(files);
        this.setState({piecesJointes: [...this.state.piecesJointes, ...piecesJointesArr]}, () => {
            this.sendPieceJointeToForm(this.state.piecesJointes)
        });

    }

    supprimePieceJointe(fichier) {
        this.setState({piecesJointes: this.state.piecesJointes.filter(file => file !== fichier)}, () => {
            this.sendPieceJointeToForm(this.state.piecesJointes)
        });
    }

    /* On envoie les pièces jointes au composant parent */
    sendPieceJointeToForm = (info) => {
        this.props.pieceJointeCallBack(info);
   }
    
    render() {
        const {piecesJointes} = this.state
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.fermer} size="lg" className="Modal" >
                <ModalHeader toggle={this.props.fermer}>
                    {piecesJointes.length <= 1 ? (<p className="h3">Nom du fichier</p>) : (<p className="h3">Nom des fichiers</p>)}   
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <tbody>
                            { 
                                piecesJointes !== undefined && (
                                    piecesJointes.map((fichier, index) =>
                                    <tr key={index}>
                                        <td className="file-preview" style={{marginRight: 10}}
                                                onClick={this.supprimePieceJointe.bind(this, fichier)}> 
                                            {fichier.name + " "}
                                            <i className="fa fa-minus-circle pointer"/>
                                        </td>
                                    </tr>  
                                    )
                                )
                            }
                            
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <div className="float-right">
                        <form encType="multipart/form-data" action="">  
                            <label>
                                <p className="btn btnFichierModal btn-md mb-0">
                                    <i className="mdi mdi-attachment px-1"/> Ajouter une pièce jointe 
                                </p>
                                <input
                                    id="piecesJointes"
                                    type="file"
                                    name="piecesJointes"
                                    multiple
                                    style={{display: 'none'}}
                                    ref={this.fileInput}
                                    onChange={this.handleFiles}
                                />
                            </label>
                            <p onClick={this.props.fermer} className="btn btnValider btn-md mb-0 ml-2">Valider</p>
                        </form>
                    </div>
                    
                </ModalFooter>
            </Modal>
            
        );
    }
}
