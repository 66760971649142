import { authHeader, handleResponse, handleBlobResponse } from "../_helpers";

export const ticketService = {
  // (GET)
  RecupereTicketsParClientIdAvecFiltre,
  RecupereTicketsParStatutEtClientId,
  RecupereTicketsParStatut,
  RecupereTicketsParId,
  RecupereTicketsAvecFiltre,
  RecupereTotalTicketsParClient,
  RecupereStatistiqueTickets,
  RecupereStatistiqueCloture,
  RecupereStatistiqueDuree,
  RecupereTicketsTempsParTechnicien,

  // Enregistrement (POST):
  EnregistrerTicket,
  ExporterExcelTickets,

  // Modification (PUT):
  ModifierNoteTicket,
  ModifierTicket,
  ModifierInformationTicket,
  ModifierDateChronometreTicket,
  ModifierTempsTechnicienTicket,
  ModifierFacturationTicket,
};

// Récupérer tous les tickets du client
function RecupereTicketsParClientIdAvecFiltre(
  id,
  sousSociete,
  statut,
  priorite,
  dateDebut,
  dateFin,
  utilisateurId
) {
  const requestOptions = { method: "GET", headers: authHeader() };
  let url =
    process.env.REACT_APP_API +
    "/tickets/clients/" +
    id +
    "?sousSociete=" +
    sousSociete +
    "&statut=" +
    statut +
    "&priorite=" +
    priorite;
  if (dateDebut !== null && dateFin !== null) {
    url = url + `&dateDebut=${dateDebut}&dateFin=${dateFin}`;
  }
  if (utilisateurId > -1) url += `&utilisateurId=${utilisateurId}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

async function RecupereTicketsParStatutEtClientId(statut, id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  let url = process.env.REACT_APP_API + "/tickets/clients/" + statut + "/" + id;

  return await fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

async function RecupereTicketsParId(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  let url = `${process.env.REACT_APP_API}/tickets/tous/${id}`;

  return await fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

async function RecupereTicketsParStatut(statut) {
  const requestOptions = { method: "GET", headers: authHeader() };
  let url = process.env.REACT_APP_API + "/tickets/" + statut;

  return await fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereTotalTicketsParClient(
  idClient,
  sousSociete,
  dateDebut,
  dateFin
) {
  const requestOptions = { method: "GET", headers: authHeader() };
  let url = `${process.env.REACT_APP_API}/tickets/statistiques/clients?clientId=${idClient}&sousSocieteFiltre=${sousSociete}`;
  if (dateDebut !== null && dateFin !== null) {
    url = url + `&dateDebut=${dateDebut}&dateFin=${dateFin}`;
  }

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereTicketsAvecFiltre(
  sousSociete,
  typeSociete,
  client,
  statut,
  priorite,
  technicien,
  dateDebut,
  dateFin
) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = `${process.env.REACT_APP_API}/tickets/supports?sousSociete=${sousSociete}&typeSociete=${typeSociete}&client=${client}&statut=${statut}&priorite=${priorite}&technicien=${technicien}`;
  if (dateDebut !== null && dateFin !== null) {
    url = url + `&dateDebut=${dateDebut}&dateFin=${dateFin}`;
  }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereTicketsTempsParTechnicien(
  technicienId,
  societe,
  type,
  priorite,
  clientId,
  facturation,
  statut,
  dateDebut,
  dateFin
) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = `${process.env.REACT_APP_API}/tickets/temps/${technicienId}?societe=${societe}&type=${type}&priorite=${priorite}&clientId=${clientId}&facturation=${facturation}&statut=${statut}`;
  if (dateDebut !== null && dateFin !== null) {
    url = url + `&dateDebut=${dateDebut}&dateFin=${dateFin}`;
  }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereStatistiqueTickets(
  sousSociete,
  typeSociete,
  clientId,
  dateDebut,
  dateFin,
  statut,
  technicienId,
  priorite,
  categorie
) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = `${process.env.REACT_APP_API}/tickets/statistiques/tickets?sousSociete=${sousSociete}&typeSociete=${typeSociete}&clientId=${clientId}&statut=${statut}&technicienId=${technicienId}&priorite=${priorite}&categorie=${categorie}`;
  if (dateDebut !== null && dateFin !== null) {
    url = url + `&dateDebut=${dateDebut}&dateFin=${dateFin}`;
  }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereStatistiqueDuree(
  sousSociete,
  typeSociete,
  clientId,
  dateDebut,
  dateFin,
  technicienId,
  priorite,
  typeCategorie
) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = `${process.env.REACT_APP_API}/tickets/statistiques/durees?sousSociete=${sousSociete}&typeSociete=${typeSociete}&clientId=${clientId}&technicienId=${technicienId}&priorite=${priorite}&typeCategorie=${typeCategorie}`;
  if (dateDebut !== null && dateFin !== null) {
    url = url + `&dateDebut=${dateDebut}&dateFin=${dateFin}`;
  }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function RecupereStatistiqueCloture(
  sousSociete,
  typeSociete,
  clientId,
  dateDebut,
  dateFin,
  technicienId,
  priorite,
  categorieId,
  sousCategorieId,
  moduleId
) {
  const requestOptions = { method: "GET", headers: authHeader(true) };
  let url = `${process.env.REACT_APP_API}/tickets/statistiques/clotures?sousSociete=${sousSociete}&typeSociete=${typeSociete}&clientId=${clientId}&technicienId=${technicienId}&priorite=${priorite}&categorieId=${categorieId}&sousCategorieId=${sousCategorieId}&moduleId=${moduleId}`;
  if (dateDebut !== null && dateFin !== null) {
    url = url + `&dateDebut=${dateDebut}&dateFin=${dateFin}`;
  }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function EnregistrerTicket(
  utilisateurId,
  sousSociete,
  priorite,
  typeCategorie,
  element,
  sujet,
  message,
  pieceJointes
) {
  let formData = new FormData();
  formData.append("SousSociete", sousSociete);
  formData.append("Priorite", priorite);
  formData.append("TypeCategorie", typeCategorie);
  formData.append("Element", element);
  formData.append("Sujet", sujet);
  formData.append("Message", message);

  // eslint-disable-next-line
  for (let key of Object.keys(pieceJointes)) {
    formData.append("Fichier", pieceJointes[key]);
  }

  const url = `${process.env.REACT_APP_API}/tickets/${utilisateurId}`;

  let requestOptions = {
    method: "POST",
    headers: authHeader(false, false, true),
    body: formData,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function ExporterExcelTickets() {
  const url = `${process.env.REACT_APP_API}/tickets/excel`;

  const requestOptions = {
    method: "POST",
    headers: authHeader(true),
    body: "",
  };

  return fetch(url, requestOptions)
    .then(handleBlobResponse)
    .then((reponse) => {
      return reponse;
    });
}

function ModifierNoteTicket(id, note) {
  const json = JSON.stringify({ id, note });
  const url = `${process.env.REACT_APP_API}/tickets/notes`;

  let requestOptions = {
    method: "PUT",
    headers: authHeader(true),
    body: json,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function ModifierTicket(id, statut, technicienId) {
  const json = JSON.stringify({ statut, technicienId });
  const url = `${process.env.REACT_APP_API}/tickets/${id}`;

  let requestOptions = {
    method: "PUT",
    headers: authHeader(true),
    body: json,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function ModifierInformationTicket(
  id,
  statut,
  technicienId,
  facturation,
  priorite,
  categorieId,
  sousCategorieId,
  moduleId
) {
  const json = JSON.stringify({
    statut,
    technicienId,
    facturation,
    priorite,
    categorieId,
    sousCategorieId,
    moduleId,
  });
  const url = `${process.env.REACT_APP_API}/tickets/informations/${id}`;

  let requestOptions = {
    method: "PUT",
    headers: authHeader(true),
    body: json,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function ModifierDateChronometreTicket(id, isStarted, isReset) {
  const json = JSON.stringify({ id, isStarted, isReset });
  const url = `${process.env.REACT_APP_API}/tickets/chronometres`;

  let requestOptions = {
    method: "PUT",
    headers: authHeader(true),
    body: json,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function ModifierTempsTechnicienTicket(id, tempsTechnicien) {
  const json = JSON.stringify({ id, tempsTechnicien });
  const url = `${process.env.REACT_APP_API}/tickets/tempsTechniciens`;

  let requestOptions = {
    method: "PUT",
    headers: authHeader(true),
    body: json,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}

function ModifierFacturationTicket(id, facturation) {
  const json = JSON.stringify({ id, facturation });
  const url = `${process.env.REACT_APP_API}/tickets/facturations`;

  let requestOptions = {
    method: "PUT",
    headers: authHeader(true),
    body: json,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((reponse) => {
      return reponse;
    });
}
