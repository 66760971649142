import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import TableauTicketSupport from "../../../components/Ticket/TableauTicketSupport";
import BackButton from "../../../components/General/BackButton";
import Chargement from "../../../components/Chargement/Chargement";

class TicketAllSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: "Tous",
      isLoading: true,
    };
    this.nameHeader = [
      "#",
      "Id",
      "Société",
      "Produit",
      "Client",
      "Note",
      "Sujet",
      "Priorité",
      "Statut",
      "Module",
      "Technicien",
      "Crée le",
      "Modifié le",
    ];
  }
  componentDidMount() {
    if (this.props.clientId !== undefined) {
      this.setState({
        client: this.props.clientId,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <Chargement />
        ) : (
          <>
            {this.state.client !== "Tous" && (
              <BackButton
                path="/support/statistique/client"
                messages=""
                client={this.state.client}
              />
            )}
            <Row>
              <Col lg="12" md="12">
                <TableauTicketSupport
                  nameHeader={this.nameHeader}
                  statut="Tous"
                  client={this.state.client}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

export default TicketAllSupport;
