import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../jwt/_services';
import RecupereRoleId from '../components/Fonctions/GestionToken/RecupereRoleId'
let roleId = 0
const currentUser = JSON.parse(localStorage.getItem("currentUser"));
if(currentUser != null){
    roleId = RecupereRoleId()
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser || props.location.pathname.includes("/authentication/login")) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />
        }
        
        if (props.location.pathname === "/") {
            switch (roleId) {
                case "1":
                    return (
                        <Redirect
                            to={{
                                pathname: "/support/ticket/en-attente",
                                state: {from: props.location}
                            }}
                        />
                    );
                case "2":
                    return (
                        <Redirect
                            to={{
                                pathname: "/client/ticket/tous",
                                state: {from: props.location}
                            }}
                        />
                    );
                case "3":
                    return (
                        <Redirect
                            to={{
                                pathname: "/support/gestion-du-temps/tous",
                                state: {from: props.location}
                            }}
                        />
                    );
                case "4":
                    return (
                        <Redirect
                            to={{
                                pathname: "/support/ticket/en-attente",
                                state: {from: props.location}
                            }}
                        />
                    );
                default:
                    break;
            }
           
        }
        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;