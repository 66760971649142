import React, { Component } from 'react'
import TableauTempsTickets from '../../../components/Ticket/TableauTempsTickets'
import RecupereRoleId from '../../../components/Fonctions/GestionToken/RecupereRoleId'
import {Col, Row} from 'reactstrap'
export default class GestionTemps extends Component {
    constructor(props) {
        super(props);
        this.roleId = RecupereRoleId()
        this.nameHeaderDev = ["N°", "N° société","Nom de la société", "Type de société", "Nom du client", "Sujet","Priorité", "Statut","Module", "Date de création", "Temps", "Facturation"]
        this.nameHeaderAnalyste = ["N°", "N° société","Nom de la société", "Type de société", "Nom du client", "Sujet","Priorité", "Statut","Module", "Date de création","Technicien", "Temps", "Facturation"]
    }
    render() {
        return (
            <div>
                <Row>
                    <Col lg="12" md="12" className="mt-2">
                        <TableauTempsTickets type={this.props.type} utilisateurId={this.props.utilisateurId} nameHeader={(this.roleId === "3"  ||this.roleId === "1" ) ? this.nameHeaderAnalyste : this.nameHeaderDev} />
                    </Col>
                </Row>
            </div>
        )
    }
}

