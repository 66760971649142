import { authenticationService } from '../_services';

export function authHeader(json = false, file = false, custom = false) {
    const currentUser = authenticationService.currentUserValue;

    // Si l'utilisateur est stocké dans le navigateur et que celui-ci possède le token, on envoie le token.
    if (currentUser && currentUser.token) {
        if (json) return {Authorization: `Bearer ${currentUser.token}`, "Content-Type": "application/json"};
        if (file) return {Authorization: `Bearer ${currentUser.token}`, "Content-Type": "multipart/form-data"};
        if (custom) return {Authorization: `Bearer ${currentUser.token}`};
        return {Authorization: `Bearer ${currentUser.token}`};
    } else {
        return {};
    }
}