import React, { Component } from 'react';
import {Col, Row,Modal,ModalHeader, ModalBody, Label, Input,FormGroup} from 'reactstrap'
import { utilisateurService } from '../../jwt/_services/utilisateur.service';
import RecupereUtilisateurId from '../Fonctions/GestionToken/RecupereUtilisateurId'
import Swal from "sweetalert2";

class ModalProfil extends Component {
    constructor(props){
        super(props);
        this.state = {
            input: {nom: '', prenom: '',mail: '', telephone: '',mobile: '', rue: '', ville: '', pays: '', codePostal: '', role: '', typeSociete: '' },
            errors: {},
            role: '',
            typeSociete: '',
            utilisateurId: RecupereUtilisateurId()
        }
    }
    

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.utilisateur !== nextProps.utilisateur){
            this.setState({
                input: {nom: (nextProps.utilisateur.nom !== null ? nextProps.utilisateur.nom : ""), 
                     prenom: (nextProps.utilisateur.prenom !== null ? nextProps.utilisateur.prenom : ""),
                     mail: (nextProps.utilisateur.mail !== null ? nextProps.utilisateur.mail : ""), 
                     telephone: (nextProps.utilisateur.telephone !== null ? nextProps.utilisateur.telephone : ""),
                     mobile: (nextProps.utilisateur.mobile !== null ? nextProps.utilisateur.mobile : ""),
                     rue: (nextProps.utilisateur.adresse !== null && nextProps.utilisateur.adresse !== undefined && nextProps.utilisateur.adresse.rue !== undefined ? nextProps.utilisateur.adresse.rue : ""),
                     ville: (nextProps.utilisateur.adresse !== undefined && nextProps.utilisateur.adresse !== null && nextProps.utilisateur.adresse.ville !== undefined ? nextProps.utilisateur.adresse.ville : ""),
                     codePostal: (nextProps.utilisateur.adresse !== undefined && nextProps.utilisateur.adresse !== null && nextProps.utilisateur.adresse.codePostal !== undefined ? nextProps.utilisateur.adresse.codePostal : ""),
                     pays: (nextProps.utilisateur.adresse !== undefined && nextProps.utilisateur.adresse !== null && nextProps.utilisateur.adresse.pays !== undefined ? nextProps.utilisateur.adresse.pays : "")},
                role: (nextProps.utilisateur.role !== undefined && nextProps.utilisateur.role !== null && nextProps.utilisateur.role.nom),
                typeSociete: (nextProps.utilisateur.typeSociete !== undefined && nextProps.utilisateur.typeSociete !== null && nextProps.utilisateur.typeSociete.nom),
                errors: {nom: "", prenom: "", adresse: ""}
            })
        }
    }

    handleChange = (e) => {
        let input = this.state.input;
        input[e.target.name] = e.target.value
        this.setState({input})
    }
    validate = () => {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["nom"]) {
            isValid = false;
            errors["nom"] = "Veuillez entrer votre nom";
        }
        if (!input["prenom"]) {
            isValid = false;
            errors["prenom"] = "Veuillez entrer votre prénom";
        }
        if (!input["mail"]) {
            isValid = false;
            errors["mail"] = "Veuillez entrer votre email";
        }
        if(input["rue"] || input["ville"] ||input["codePostal"] || input["pays"]){
            if(!(input["rue"] && input["ville"] && input["codePostal"] && input["pays"])){
                isValid = false;
                errors["adresse"] = "Veuillez entrer votre adresse complète";
            }
        }

        this.setState({
            errors: errors
          });
      
          return isValid;
    }

    editProfil = () => {
        if(this.validate()){
            let adresse = {rue: this.state.input.rue,ville: this.state.input.ville,codePostal: this.state.input.codePostal,pays: this.state.input.pays}
            utilisateurService.ModifierProfilUtilisateur(this.state.utilisateurId,this.state.input.nom,this.state.input.prenom,this.state.input.mail,this.state.input.telephone,this.state.input.mobile,adresse).then(
                () => {
                    Swal.fire({title : "Opération réussie !", text: "La modification a bien été réalisée", icon: "success"});
                }, (error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Une erreur est survenue !",
                        text: error.messages
                    });
                }
            )
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.fermer} size="xl" >
                <ModalHeader toggle={this.props.fermer}>
                    <p className="h3">Mon profil </p>
                </ModalHeader>
                <ModalBody>
                    <div className="text-center mb-4">
                        <p className="h2 mb-3">{this.state.role}</p>
                        {this.state.role === "Client" &&
                        <p><span className={this.state.typeSociete === "APSCHOOL" ? "nomSocieteActive" : "nomSocieteNonActive"}>APSCHOOL</span> <span className={this.state.typeSociete === "APCHANTIER" ? "nomSocieteActive" : "nomSocieteNonActive"}>APCHANTIER</span> <span className={this.state.typeSociete === "APSELF" ? "nomSocieteActive" : "nomSocieteNonActive"}>APSELF</span></p>}
                    </div>
                    <FormGroup>
                        <Row className="mb-3">
                            <Label sm="1">Nom</Label>
                            <Col sm="11">
                                <Input type="text" name="nom" placeholder="Nom" value={this.state.input.nom} onChange={this.handleChange} />
                            </Col>
                            <div className="ml-3 text-danger">{this.state.errors.nom}</div>
                        </Row>
                    
                        <Row className="mb-3">
                            <Label sm="1">Prénom</Label>
                            <Col sm="11">
                                <Input type="text" name="prenom" placeholder="Prénom" value={this.state.input.prenom} onChange={this.handleChange} />
                            </Col>
                            <div className="ml-3 text-danger">{this.state.errors.prenom}</div>
                        </Row>
                    
                        <Row className="mb-3">
                            <Label sm="1">Téléphone</Label>
                            <Col sm="11">
                                <Input type="tel" name="telephone" placeholder="Téléphone" value={this.state.input.telephone} onChange={this.handleChange} />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Label sm="1">Mobile</Label>
                            <Col sm="11">
                                <Input type="tel" name="mobile" placeholder="Mobile" value={this.state.input.mobile} onChange={this.handleChange} />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Label sm="1">Email</Label>
                            <Col sm="11">
                                <Input type="email" name="mail" placeholder="Email" value={this.state.input.mail} onChange={this.handleChange} />
                            </Col>
                            <div className="ml-3 text-danger">{this.state.errors.mail}</div>
                        </Row>
                   
                        <Row className="mb-3">
                            <Label sm="1">Adresse</Label>
                            <Col sm="4">
                                <Input type="text" name="rue" value={this.state.input.rue} placeholder="Rue" onChange={this.handleChange} />
                            </Col>
                            <Col sm="3">
                                <Input type="text" name="ville" value={this.state.input.ville} placeholder="Ville" onChange={this.handleChange} />
                            </Col>
                            <Col sm="2">
                                <Input type="text" name="codePostal" value={this.state.input.codePostal} placeholder="Code postal" onChange={this.handleChange} />
                            </Col>
                            <Col sm="2">
                                <Input type="text" name="pays" value={this.state.input.pays} placeholder="Pays" onChange={this.handleChange} />
                            </Col>
                            <div className="ml-3 text-danger">{this.state.errors.adresse}</div>
                        </Row>
                        <Row>
                            <p className="btn btnEditProfil" onClick={this.editProfil}>Modifier</p>
                        </Row>
                    </FormGroup>
                </ModalBody>
            </Modal>
        );
    }
}

export default ModalProfil;