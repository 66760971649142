import React, { Component } from "react";
import RecupereRoleId from "../../components/Fonctions/GestionToken/RecupereRoleId";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import classnames from "classnames";
import dateFormat from "dateformat";
import Select from "react-select";
import TableauCloture from "../../components/Statistiques/TableauCloture";
import GraphiqueCloture from "../../components/Statistiques/GraphiqueCloture";
import { ticketService } from "../../jwt/_services/ticket.service";
import { categorieService } from "../../jwt/_services/categorie.service";
import { moduleService } from "../../jwt/_services/module.service";
import Chargement from "../../components/Chargement/Chargement";
import Swal from "sweetalert2";

// Export Excel:
import ReactExport from "@sachamarits/react-export-excel4";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const colors = [
  "rgb(28,98,53)",
  "rgb(38, 198, 218)",
  "rgb(38,9,165)",
  "#ef5350",
  "rgb(56,148,120)",
  "rgb(216,168,12)",
  "rgb(99,187,186)",
  "rgb(115,89,37)",
  "rgb(116, 90, 242)",
  "rgb(30, 136, 229)",
  "rgb(187,40,240)",
  "rgb(179,197,70)",
  "rgb(81,15,39)",
  "rgb(222,64,150)",
  "rgb(110,248,139)",
  "rgb(101,24,1)",
  "rgb(235,150,159)",
  "rgb(78,84,10)",
  "rgb(11,200,53)",
  "rgb(60,112,112)",
  "rgb(255,198,141)",
  "rgb(16,129,99)",
  "rgb(125,228,106)",
];
export default class StatistiqueCloture extends Component {
  constructor(props) {
    super(props);
    this.roleId = RecupereRoleId();
    this.state = {
      activeTab: "",
      selected: "graphique",
      tickets: null,
      tabFilter: null,
      isLoading: true,
      moduleIsLoading: true,
      select: {
        sousSociete: "",
        typeSociete: "",
        clientId: "",
        dateDebut: null,
        dateFin: null,
        technicienId: "",
        priorite: "",
        categorieId: "",
        sousCategorieId: "",
        moduleId: "",
      },
      selectSousSociete: null,
      selectTypeSociete: null,
      selectTicketsClient: null,
      selectTicketsTechnicien: null,
      selectTicketsSousCategorie: [
        { nom: "Pédagogique", id: 1 },
        { nom: "Administratif", id: 2 },
        { nom: "Technique", id: 3 },
      ],
      modal: false,
      deleteModal: false,
      listeCategories: null,
      listeModules: null,
      categorieSelect: { nom: "", id: 0 },
      nouvelleCategorie: "",
    };
    this.selectTicketsPriorite = [
      { nom: "Faible" },
      { nom: "Moyenne" },
      { nom: "Haute" },
    ];
    this.optionsPieSociete = {
      chart: {
        id: "pie-chartSociete",
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70px",
          },
        },
      },
      labels: ["APSCHOOL", "APCHANTIER", "APSELF"],
      legend: {
        show: true,
        position: "bottom",
        width: "50px",
        fontFamily: "Open sans",
      },
      colors: ["rgb(30, 136, 229)", "rgb(38, 198, 218)", "#ef5350"],
      tooltip: {
        style: {
          fontFamily: "Open sans",
        },
        fillSeriesColor: true,
      },
    };
    this.seriesPieSociete = [];
    this.optionsPieSousCategorie = {
      chart: {
        id: "pie-chartSousCategorie",
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70px",
          },
        },
      },
      labels: ["Pédagogique", "Administratif", "Technique"],
      legend: {
        show: true,
        position: "bottom",
        width: "50px",
        fontFamily: "Open sans",
      },
      colors: ["rgb(116, 90, 242)", "#ef5350", "rgb(38, 198, 218)"],
      tooltip: {
        style: {
          fontFamily: "Open sans",
        },
        fillSeriesColor: true,
      },
    };
    this.seriesPieSousCategorie = [];
    this.optionsdoughnut = {
      chart: {
        id: "donut-chart",
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "60px",
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
        width: "50px",
        fontFamily: "Open Sans",
      },
      colors: [],
      tooltip: {
        style: {
          fontFamily: "Open sans",
        },
        fillSeriesColor: false,
      },
    };
    this.seriesdoughnut = [];
    this.optionsModule = {
      chart: {
        id: "donut-chart",
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "60px",
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
        width: "50px",
        fontFamily: "Open Sans",
      },
      colors: [],
      tooltip: {
        style: {
          fontFamily: "Open sans",
        },
        fillSeriesColor: false,
      },
    };
    this.seriesModule = [];
    this.colourStyles = {
      option: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "gray" : null,
          color: "#333333",
        };
      },
    };
    this.selectRef = {
      sousSociete: null,
      typeSociete: null,
      clientId: null,
      technicienId: null,
      priorite: null,
      categorieId: null,
      sousCategorieId: null,
      moduleId: null,
    };
  }
  componentDidMount() {
    this.RecupereTicketsCloture();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    } else {
      this.setState({ activeTab: "" });
    }
  }

  openModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  RecupereTicketsCloture = () => {
    this.setState(
      {
        isLoading: true,
        moduleIsLoading: true,
      },
      () => {
        ticketService
          .RecupereStatistiqueCloture(
            this.state.select["sousSociete"],
            this.state.select["typeSociete"],
            this.state.select["clientId"],
            this.state.select["dateDebut"],
            this.state.select["dateFin"],
            this.state.select["technicienId"],
            this.state.select["priorite"],
            this.state.select["categorieId"],
            this.state.select["sousCategorieId"],
            this.state.select["moduleId"]
          )
          .then((ticketsCloture) => {
            this.setState(
              {
                tickets: ticketsCloture.resource,
                tabFilter: ticketsCloture.resource,
              },
              () => {
                this.setState(
                  {
                    selectSousSociete: this.state.tickets.map((ticket) => ({
                      nom: ticket.sousSociete,
                      adresse: ticket.adresseSousSociete,
                    })),
                    selectTypeSociete: this.state.tickets.map((ticket) => ({
                      nom: ticket.typeSociete,
                    })),
                    selectTicketsClient: this.state.tickets.map((client) => ({
                      prenom: client.prenomClient,
                      nom: client.nomClient,
                      id: client.clientId,
                    })),
                    selectTicketsTechnicien: this.state.tickets.map((tech) => ({
                      prenom: tech.prenomTechnicien,
                      id: tech.technicienId,
                    })),
                  },
                  () => {
                    this.setState(
                      {
                        selectSousSociete: [
                          ...new Map(
                            this.state.selectSousSociete.map((item) => [
                              item["nom"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTypeSociete: [
                          ...new Map(
                            this.state.selectTypeSociete.map((item) => [
                              item["nom"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTicketsClient: [
                          ...new Map(
                            this.state.selectTicketsClient.map((item) => [
                              item["id"],
                              item,
                            ])
                          ).values(),
                        ],
                        selectTicketsTechnicien: [
                          ...new Map(
                            this.state.selectTicketsTechnicien.map((item) => [
                              item["id"],
                              item,
                            ])
                          ).values(),
                        ],
                      },
                      () => {
                        let dataListe = [];
                        this.state.tabFilter.map((ticket) =>
                          dataListe.push({
                            id: ticket.id !== null ? ticket.id : "",
                            societe:
                              ticket.sousSociete !== null
                                ? ticket.sousSociete
                                : "",
                            type:
                              ticket.typeSociete !== null
                                ? ticket.typeSociete
                                : "",
                            client:
                              ticket.prenomClient !== null
                                ? ticket.prenomClient
                                : "",
                            sujet: ticket.sujet !== null ? ticket.sujet : "",
                            technicien:
                              ticket.prenomTechnicien !== null
                                ? ticket.prenomTechnicien
                                : "",
                            dateCreation:
                              ticket.dateCreation !== null
                                ? dateFormat(
                                    ticket.dateCreation,
                                    "dd/mm/yyyy HH:MM"
                                  )
                                : "",
                            dateCloture:
                              ticket.dateCloture !== null
                                ? dateFormat(
                                    ticket.dateCloture,
                                    "dd/mm/yyyy HH:MM"
                                  )
                                : "",
                            priorite:
                              ticket.priorite !== null ? ticket.priorite : "",
                            categorie:
                              ticket.categorie !== null ? ticket.categorie : "",
                            sousCategorie:
                              ticket.sousCategorie !== null
                                ? ticket.sousCategorie
                                : "",
                            module: ticket.module !== null ? ticket.module : "",
                          })
                        );
                        this.setState({
                          dataListe: dataListe,
                        });
                        /* Création du graphique des types de societe */
                        let tabValueSociete = [];
                        tabValueSociete[0] = this.state.tabFilter.filter(
                          (ticket) => ticket.typeSociete === "APSCHOOL"
                        ).length;
                        tabValueSociete[1] = this.state.tabFilter.filter(
                          (ticket) =>
                            ticket.typeSociete ===
                            ("BPC" || "Wust" || "Engie" || "Blaton")
                        ).length;
                        tabValueSociete[2] = this.state.tabFilter.filter(
                          (ticket) => ticket.typeSociete === "APSELF"
                        ).length;

                        let tabValueModule = [];
                        let tabColorModule = [];
                        let tabLabelModule = [];
                        moduleService.RecupereModules().then((modules) => {
                          this.setState(
                            {
                              listeModules: modules.resource,
                            },
                            () => {
                              let j = 0;
                              for (
                                let i = 0;
                                i < this.state.listeModules.length;
                                i++
                              ) {
                                if (
                                  this.state.tabFilter.filter(
                                    (ticket) =>
                                      ticket.module ===
                                      this.state.listeModules[i].nom
                                  ).length > 0
                                ) {
                                  tabLabelModule[j] =
                                    this.state.listeModules[i].nom;
                                  tabValueModule[j] =
                                    this.state.tabFilter.filter(
                                      (ticket) =>
                                        ticket.module ===
                                        this.state.listeModules[i].nom
                                    ).length;
                                  tabColorModule[j] = `rgb(${Math.round(
                                    Math.random() * (200 - 50) + 50
                                  )}, ${Math.round(
                                    Math.random() * (200 - 50) + 50
                                  )}, ${Math.round(
                                    Math.random() * (200 - 50) + 50
                                  )})`;
                                  j++;
                                }
                              }
                              this.optionsModule = {
                                ...this.optionsModule,
                                colors: tabColorModule,
                                labels: tabLabelModule,
                              };
                              this.seriesModule = tabValueModule;
                              this.setState({
                                moduleIsLoading: false,
                              });
                            }
                          );
                        });
                        /* Création du graphique des catégories de clôture */
                        let tabValueCategorie = [];
                        let tabColorCategorie = [];
                        let tabLabelCategorie = [];
                        categorieService
                          .RecupereCategories()
                          .then((categories) => {
                            this.setState(
                              {
                                listeCategories: categories.resource,
                              },
                              () => {
                                for (
                                  let i = 0;
                                  i < this.state.listeCategories.length;
                                  i++
                                ) {
                                  tabValueCategorie[i] =
                                    this.state.tabFilter.filter(
                                      (ticket) =>
                                        ticket.categorie ===
                                        this.state.listeCategories[i].nom
                                    ).length;
                                  tabColorCategorie[i] = colors[i];
                                  tabLabelCategorie[i] =
                                    this.state.listeCategories[i].nom;
                                }
                                /* Création du graphique des sous-catégories de clôture */
                                let tabValueSousCategorie = [];
                                tabValueSousCategorie[0] =
                                  this.state.tabFilter.filter(
                                    (ticket) =>
                                      ticket.sousCategorie === "Pédagogique"
                                  ).length;
                                tabValueSousCategorie[1] =
                                  this.state.tabFilter.filter(
                                    (ticket) =>
                                      ticket.sousCategorie === "Administrative"
                                  ).length;
                                tabValueSousCategorie[2] =
                                  this.state.tabFilter.filter(
                                    (ticket) =>
                                      ticket.sousCategorie === "Technique"
                                  ).length;

                                this.seriesPieSociete = tabValueSociete;
                                this.seriesPieSousCategorie =
                                  tabValueSousCategorie;
                                this.optionsdoughnut = {
                                  ...this.optionsdoughnut,
                                  colors: tabColorCategorie,
                                  labels: tabLabelCategorie,
                                };
                                this.seriesdoughnut = tabValueCategorie;
                                let dataGraphique = [];
                                let j = 0;

                                if (
                                  tabValueSociete.length >=
                                    tabLabelCategorie.length &&
                                  tabValueSociete.length >=
                                    tabValueSousCategorie.length
                                ) {
                                  j = tabValueSociete.length;
                                } else if (
                                  tabLabelCategorie.length >=
                                    tabValueSociete.length &&
                                  tabLabelCategorie.length >=
                                    tabValueSousCategorie.length
                                ) {
                                  j = tabLabelCategorie.length;
                                } else if (
                                  tabValueSousCategorie.length >=
                                    tabValueSociete.length &&
                                  tabValueSousCategorie.length >=
                                    tabLabelCategorie.length
                                ) {
                                  j = tabValueSousCategorie.length;
                                }
                                if (tabValueModule.length >= j) {
                                  j = tabValueModule.length;
                                }

                                for (let index = 0; index < j; index++) {
                                  let data = [];

                                  data[0] = {
                                    type:
                                      this.optionsPieSociete.labels[index] !==
                                      null
                                        ? this.optionsPieSociete.labels[index]
                                        : "",
                                    ticketClotureType:
                                      tabValueSociete[index] !== null
                                        ? tabValueSociete[index]
                                        : "",
                                    categorie:
                                      tabLabelCategorie[index] !== null
                                        ? tabLabelCategorie[index]
                                        : "",
                                    ticketClotureCategorie:
                                      tabValueCategorie[index] !== null
                                        ? tabValueCategorie[index]
                                        : "",
                                    sousCategorie:
                                      this.optionsPieSousCategorie.labels[
                                        index
                                      ] !== null
                                        ? this.optionsPieSousCategorie.labels[
                                            index
                                          ]
                                        : "",
                                    ticketClotureSousCategorie:
                                      tabValueSousCategorie[index] !== null
                                        ? tabValueSousCategorie[index]
                                        : "",
                                    module:
                                      tabLabelModule[index] !== null
                                        ? tabLabelModule[index]
                                        : "",
                                    ticketClotureModule:
                                      tabValueModule[index] !== null
                                        ? tabValueModule[index]
                                        : "",
                                  };

                                  dataGraphique.push(data[0]);
                                }
                                this.setState(
                                  {
                                    dataGraphique: dataGraphique,
                                  },
                                  () => {
                                    this.setState({
                                      isLoading: false,
                                    });
                                  }
                                );
                              }
                            );
                          });
                      }
                    );
                  }
                );
              }
            );
          });
      }
    );
  };
  RecupereTicketsClotureFiltre = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        ticketService
          .RecupereStatistiqueCloture(
            this.state.select["sousSociete"],
            this.state.select["typeSociete"],
            this.state.select["clientId"],
            this.state.select["dateDebut"],
            this.state.select["dateFin"],
            this.state.select["technicienId"],
            this.state.select["priorite"],
            this.state.select["categorieId"],
            this.state.select["sousCategorieId"],
            this.state.select["moduleId"]
          )
          .then((ticketsCloture) => {
            this.setState(
              {
                tickets: ticketsCloture.resource,
                tabFilter: ticketsCloture.resource,
              },
              () => {
                let dataListe = [];
                this.state.tabFilter.map((ticket) =>
                  dataListe.push({
                    id: ticket.id !== null ? ticket.id : "",
                    societe:
                      ticket.sousSociete !== null ? ticket.sousSociete : "",
                    type: ticket.typeSociete !== null ? ticket.typeSociete : "",
                    client:
                      ticket.prenomClient !== null ? ticket.prenomClient : "",
                    sujet: ticket.sujet !== null ? ticket.sujet : "",
                    technicien:
                      ticket.prenomTechnicien !== null
                        ? ticket.prenomTechnicien
                        : "",
                    dateCreation:
                      ticket.dateCreation !== null
                        ? dateFormat(ticket.dateCreation, "dd/mm/yyyy HH:MM")
                        : "",
                    dateCloture:
                      ticket.dateCloture !== null
                        ? dateFormat(ticket.dateCloture, "dd/mm/yyyy HH:MM")
                        : "",
                    priorite: ticket.priorite !== null ? ticket.priorite : "",
                    categorie:
                      ticket.categorie !== null ? ticket.categorie : "",
                    sousCategorie:
                      ticket.sousCategorie !== null ? ticket.sousCategorie : "",
                  })
                );
                this.setState({
                  dataListe: dataListe,
                });
                /* Création du graphique des types de societe */
                let tabValueSociete = [];
                tabValueSociete[0] = this.state.tabFilter.filter(
                  (ticket) => ticket.typeSociete === "APSCHOOL"
                ).length;
                tabValueSociete[1] = this.state.tabFilter.filter(
                  (ticket) =>
                    ticket.typeSociete ===
                    ("BPC" || "Wust" || "Engie" || "Blaton")
                ).length;
                tabValueSociete[2] = this.state.tabFilter.filter(
                  (ticket) => ticket.typeSociete === "APSELF"
                ).length;

                let tabValueModule = [];
                let tabColorModule = [];
                let tabLabelModule = [];
                moduleService.RecupereModules().then((modules) => {
                  this.setState(
                    {
                      listeModules: modules.resource,
                    },
                    () => {
                      let j = 0;
                      for (let i = 0; i < this.state.listeModules.length; i++) {
                        if (
                          this.state.tabFilter.filter(
                            (ticket) =>
                              ticket.module === this.state.listeModules[i].nom
                          ).length > 0
                        ) {
                          tabLabelModule[j] = this.state.listeModules[i].nom;
                          tabValueModule[j] = this.state.tabFilter.filter(
                            (ticket) =>
                              ticket.module === this.state.listeModules[i].nom
                          ).length;
                          tabColorModule[j] = `rgb(${Math.round(
                            Math.random() * (200 - 50) + 50
                          )}, ${Math.round(
                            Math.random() * (200 - 50) + 50
                          )}, ${Math.round(Math.random() * (200 - 50) + 50)})`;
                          j++;
                        }
                      }
                      this.optionsModule = {
                        ...this.optionsModule,
                        colors: tabColorModule,
                        labels: tabLabelModule,
                      };
                      this.seriesModule = tabValueModule;
                      this.setState({
                        moduleIsLoading: false,
                      });
                    }
                  );
                });

                /* Création du graphique des catégories de clôture */
                let tabValueCategorie = [];
                let tabColorCategorie = [];
                let tabLabelCategorie = [];
                categorieService.RecupereCategories().then((categories) => {
                  this.setState(
                    {
                      listeCategories: categories.resource,
                    },
                    () => {
                      for (
                        let i = 0;
                        i < this.state.listeCategories.length;
                        i++
                      ) {
                        tabValueCategorie[i] = this.state.tabFilter.filter(
                          (ticket) =>
                            ticket.categorie ===
                            this.state.listeCategories[i].nom
                        ).length;
                        tabColorCategorie[i] = colors[i];
                        tabLabelCategorie[i] =
                          this.state.listeCategories[i].nom;
                      }
                      /* Création du graphique des sous-catégories de clôture */
                      let tabValueSousCategorie = [];
                      tabValueSousCategorie[0] = this.state.tabFilter.filter(
                        (ticket) => ticket.sousCategorie === "Pédagogique"
                      ).length;
                      tabValueSousCategorie[1] = this.state.tabFilter.filter(
                        (ticket) => ticket.sousCategorie === "Administrative"
                      ).length;
                      tabValueSousCategorie[2] = this.state.tabFilter.filter(
                        (ticket) => ticket.sousCategorie === "Technique"
                      ).length;

                      this.seriesPieSociete = tabValueSociete;
                      this.seriesPieSousCategorie = tabValueSousCategorie;
                      this.optionsdoughnut = {
                        ...this.optionsdoughnut,
                        colors: tabColorCategorie,
                        labels: tabLabelCategorie,
                      };
                      this.seriesdoughnut = tabValueCategorie;
                      let dataGraphique = [];
                      let j = 0;

                      if (
                        tabValueSociete.length >= tabLabelCategorie.length &&
                        tabValueSociete.length >= tabValueSousCategorie.length
                      ) {
                        j = tabValueSociete.length;
                      } else if (
                        tabLabelCategorie.length >= tabValueSociete.length &&
                        tabLabelCategorie.length >= tabValueSousCategorie.length
                      ) {
                        j = tabLabelCategorie.length;
                      } else if (
                        tabValueSousCategorie.length >=
                          tabValueSociete.length &&
                        tabValueSousCategorie.length >= tabLabelCategorie.length
                      ) {
                        j = tabValueSousCategorie.length;
                      }
                      if (tabValueModule.length >= j) {
                        j = tabValueModule.length;
                      }

                      for (let index = 0; index < j; index++) {
                        let data = [];
                        data[0] = {
                          type:
                            this.optionsPieSociete.labels[index] !== null
                              ? this.optionsPieSociete.labels[index]
                              : "",
                          ticketClotureType:
                            tabValueSociete[index] !== null
                              ? tabValueSociete[index]
                              : "",
                          categorie:
                            tabLabelCategorie[index] !== null
                              ? tabLabelCategorie[index]
                              : "",
                          ticketClotureCategorie:
                            tabValueCategorie[index] !== null
                              ? tabValueCategorie[index]
                              : "",
                          sousCategorie:
                            this.optionsPieSousCategorie.labels[index] !== null
                              ? this.optionsPieSousCategorie.labels[index]
                              : "",
                          ticketClotureSousCategorie:
                            tabValueSousCategorie[index] !== null
                              ? tabValueSousCategorie[index]
                              : "",
                          module:
                            tabLabelModule[index] !== null
                              ? tabLabelModule[index]
                              : "",
                          ticketClotureModule:
                            tabValueModule[index] !== null
                              ? tabValueModule[index]
                              : "",
                        };

                        dataGraphique.push(data[0]);
                      }
                      this.setState({
                        dataGraphique: dataGraphique,
                        isLoading: false,
                      });
                    }
                  );
                });
              }
            );
          });
      }
    );
  };
  handleSousSociete = (val) => {
    let select = this.state.select;
    select["sousSociete"] = val !== null ? val.nom : "";
    this.setState(
      {
        select: select,
      },
      () => {
        this.RecupereTicketsClotureFiltre();
      }
    );
  };

  handleTypeSociete = (val) => {
    let select = this.state.select;
    select["typeSociete"] = val !== null ? val.nom : "";
    this.setState(
      {
        select: select,
      },
      () => {
        this.RecupereTicketsClotureFiltre();
      }
    );
  };

  handleClientChange = (val) => {
    let select = this.state.select;
    select["clientId"] = val !== null ? val.id : "";
    this.setState(
      {
        select: select,
      },
      () => {
        this.RecupereTicketsClotureFiltre();
      }
    );
  };

  handleTechnicienChange = (val) => {
    let select = this.state.select;
    select["technicienId"] = val !== null ? val.id : "";
    this.setState(
      {
        select: select,
      },
      () => {
        this.RecupereTicketsClotureFiltre();
      }
    );
  };

  handleDateMinChange = (e) => {
    let select = this.state.select;
    select["dateDebut"] = e.target.value;
    this.setState(
      {
        select: select,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.RecupereTicketsClotureFiltre();
        }
      }
    );
  };

  handleDateMaxChange = (e) => {
    let select = this.state.select;
    select["dateFin"] = e.target.value;
    this.setState(
      {
        select: select,
      },
      () => {
        if (this.state.dateDebut !== "" && this.state.dateFin !== "") {
          this.RecupereTicketsClotureFiltre();
        }
      }
    );
  };

  handlePrioriteChange = (val) => {
    let select = this.state.select;
    select["priorite"] = val !== null ? val.nom : "";
    this.setState(
      {
        select: select,
      },
      () => {
        this.RecupereTicketsClotureFiltre();
      }
    );
  };

  handleCategorieChange = (val) => {
    let select = this.state.select;
    select["categorieId"] = val !== null ? val.id : "";
    this.setState(
      {
        select: select,
      },
      () => {
        this.RecupereTicketsClotureFiltre();
      }
    );
  };

  handleModuleChange = (val) => {
    let select = this.state.select;
    select["moduleId"] = val !== null ? val.id : "";
    this.setState(
      {
        select: select,
      },
      () => {
        this.RecupereTicketsClotureFiltre();
      }
    );
  };
  handleSousCategorieChange = (val) => {
    let select = this.state.select;
    select["sousCategorieId"] = val !== null ? val.id : "";
    this.setState(
      {
        select: select,
      },
      () => {
        this.RecupereTicketsClotureFiltre();
      }
    );
  };

  customFilterClient = (option, searchText) => {
    if (
      (option.data.prenom !== null &&
        option.data.prenom.toLowerCase().includes(searchText.toLowerCase())) ||
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  customFilterSousSociete = (option, searchText) => {
    if (
      (option.data.adresse !== null &&
        option.data.adresse.ville !== null &&
        option.data.adresse.ville
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetValueSelect = () => {
    this.selectRef["typeSociete"].select.clearValue();
    this.selectRef["sousSociete"].select.clearValue();
    this.selectRef["clientId"].select.clearValue();
    this.selectRef["technicienId"].select.clearValue();
    this.selectRef["priorite"].select.clearValue();
    this.selectRef["categorieId"].select.clearValue();
    this.selectRef["sousCategorieId"].select.clearValue();
    this.selectRef["moduleId"].select.clearValue();
    document.getElementsByName("datemin")[0].value = "";
    document.getElementsByName("datemax")[0].value = "";
    this.setState(
      {
        isLoading: true,
        moduleIsLoading: true,
        select: {
          sousSociete: "",
          typeSociete: "",
          clientId: "",
          dateDebut: null,
          dateFin: null,
          technicienId: "",
          priorite: "",
          categorieId: "",
          sousCategorieId: "",
          moduleId: "",
        },
      },
      () => {
        this.RecupereTicketsCloture();
      }
    );
  };

  supprimerCategorie = () => {
    categorieService.SupprimerCategorie(this.state.categorieSelect["id"]).then(
      () => {
        this.setState(
          {
            deleteModal: !this.state.deleteModal,
          },
          () => {
            this.RecupereTicketsCloture();
          }
        );
      },
      (error) => {
        this.setState(
          {
            deleteModal: !this.state.deleteModal,
          },
          () => {
            Swal.fire({
              icon: "error",
              title: "Supression impossible !",
              text: error.messages,
            });
          }
        );
      }
    );
  };
  handleChangeNouvelleCategorie = (e) => {
    this.setState({
      nouvelleCategorie: e.target.value,
    });
  };

  enregistrerCategorie = () => {
    categorieService.EnregistrerCategorie(this.state.nouvelleCategorie).then(
      () => {
        this.setState(
          {
            nouvelleCategorie: "",
          },
          () => {
            Swal.fire({
              title: "Opération réussie !",
              text: "La catégorie a été ajoutée !",
              icon: "success",
            });
            this.RecupereTicketsCloture();
          }
        );
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Une erreur est survenue !",
          text: error.messages,
        });
      }
    );
  };
  closeDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };
  openDeleteModal = (categorieId, categorie) => {
    let categorieSelect = this.state.categorieSelect;
    categorieSelect["nom"] = categorie;
    categorieSelect["id"] = categorieId;
    this.setState({
      deleteModal: !this.state.deleteModal,
      categorieSelect: categorieSelect,
    });
  };

  render() {
    const { activeTab, isLoading, moduleIsLoading } = this.state;
    return (
      <div>
        <Card>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-none d-md-block">
                  <i className="mdi mdi-filter-outline" /> Filtres
                </span>
                <span className="d-block d-md-none">
                  <i className="mdi mdi-filter-outline" />
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardBody className="pb-2">
                    <Row>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par societé</Label>
                          {this.state.selectSousSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectSousSociete}
                              onChange={this.handleSousSociete}
                              filterOption={this.customFilterSousSociete}
                              noOptionsMessage={() => "Aucune société"}
                              placeholder="Votre société"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["sousSociete"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par type</Label>
                          {this.state.selectTypeSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTypeSociete}
                              onChange={this.handleTypeSociete}
                              noOptionsMessage={() => "Aucun type"}
                              placeholder="Votre type"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["typeSociete"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par client</Label>
                          {this.state.selectTicketsClient !== null && (
                            <Select
                              getOptionLabel={(option) =>
                                `${option.prenom} ${option.nom}`
                              }
                              getOptionValue={(option) =>
                                `${option.prenom} ${option.nom}`
                              }
                              styles={this.colourStyles}
                              options={this.state.selectTicketsClient}
                              onChange={this.handleClientChange}
                              filterOption={this.customFilterClient}
                              placeholder="Votre client"
                              noOptionsMessage={() => "Aucun client"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["clientId"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par mois : Date début</Label>
                          <Input
                            type="date"
                            name="datemin"
                            id="datemin "
                            placeholder="date placeholder"
                            onChange={this.handleDateMinChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label> Date fin</Label>
                          <InputGroup>
                            <Input
                              type="date"
                              name="datemax"
                              id="datemax "
                              placeholder="date placeholder"
                              onChange={this.handleDateMaxChange}
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par technicien</Label>
                          {this.state.selectTicketsTechnicien !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.prenom}`}
                              getOptionValue={(option) => `${option.prenom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsTechnicien}
                              onChange={this.handleTechnicienChange}
                              placeholder={"Votre technicien"}
                              noOptionsMessage={() => "Aucun technicien"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["technicienId"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par priorité</Label>
                          {this.selectTicketsPriorite !== "" && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.selectTicketsPriorite}
                              onChange={this.handlePrioriteChange}
                              noOptionsMessage={() => "Aucune priorité"}
                              placeholder="Votre priorité"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["priorite"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par catégorie</Label>
                          {this.state.listeCategories !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.listeCategories}
                              onChange={this.handleCategorieChange}
                              noOptionsMessage={() => "Aucune catégorie"}
                              placeholder="Votre catégorie"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["categorieId"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par sous-catégorie</Label>
                          {this.state.selectTicketsSousCategorie !== "" && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsSousCategorie}
                              onChange={this.handleSousCategorieChange}
                              noOptionsMessage={() => "Aucune sous-catégorie"}
                              placeholder="Votre sous-catégorie"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["sousCategorieId"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par module</Label>
                          {this.state.listeModules !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.listeModules}
                              onChange={this.handleModuleChange}
                              noOptionsMessage={() => "Aucun module"}
                              placeholder="Votre module"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["moduleId"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
                {(this.state.select["typeSociete"] !== "" ||
                  this.state.select["sousSociete"] !== "" ||
                  this.state.select["clientId"] !== "" ||
                  this.state.select["technicienId"] !== "" ||
                  this.state.select["priorite"] !== "" ||
                  this.state.select["categorieId"] !== "" ||
                  this.state.select["sousCategorieId"] !== "" ||
                  this.state.select["moduleId"] !== "" ||
                  (this.state.select["dateDebut"] !== null &&
                    this.state.select["dateFin"] !== null)) && (
                  <Col lg={12}>
                    <InputGroupAddon
                      addonType="append"
                      className="float-right mb-2 mr-2"
                    >
                      <button
                        className="btn btn-danger"
                        onClick={() => this.resetValueSelect()}
                      >
                        Effacer les filtres
                      </button>
                    </InputGroupAddon>
                  </Col>
                )}
              </Row>
            </TabPane>
          </TabContent>
        </Card>
        <div className="bg-white pt-2">
          <ButtonGroup className="mb-1 ml-2">
            <button
              className="btn selectButton"
              onClick={() => this.setState({ selected: "graphique" })}
              active={(this.state.selected === "graphique").toString()}
            >
              Graphique
            </button>
            <button
              className="btn selectButton"
              onClick={() => this.setState({ selected: "liste" })}
              active={(this.state.selected === "graphique").toString()}
            >
              Liste
            </button>
            {this.roleId === "1" && (
              <button
                className="btn btnFichierModal ml-3 text-white rounded"
                onClick={() => this.openModal()}
              >
                Gérer les catégories
              </button>
            )}
          </ButtonGroup>
          <div className="float-right mr-3">
            {this.state.selected === "graphique" ? (
              <ExcelFile
                element={
                  <button className="btn btn-success">
                    Exporter les données du graphique
                  </button>
                }
                filename={"Statistique des clôtures"}
              >
                <ExcelSheet
                  data={this.state.dataGraphique}
                  name="Statistique des clôtures"
                >
                  <ExcelColumn label="Type de société" value="type" />
                  <ExcelColumn
                    label="Total tickets clôturés par société"
                    value="ticketClotureType"
                  />
                  <ExcelColumn label="Type de catégorie" value="categorie" />
                  <ExcelColumn
                    label="Total tickets clôturés par catégorie"
                    value="ticketClotureCategorie"
                  />
                  <ExcelColumn
                    label="Type de sous-catégorie"
                    value="sousCategorie"
                  />
                  <ExcelColumn
                    label="Total tickets clôturés par sous-catégorie"
                    value="ticketClotureSousCategorie"
                  />
                  <ExcelColumn label="Type de module" value="module" />
                  <ExcelColumn
                    label="Total tickets clôturés par module"
                    value="ticketClotureModule"
                  />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              <ExcelFile
                element={
                  <button className="btn btn-success">
                    Exporter les données de la liste
                  </button>
                }
                filename={"Statistique des clôtures"}
              >
                <ExcelSheet
                  data={this.state.dataListe}
                  name="Statistique des clôtures"
                >
                  <ExcelColumn label="Id" value="id" />
                  <ExcelColumn label="Nom de la société" value="societe" />
                  <ExcelColumn label="Type de société" value="type" />
                  <ExcelColumn label="Prénom du client" value="client" />
                  <ExcelColumn label="Sujet" value="sujet" />
                  <ExcelColumn label="Technicien" value="technicien" />
                  <ExcelColumn label="Date de création" value="dateCreation" />
                  <ExcelColumn label="Date de clôture" value="dateCloture" />
                  <ExcelColumn label="Priorité" value="priorite" />
                  <ExcelColumn label="Catégorie" value="categorie" />
                  <ExcelColumn label="Sous-catégorie" value="sousCategorie" />
                  <ExcelColumn label="Module" value="module" />
                </ExcelSheet>
              </ExcelFile>
            )}
          </div>

          {!isLoading && !moduleIsLoading && (
            <p className="h2 ml-2 font-weight-bold">
              Total : {this.state.tickets.length}
            </p>
          )}
          {this.state.selected === "graphique" ? (
            isLoading || moduleIsLoading ? (
              <Chargement />
            ) : (
              <>
                <GraphiqueCloture
                  nbTickets={this.state.tickets.length}
                  optionsPieSociete={this.optionsPieSociete}
                  seriesPieSociete={this.seriesPieSociete}
                  optionsdoughnut={this.optionsdoughnut}
                  seriesdoughnut={this.seriesdoughnut}
                  optionsPieSousCategorie={this.optionsPieSousCategorie}
                  seriesPieSousCategorie={this.seriesPieSousCategorie}
                  optionsModule={this.optionsModule}
                  seriesModule={this.seriesModule}
                />
              </>
            )
          ) : (
            <TableauCloture
              tickets={this.state.tabFilter}
              isLoading={isLoading}
            />
          )}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.openModal}
          size="lg"
          className="Modal"
        >
          <ModalHeader toggle={this.openModal}>
            <p className="h3 font-weight-bold">
              Liste des catégories de clôture
            </p>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col lg={4}>
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Nouvelle catégorie"
                      value={this.state.nouvelleCategorie}
                      onChange={this.handleChangeNouvelleCategorie}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        color="success"
                        onClick={this.enregistrerCategorie}
                      >
                        <i class="fas fa-plus-circle"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>
            {this.state.listeCategories !== null
              ? this.state.listeCategories.map((categorie, index) => (
                  <Row key={index}>
                    <Col lg={11} md={10} sm={10}>
                      <p className="text-center h4">{categorie.nom}</p>
                    </Col>
                    <Col lg={1} md={2} sm={2}>
                      <p className="text-right mr-4">
                        <i
                          className="fas fa-trash-alt cursor fa-lg"
                          onClick={() =>
                            this.openDeleteModal(categorie.id, categorie.nom)
                          }
                        ></i>
                      </p>
                    </Col>
                  </Row>
                ))
              : ""}
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.openDeleteModal}
          size="lg"
          className="Modal"
        >
          <ModalHeader toggle={this.openDeleteModal}>
            Êtes-vous sûr ?
          </ModalHeader>
          <ModalBody>
            <div>
              <span className="textDeco">
                Voulez-vous supprimer cette catégorie :{" "}
                {this.state.categorieSelect["nom"]} ?
              </span>
            </div>
            <div className="text-center">
              <span
                onClick={this.supprimerCategorie}
                className="btn btnModalYes btnModal text-white"
              >
                Oui
              </span>
              <span
                onClick={this.closeDeleteModal}
                className="btn btnModalNo btnModal text-white"
              >
                Non
              </span>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
