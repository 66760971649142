import React, { Component } from 'react'
import RecupereUtilisateurId from '../Fonctions/GestionToken/RecupereUtilisateurId'
import {ticketService} from '../../jwt/_services/ticket.service'
import {Card, 
        CardBody, 
        CardTitle,
        Table,
        Nav,
        NavItem,
        NavLink,
        TabContent,
        TabPane,
        Row,
        Col,
        Label,
        FormGroup,
        Input,
        InputGroup,
        InputGroupAddon} from 'reactstrap';
import dateFormat from 'dateformat'
import { Redirect} from 'react-router-dom';
import Select from 'react-select'
import classnames from "classnames";
import InitialiseTabStatut from '../Fonctions/InitialiseTabStatut'
import RecupereRoleId from '../../components/Fonctions/GestionToken/RecupereRoleId'
import Skeleton from 'react-loading-skeleton';
import retourneTechnicien from '../Fonctions/Utils/retourneTechnicien';


export default class TableauTempsTickets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "",
            tickets: [],
            ticketsFilter: [],
            isLoading: true,
            redirect: false,
            currentSort: "down",
            ticketIdClicked: 0,
            selectTicketsNomSociete: [],
            selectTicketsPrenomClient: [],
            selectTicketsPrenomTechnicien: [],
            selectTicketsTypeSociete: [],
            tabTickets: [],
            select: {}
        }
        this.roleId = RecupereRoleId()
        this.id = RecupereUtilisateurId()
        this.selectRef = {societe: null,type: null,priorite: null,client: null,technicien: null,statut: null,facturation: null};
        this.selectTicketsPriorite = [{nom: "Faible"},{nom: "Moyenne"},{nom: "Haute"}]
        this.selectTicketsFacturation = [{type: "Non-facturé"},{type: "A facturer"},{type: "Facturé"}]
        this.selectTicketsStatut = InitialiseTabStatut()
        this.colourStyles = {option: (styles, { isFocused }) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? "gray" : null,
                    color: "#333333"
                };
            }
        }; 
    }

    componentDidMount(){
        this.getTicketsTemps()
    }

    componentDidUpdate(prevProps){
        if(this.props.utilisateurId !== prevProps.utilisateurId){
            this.getTicketsTemps()
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab});
        }
        else {
            this.setState({activeTab: ""});
        }
    }
    getTicketsTemps = () => {
        /* On initialise les variables pour faire la recherche avec les filtres de bases et on remplit les listes de sélection */

        this.setState({
            select: {"societe": "Toutes","type": "Tous","priorite": "Toutes","client": "Tous","facturation": "Toutes","statut": "Tous","technicien" : (this.roleId === "3"  || this.roleId === "1" ) ? this.props.utilisateurId : RecupereUtilisateurId() + "","dateDebut": null, "dateFin": null},
            isLoading: true
        },()=>{
            ticketService.RecupereTicketsTempsParTechnicien(this.state.select["technicien"],this.state.select["societe"],this.state.select["type"],this.state.select["priorite"],this.state.select["client"],this.state.select["facturation"],this.state.select["statut"],this.state.select["dateDebut"],this.state.select["dateFin"]).then(
                (ticketsTemps) => {
                    this.setState({
                        tickets: ticketsTemps.resource,
                        ticketsFilter: ticketsTemps.resource
                    },()=>{
                        this.setState({
                                tabTickets: [...new Set(this.state.tickets.map(x => x))]
                            }, () => {
                            this.setState({
                                selectTicketsNomSociete:  this.state.tickets.map(ticket => ({
                                    nom: ticket.nomSousSociete,
                                    adresse: ticket.adresse
                                })),
                                selectTicketsPrenomClient:  this.state.tabTickets.map(ticket => ({
                                    id: ticket.clientId,
                                    prenom: ticket.prenomClient
                                })),
                                selectTicketsTypeSociete: this.state.tickets.map(ticket => ({
                                    nom: ticket.typeSocieteClient
                                })),
                                selectTicketsPrenomTechnicien:  this.state.tabTickets.filter(ticket => ticket.prenomTechnicien !== null).map(ticket => ({
                                    id: ticket.technicienId,
                                    prenom: ticket.prenomTechnicien
                                }))
                            },() => {
                                this.setState({
                                    isLoading: false,
                                    /* on retire les doublons dans les listes en fonction d'un attribut */
                                    selectTicketsNomSociete: [...new Map(this.state.selectTicketsNomSociete.map(item => [item["nom"], item])).values()],
                                    selectTicketsTypeSociete: [...new Map(this.state.selectTicketsTypeSociete.map(item => [item["nom"], item])).values()],
                                    selectTicketsPrenomClient: [...new Map(this.state.selectTicketsPrenomClient.map(item => [item["id"], item])).values()],
                                    selectTicketsPrenomTechnicien: [...new Map(this.state.selectTicketsPrenomTechnicien.map(item => [item["id"], item])).values()]
                                })
                            })
                        })
                    })
                }
            )
        })
    }

    getTicketsTempsFilter = () => {
        this.setState({
            isLoading: true
        },()=>{
            /* On remplit la liste en fonction des filtres */
            ticketService.RecupereTicketsTempsParTechnicien(this.state.select["technicien"],this.state.select["societe"],this.state.select["type"],this.state.select["priorite"],this.state.select["client"],this.state.select["facturation"],this.state.select["statut"],this.state.select["dateDebut"],this.state.select["dateFin"]).then(
                (ticketsTemps) => {
                    this.setState({
                            ticketsFilter: ticketsTemps.resource
                    },()=>{
                        this.setState({
                            isLoading: false
                        })
                    })
                },()=> {
                    this.setState({
                            ticketsFilter: []
                    },()=>{
                        this.setState({
                            isLoading: false
                        })
                    })
                }
            )
        })  
    }

    handleChange = (ticketId,e) => {
        let facturation = e.target.value
        this.state.tickets.forEach(ticket => {
            if(ticket.id === ticketId){
                ticketService.ModifierFacturationTicket(ticketId, facturation).then(
                    () => {
                        ticket.facturation = facturation
                    }
                )
            }
        });
    }

    handleFilter = (value,action) => {
        if(value !== null){
            let select = this.state.select;
            if(action.name === "client" || action.name === "technicien"){
                select[action.name] = value.id
            }else if(action.name === "facturation"){
                select[action.name] = value.type
            }else if(action.name === "statut"){
                select[action.name] = value.statut
            }else{
                select[action.name] = value.nom
            }
            this.setState({
                select: select
            },()=>{
                this.getTicketsTempsFilter()
            })
        }else {
            let select = this.state.select;
            if(action.name === "societe" || action.name === "priorite" || action.name === "facturation"){
                select[action.name] = "Toutes"
            }else if(action.name === "type" || action.name === "client" || action.name === "statut" || action.name === "technicien"){
                select[action.name] = "Tous"  
            }else{
                select[action.name] = null
            }
             this.setState({
                select: select
            },()=>{
                this.getTicketsTempsFilter()
            })
        }
    }

    handleDateMinChange = (e) => {
        let select = this.state.select;
        select[e.target.name] = e.target.value
        this.setState({
            select: select
        },()=>{
            if(this.state.select["dateDebut"] !== "" && this.state.select["dateFin"] !== ""){
                this.getTicketsTempsFilter()
            }
        })
    }

    handleDateMaxChange = (e) => {
        let select = this.state.select;
        select[e.target.name] = e.target.value
        this.setState({
            select: select
        },()=>{
            if(this.state.select["dateDebut"] !== "" && this.state.select["dateFin"] !== ""){
                this.getTicketsTempsFilter()
            }
        })
    }

    customFilterSociete = (option, searchText) => {
        if (
            (option.data.adresse !== null && option.data.adresse.ville !== null && option.data.adresse.ville.toLowerCase().includes(searchText.toLowerCase())) 
            || (option.data !== null && option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
        ) {
            return true;
        } else {
            return false;
        }
    }

    sortTableByTotalTemps = () => {
        const { currentSort } = this.state;
		let nextSort;

		if (currentSort === 'down') nextSort = 'up';
		else if (currentSort === 'up') nextSort = 'down';

		this.setState({
			currentSort: nextSort
		},() => {
            if(currentSort === 'down'){
                this.setState({
                    ticketsFilter:  this.state.ticketsFilter.sort((a,b) => {
                                    return a.tempsTechnicien - b.tempsTechnicien
                                })
                })
            }else if(currentSort === "up"){
                this.setState({
                    ticketsFilter:  this.state.ticketsFilter.sort((a,b) => {
                                    return b.tempsTechnicien - a.tempsTechnicien
                                })
                })
            }
        });
    }

    resetValueSelect = () => {
        this.selectRef["societe"].select.clearValue();
        this.selectRef["type"].select.clearValue();
        this.selectRef["priorite"].select.clearValue();
        this.selectRef["client"].select.clearValue();
        if(this.props.type !== "tempsDev"){
            this.selectRef["technicien"].select.clearValue();
        }
        this.selectRef["statut"].select.clearValue();
        this.selectRef["facturation"].select.clearValue();       
        document.getElementsByName("dateDebut")[0].value = ""
        document.getElementsByName("dateFin")[0].value = ""
        this.getTicketsTemps()
    }

    showInformationTicket = (ticketId) => {
        this.setState({
            ticketIdClicked: ticketId,
            redirect: true
        })
    }
    
    render() {
        const {ticketsFilter,isLoading,activeTab} = this.state
        const {nameHeader} = this.props
        return (
            <div>
                {this.state.redirect === true && <Redirect to={{pathname: `/support/ticket/information/${this.state.ticketIdClicked}`, state: {type: "gestionTemps"}}}/>}
                <Card>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({active: activeTab === "1"})}
                                onClick={() => {
                                    this.toggle("1");
                                }}
                            >
                                <span className="d-none d-md-block">
                                    <i className="mdi mdi-filter-outline"/> Filtres
                                </span>
                                <span className="d-block d-md-none">
                                    <i className="mdi mdi-filter-outline"/>
                                </span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                        
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <CardBody className="pb-2">
                                        <Row>
                                            <Col lg={3}>
                                                <FormGroup>
                                                    <Label>Rechercher par société</Label>
                                                    {this.state.selectTicketsNomSociete !== null &&
                                                        <Select 
                                                        getOptionLabel={option => `${option.nom}`}
                                                        getOptionValue={option => `${option.nom}`}
                                                        styles={this.colourStyles}
                                                        options={this.state.selectTicketsNomSociete}
                                                        onChange={this.handleFilter}
                                                        filterOption={this.customFilterSociete}
                                                        name="societe"
                                                        placeholder="Votre societé/ville"
                                                        noOptionsMessage={() => "Aucune société"}
                                                        ref={ref => {
                                                                this.selectRef["societe"] = ref}}
                                                        isClearable />
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2}>
                                                <FormGroup>
                                                    <Label>Rechercher par type</Label>
                                                    {this.state.selectTicketsTypeSociete !== null &&
                                                        <Select 
                                                            getOptionLabel={option => `${option.nom}`}
                                                            getOptionValue={option => `${option.nom}`}
                                                            styles={this.colourStyles}
                                                            options={this.state.selectTicketsTypeSociete}
                                                            onChange={this.handleFilter}
                                                            noOptionsMessage={() => "Aucun type"}
                                                            placeholder="Votre type"
                                                            name="type"
                                                            ref={ref => {
                                                                this.selectRef["type"] = ref}}
                                                            isClearable 
                                                        />
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2}>
                                                <FormGroup>
                                                    <Label>Rechercher par priorité</Label>
                                                    {this.selectTicketsPriorite !== null &&
                                                        <Select 
                                                            getOptionLabel={option => `${option.nom}`}
                                                            getOptionValue={option => `${option.nom}`}
                                                            styles={this.colourStyles}
                                                            options={this.selectTicketsPriorite}
                                                            onChange={this.handleFilter}
                                                            noOptionsMessage={() => "Aucune priorité"}
                                                            placeholder="Votre priorité"
                                                            isClearable
                                                            name="priorite" 
                                                            ref={ref => {
                                                                this.selectRef["priorite"] = ref}}
                                                        />
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2}>
                                                <FormGroup>
                                                    <Label>Rechercher par client</Label>
                                                        {this.state.selectTicketsPrenomClient !== null &&
                                                            <Select 
                                                            getOptionLabel={option => `${option.prenom}`}
                                                            getOptionValue={option => `${option.prenom}`}
                                                            styles={this.colourStyles}
                                                            options={this.state.selectTicketsPrenomClient}
                                                            onChange={this.handleFilter}
                                                            name="client"
                                                            placeholder="Votre client"
                                                            noOptionsMessage={() => "Aucun client"}
                                                            isClearable 
                                                            ref={ref => {
                                                                this.selectRef["client"] = ref}}   
                                                            />
                                                        }
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2}>
                                                <FormGroup>
                                                    <Label>Rechercher par facturation</Label>
                                                    {this.selectTicketsFacturation!== null &&
                                                        <Select 
                                                            getOptionLabel={option => `${option.type}`}
                                                            getOptionValue={option => `${option.type}`}
                                                            styles={this.colourStyles}
                                                            options={this.selectTicketsFacturation}
                                                            onChange={this.handleFilter}
                                                            noOptionsMessage={() => "Aucune facturation"}
                                                            placeholder="Votre facturation"
                                                            isClearable
                                                            name="facturation" 
                                                            ref={ref => {
                                                                this.selectRef["facturation"] = ref}}
                                                        />
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={2}>
                                                <FormGroup>
                                                    <Label>Rechercher par statut</Label>
                                                    {this.selectTicketsStatut !== null &&
                                                    <Select 
                                                        getOptionLabel={option => `${option.statut}`}
                                                        getOptionValue={option => `${option.statut}`}
                                                        styles={this.colourStyles}
                                                        options={this.selectTicketsStatut}
                                                        onChange={this.handleFilter}
                                                        noOptionsMessage={() => "Aucune statut"}
                                                        placeholder="Votre statut"
                                                        name="statut"
                                                        isClearable 
                                                        ref={ref => {
                                                            this.selectRef["statut"] = ref}}
                                                    />
                                                }
                                                </FormGroup>
                                            </Col>
                                            {this.props.type !== "tempsDev" && (this.roleId === "3" || this.roleId === "1") &&
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>Rechercher par technicien</Label>
                                                        {this.state.selectTicketsPrenomTechnicien !== null &&
                                                        <Select 
                                                            getOptionLabel={option => `${option.prenom}`}
                                                            getOptionValue={option => `${option.prenom}`}
                                                            styles={this.colourStyles}
                                                            options={this.state.selectTicketsPrenomTechnicien}
                                                            onChange={this.handleFilter}
                                                            noOptionsMessage={() => "Aucune technicien"}
                                                            placeholder="Votre technicien"
                                                            name="technicien"
                                                            isClearable 
                                                            ref={ref => {
                                                                this.selectRef["technicien"] = ref}}
                                                        />
                                                    }
                                                    </FormGroup>
                                                </Col>
                                            }
                                            
                                            <Col lg={3}>
                                                <FormGroup>
                                                    <Label>Rechercher par mois : Date
                                                        début</Label>
                                                    <Input type="date" 
                                                            name="dateDebut" id="dateDebut"
                                                            placeholder="date placeholder"
                                                            onChange={this.handleDateMinChange}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3}>
                                                <FormGroup>
                                                    <Label> Date fin</Label>
                                                    <InputGroup>
                                                        <Input type="date" 
                                                                name="dateFin" id="dateFin "
                                                                placeholder="date placeholder"
                                                                onChange={this.handleDateMaxChange}>
                                                        </Input>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>                                                                                                                  
                                            {
                                                (this.state.select["societe"] !== "Toutes" ||
                                                this.state.select["type"] !== "Tous" ||
                                                this.state.select["client"] !== "Tous" ||
                                                this.state.select["statut"] !== "Tous" ||
                                                this.state.select["priorite"] !== "Toutes" || 
                                                this.state.select["facturation"] !== "Toutes" ||
                                                ((this.state.select["technicien"] !== this.props.utilisateurId && this.state.select["technicien"] !== "Tous" ) && this.props.type !== "tempsDev") ||
                                                this.state.select["statut"] !== "Tous" ||
                                                (this.state.select["dateDebut"] !== null &&
                                                this.state.select["dateFin"] !== null)) &&
                                                <Col lg={12}>
                                                    <InputGroupAddon addonType="append" className="float-right">
                                                        <button className="btn btn-danger"
                                                                onClick={() =>this.resetValueSelect()}>Effacer les filtres</button>
                                                    </InputGroupAddon>
                                                </Col>  
                                            }
                                        </Row>
                                    </CardBody>
                                
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </Card>
                
                <Card>
                    <CardBody className="bg-info-gradient rounded-top">
                        <CardTitle className="h4 mb-0 text-white">Liste des tickets</CardTitle>
                    </CardBody>
                    <Table className="mb-0 align-middle" hover responsive size={"sm"}>
                        <thead>
                            <tr>
                                {nameHeader.map((name,i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                {name === "Temps" ? 
                                                    <th scope="col" className="cursor" onClick={ () => this.sortTableByTotalTemps()}>{name} <i className="fas fa-sort"></i></th>
                                                : <th scope="col" >{name}</th>}
                                            </React.Fragment>
                                        )
                                    })}
                            </tr>
                        </thead>
                        
                        
                        <tbody>
                            {isLoading ? 
                            /* On affiche une liste squelette en attendant que la liste soit chargée */
                            <>
                                {Array(7).fill().map((i,index) => (
                                    <tr key={index}>
                                        {Array(13).fill().map((i,index) => (
                                            <td key={index}>
                                                <Skeleton width={'100%'} duration={1} />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </> 
                            :
                            <>
                                {ticketsFilter !== undefined && ticketsFilter.map((ticket) => (   
                                        <React.Fragment key={ticket.id}>
                                            <tr>
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.id}</td>
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.societeId}</td>
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.nomSousSociete}</td>
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.typeSocieteClient}</td>
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.prenomClient}</td>
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.sujet}</td>
                                                <td className="text-center cursor h5" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.priorite === "Haute" ? <span className="badge badge-pill bg-danger text-white">{ticket.priorite}</span> : ticket.priorite === "Moyenne" ? <span className="badge badge-pill bg-warning text-white">{ticket.priorite}</span> : <span className="badge badge-pill bg-successV2 text-white">{ticket.priorite}</span>}</td>
                                                <td className="text-center cursor h5" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.statut === "En attente" ?
                                                                            <span className="badge badge-pill bg-warning text-white">{ticket.statut}</span> :
                                                                            ticket.statut === "Clôture" ? <span className="badge badge-pill bg-success text-white">{ticket.statut}</span> :
                                                                            ticket.statut === "Ouvert" ? <span className="badge badge-pill bg-danger text-white">{ticket.statut}</span> :
                                                                            ticket.statut === "En cours" ? <span className="badge badge-pill bg-secondary text-white">{ticket.statut}</span>:
                                                                            <span className="badge badge-pill bg-primary text-white">{ticket.statut}</span>} 
                                                </td>
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{ticket.module}</td>
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{dateFormat(ticket.dateCreation,"dd/mm/yyyy HH:MM")}</td>
                                                {(this.roleId === "3"  ||this.roleId === "1" ) && <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{retourneTechnicien(ticket.prenomTechnicien)}</td>}
                                                {(Math.floor(((ticket.tempsTechnicien/1000) / 3600) < 2)) ? 
                                                <td className="text-center cursor" onClick={ () => this.showInformationTicket(ticket.id)}>{`${String(Math.floor((ticket.tempsTechnicien/1000) / 3600))}h ${String(Math.floor(((ticket.tempsTechnicien/1000) % 3600) / 60))}m ${String(Math.floor((ticket.tempsTechnicien/1000) % 60))}s`}</td>
                                                :
                                                (Math.floor(((ticket.tempsTechnicien/1000) / 3600)) >= 2 && Math.floor(((ticket.tempsTechnicien/1000) / 3600) < 4)) ? 
                                                <td className="text-center cursor text-warning" onClick={ () => this.showInformationTicket(ticket.id)}>{`${String(Math.floor((ticket.tempsTechnicien/1000) / 3600))}h ${String(Math.floor(((ticket.tempsTechnicien/1000) % 3600) / 60))}m ${String(Math.floor((ticket.tempsTechnicien/1000) % 60))}s`}</td>
                                                :
                                                <td className="text-center cursor text-danger" onClick={ () => this.showInformationTicket(ticket.id)}>{`${String(Math.floor((ticket.tempsTechnicien/1000) / 3600))}h ${String(Math.floor(((ticket.tempsTechnicien/1000) % 3600) / 60))}m ${String(Math.floor((ticket.tempsTechnicien/1000) % 60))}s`}</td>
                                                }
                                                <td className="text-center"><select className="custom-select" defaultValue={ticket.facturation} onChange={this.handleChange.bind(this,ticket.id)}>
                                                                                <option>Non-facturé</option>
                                                                                <option>A facturer</option>
                                                                                <option>Facturé</option>
                                                                            </select>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </>
                             }
                        </tbody>
                        
                    </Table>
                </Card>
            </div>
        )
    }
}
