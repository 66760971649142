import React, { Component } from "react";
import logochargerment from '../../assets/images/logo-chargement-anim.svg';

export default class Chargement extends Component {

    componentDidMount() {}

    isInViewport() {
        if (!this.imageChargement) return false;
        const top = this.imageChargement.getBoundingClientRect().top;
        return (top) >= 0 && (top) <= window.innerHeight;
    }

    render() {
        return(
            <div className="full-size d-flex justify-content-center align-items-center" style={{minHeight:'100%'}}>
                <div className="card p-4 d-flex justify-content-center align-items-center">
                    <img src={logochargerment} className="start" width="85px" alt="chargement" ref={(el) => this.imageChargement = el}/>
                </div>
            </div>
        )
    }
}
