import {authHeader, handleResponse } from '../_helpers';


export const sousCategorieService = {
    // (GET)
    RecupereSousCategories,

};

function RecupereSousCategories(){
    const requestOptions = {method: "GET",headers: authHeader(true)};
    let url = process.env.REACT_APP_API + "/sousCategories/";

    return fetch(url, requestOptions)
                .then(handleResponse)
                .then((reponse) => {
                    return reponse;
                });
}
