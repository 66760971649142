import {authHeader, handleResponse } from '../_helpers';


export const categorieService = {
    // (GET)
    RecupereCategories,

    // POST
    EnregistrerCategorie,

    // (DELETE)
    SupprimerCategorie
};

function RecupereCategories(){
    const requestOptions = {method: "GET",headers: authHeader(true)};
    let url = process.env.REACT_APP_API + "/categories/";

    return fetch(url, requestOptions)
                .then(handleResponse)
                .then((reponse) => {
                    return reponse;
                });
}

function SupprimerCategorie(categorieId) {
    let url = process.env.REACT_APP_API + "/categories/" + categorieId;
    let requestOptions = {
        method: "DELETE",
        headers: authHeader(true),
        body: ""
    };
    
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((reponse) => {
            return reponse;
        });
}

function EnregistrerCategorie(nom){

    const url = `${process.env.REACT_APP_API}/categories?nom=${nom}`;

    let requestOptions = {
        method: "POST",
        headers: authHeader(false,false,true),
        body: ""
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((reponse) => {
            return reponse;
        });
}
