import {ErrorMessage, Field, Form, Formik} from "formik";
import React from "react";
import {
    Button,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import * as Yup from "yup";
import logodarkicon from "../../assets/images/logo-icon.png";
import Chargement from "../../components/Chargement/Chargement";
import {authenticationService} from "../../jwt/_services";
import { BehaviorSubject } from 'rxjs'; 
import jwt_decode from 'jwt-decode'

const sidebarBackground = {
    backgroundImage: "linear-gradient(-45deg, #247475 -15%, #39b6b8 70%)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center"
};

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));


const EVENT_KEY_DOWN = "keydown";
const EVENT_KEY_UP = "keyup";

class Login extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            error: null,
            isCapsLockActive: false
        };
        
        this.handleClick = this.handleClick.bind(this);
    }
    
    componentDidMount() {
        document.addEventListener(EVENT_KEY_DOWN, this.wasCapsLockActivated);
        document.addEventListener(EVENT_KEY_UP, this.wasCapsLockDeactivated);  
        
        if(this.props.match.params.token){
            this.setState({
                isLoading: true
            },()=>{
                localStorage.removeItem("currentUser");
                currentUserSubject.next(null);
                let token = new Buffer(this.props.match.params.token, 'base64').toString('ascii')
                try{
                   let tokenDecode = jwt_decode(token)
                   let currentDate = new Date()
                   if (tokenDecode.exp * 1000 < currentDate.getTime()) {
                        this.setState({
                            isLoading: false
                        }) 
                    } else {
                        localStorage.setItem("currentUser", JSON.stringify({"token" : token}));
                        currentUserSubject.next(token);
                        window.location.pathname = "/"
                        this.setState({
                            isLoading: false
                        })  
                    }
                }catch (err){
                    this.setState({
                        isLoading: false
                    }) 
                }
                
                
            })
            
        }else if(currentUserSubject.value !== null){
            this.setState({
                isLoading: true
            },()=>{
                window.location.pathname = "/"
                this.setState({
                    isLoading : false
                })
            })
            
        }
    }
    
    componentWillUnmount() {
        document.removeEventListener(EVENT_KEY_DOWN, this.wasCapsLockActivated);
        document.removeEventListener(EVENT_KEY_UP, this.wasCapsLockDeactivated);
    }
    
    wasCapsLockActivated = event => {
        if (
            event.getModifierState &&
            event.getModifierState("CapsLock") &&
            this.state.isCapsLockActive === false
        ) {
            this.setState({isCapsLockActive: true});
        }
    };
    
    wasCapsLockDeactivated = event => {
        if (
            event.getModifierState &&
            !event.getModifierState("CapsLock") &&
            this.state.isCapsLockActive === true
        ) {
            this.setState({isCapsLockActive: false});
        }
    };
    
    handleClick() {
        var elem = document.getElementById("loginform");
        elem.style.transition = "all 2s ease-in-out";
        elem.style.display = "none";
        document.getElementById("recoverform").style.display = "block";
    }
    
    render() {
        
        const {isLoading} = this.state;
        
        return (
            <>
                <div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
                    <div className="container">
                        <div>
                            <Row className="no-gutters justify-content-center">
							{isLoading ? (
                                    <Chargement/>
                                ) : (
                                    <Col md="6" lg="4">
                                        <div className="text-center p-4">
                                            <img src={logodarkicon} alt="homepage" className="justify-content-center"/>
                                        </div>
                                        <div className="p-4 bg-white">
                                            <h3 className="font-medium mb-3">Se connecter</h3>
                                            
                                            <Formik
                                                initialValues={{
                                                    pseudo: "",
                                                    motDePasse: ""
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    pseudo: Yup.string().required("Identifiant invalide."),
                                                    motDePasse: Yup.string().required("Mot de passe invalide.")
                                                })}
                                                onSubmit={({pseudo, motDePasse}, {setStatus, setSubmitting}) => {
                                                    setStatus();
                                                    this.setState({isLoading: true});
                                                    authenticationService.login(pseudo, motDePasse).then(
                                                        () => {
                                                            const {from} = this.props.location.state || {
                                                                from: {
                                                                    pathname: "/"
                                                                }
                                                            };
                                                            this.props.history.push(from);
                                                        },
                                                        (error) => {
                                                            this.setState({isLoading: false, error: error});
                                                            setSubmitting(false);
                                                            setStatus(error);
                                                        }
                                                    );
                                                }}
                                                render={({errors, touched, isSubmitting}) => (
                                                    <Form className="mt-3" id="loginform">
                                                        {/* Identifiant */}
                                                        <Label for="pseudo" className="font-medium">
                                                            Identifiant
                                                        </Label>
                                                        <InputGroup className="mb-2" size="lg">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="ti-user"/>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Field
                                                                id="pseudo"
                                                                name="pseudo"
                                                                type="text"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.pseudo && touched.pseudo
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="pseudo"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </InputGroup>
                                                        
                                                        {/* Mot de passe */}
                                                        <Label for="motDePasse" className="mt-3 font-medium">
                                                            Mot de passe
                                                        </Label>
                                                        <InputGroup className="mb-3" size="lg">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="ti-key"/>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Field
                                                                id="motDePasse"
                                                                name="motDePasse"
                                                                type="password"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.motDePasse && touched.motDePasse
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="motDePasse"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </InputGroup>
                                                        
                                                        {this.state.isCapsLockActive &&
                                                        <div className="text-danger text-center mb-4">
                                                            La majuscule automatique est enclenchée !
                                                        </div>
                                                        }
                                                        
                                                        {this.state.error !== null && (
                                                            <div className="text-danger text-center mb-4">
                                                                {this.state.error.messages[0]}
                                                            </div>
                                                        )}
                                                        
                                                        {/* Bouton de connexion */}
                                                        <Row className="mb">
                                                            <Col xs="12">
                                                                <Button
                                                                    id="submitLogin"
                                                                    type="submit"
                                                                    color="success"
                                                                    size="lg"
                                                                    block
                                                                    disabled={isSubmitting}
                                                                >
                                                                    Connexion
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                </div>
                
            </>
        );
    }
}

export default Login;
