import React, { Component } from "react";
import GraphiqueDuree from "../../components/Statistiques/GraphiqueDuree";
import TableauDuree from "../../components/Statistiques/TableauDuree";
import { ticketService } from "../../jwt/_services/ticket.service";
import {
  ButtonGroup,
  Col,
  Row,
  FormGroup,
  Label,
  NavLink,
  NavItem,
  Nav,
  Card,
  CardBody,
  TabContent,
  Input,
  InputGroup,
  InputGroupAddon,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import RecupereRoleId from "../../components/Fonctions/GestionToken/RecupereRoleId";
import Chargement from "../../components/Chargement/Chargement";
import dateFormat from "dateformat";

// Export Excel:
import ReactExport from "@sachamarits/react-export-excel4";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class StatistiqueDuree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      tickets: [],
      ticketsFilter: [],
      select: {
        sousSociete: "",
        typeSociete: "",
        clientId: "",
        dateDebut: null,
        dateFin: null,
        technicienId: "",
        priorite: "",
        typeCategorie: "",
      },
      selectTicketsNomSociete: null,
      selectTicketsTypeSociete: null,
      selectTicketsPrenomClient: null,
      selectTicketsPrenomTechnicien: null,
      selected: "graphique",
      isLoading: true,
      dataGraphique: null,
      dataListe: null,
    };
    this.roleId = RecupereRoleId();
    this.selectTicketsPriorite = [
      { nom: "Faible" },
      { nom: "Moyenne" },
      { nom: "Haute" },
    ];
    this.selectRef = {
      clientId: null,
      sousSociete: null,
      typeSociete: null,
      technicienId: null,
      priorite: null,
      typeCategorie: null,
    };
    this.colourStyles = {
      option: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "gray" : null,
          color: "#333333",
        };
      },
    };
    this.optionsbar = {
      colors: ["#26BEBE"],
      dataLabels: {
        enabled: false,
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          "Temps moyen de résolution d'un ticket",
          "Temps maximum de résolution d'un ticket",
          "Temps de réponse moyen des clients",
          "Temps de réponse moyen du support",
        ],
        labels: {
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Open sans",
          },
          formatter: (value) => {
            return `${String(Math.floor(value / 86400))}j ${String(
              Math.floor((value % 86400) / 3600)
            )}h ${String(Math.floor(((value % 86400) % 3600) / 60))}m ${String(
              Math.floor(((value % 86400) % 3600) % 60)
            )}s`;
          },
        },
      },
      yaxis: {
        labels: {
          maxWidth: 290,
          style: {
            fontFamily: "Open sans",
            fontSize: "12px",
          },
          formatter: undefined,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
        },
      },
      tooltip: {
        style: {
          fontFamily: "Open sans",
        },
        y: {
          formatter: (value) => {
            return `${String(Math.floor(value / 86400))}j ${String(
              Math.floor((value % 86400) / 3600)
            )}h ${String(Math.floor(((value % 86400) % 3600) / 60))}m ${String(
              Math.floor(((value % 86400) % 3600) % 60)
            )}s`;
          },
        },
        x: {
          formatter: (seriesName) => seriesName,
        },
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            xaxis: {
              categories: [
                "Temps moyen",
                "Temps maximum",
                "Temps moyen clients",
                "Temps moyen support",
              ],
              labels: {
                rotate: -90,
                style: {
                  fontSize: "11.7px",
                  cssClass: "labelDuree",
                },
                minHeight: 140,
                formatter: undefined,
                trim: false,
              },
            },
            yaxis: {
              labels: {
                formatter: (value) => {
                  return `${String(Math.floor(value / 86400))}j ${String(
                    Math.floor((value % 86400) / 3600)
                  )}h ${String(
                    Math.floor(((value % 86400) % 3600) / 60)
                  )}m ${String(Math.floor(((value % 86400) % 3600) % 60))}s`;
                },
              },
            },
          },
        },
      ],
    };
    // Initialise les données sur l'axe des x du graphique
    this.seriesbar = [
      {
        name: "Durées",
        data: [],
      },
    ];
  }

  componentDidMount() {
    this.getStatistiqueDuree();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    } else {
      this.setState({ activeTab: "" });
    }
  }

  getStatistiqueDuree = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.seriesbar[0].data = [];
        ticketService
          .RecupereStatistiqueDuree(
            this.state.select["sousSociete"],
            this.state.select["typeSociete"],
            this.state.select["clientId"],
            this.state.select["dateDebut"],
            this.state.select["dateFin"],
            this.state.select["technicienId"],
            this.state.select["priorite"],
            this.state.select["typeCategorie"]
          )
          .then((tickets) => {
            this.setState(
              {
                tickets: tickets.resource,
                ticketsFilter: tickets.resource,
              },
              () => {
                this.setState(
                  {
                    tabTickets: [...new Set(this.state.tickets.map((x) => x))],
                  },
                  () => {
                    let tabTicketsTempsTotal = [];
                    let tabTicketsTempsSupport = [];
                    let tabTicketsTempsClient = [];
                    for (
                      let index = 0;
                      index < this.state.tickets.length;
                      index++
                    ) {
                      tabTicketsTempsTotal.push(
                        this.state.tickets[index].tempsTotal
                      );
                      tabTicketsTempsSupport.push(
                        this.state.tickets[index].tempsSupport
                      );
                      tabTicketsTempsClient.push(
                        this.state.tickets[index].tempsClient
                      );
                    }
                    if (this.state.tickets.length !== 0) {
                      this.seriesbar[0].data.push(
                        Math.floor(
                          tabTicketsTempsTotal.reduce((a, b) => a + b) /
                            tabTicketsTempsTotal.length
                        )
                      );
                      this.seriesbar[0].data.push(
                        Math.floor(Math.max(...tabTicketsTempsTotal))
                      );
                      this.seriesbar[0].data.push(
                        Math.floor(
                          tabTicketsTempsClient.reduce((a, b) => a + b) /
                            tabTicketsTempsClient.length
                        )
                      );
                      this.seriesbar[0].data.push(
                        Math.floor(
                          tabTicketsTempsSupport.reduce((a, b) => a + b) /
                            tabTicketsTempsSupport.length
                        )
                      );
                    } else {
                      this.seriesbar[0].data = [];
                    }

                    this.setState(
                      {
                        selectTicketsNomSociete: this.state.tickets.map(
                          (ticket) => ({
                            nom: ticket.sousSociete,
                            adresse: ticket.adresseSousSociete,
                          })
                        ),
                        selectTicketsTypeSociete: this.state.tickets.map(
                          (ticket) => ({
                            nom: ticket.typeSociete,
                          })
                        ),
                        selectTicketsPrenomClient: this.state.tabTickets.map(
                          (client) => ({
                            id: client.clientId,
                            prenom: client.prenomClient,
                            nom: client.nomClient,
                          })
                        ),
                        selectTicketsPrenomTechnicien:
                          this.state.tabTickets.map((tech) => ({
                            id: tech.technicienId,
                            prenom: tech.prenomTechnicien,
                          })),
                        selectTicketsCategorie: this.state.tabTickets.map(
                          (ticket) => ({
                            type: ticket.typeCategorie,
                          })
                        ),
                      },
                      () => {
                        let dataGraphique = [];

                        dataGraphique.push({
                          TempsMoyen: `${String(
                            Math.floor(this.seriesbar[0].data[0] / 86400)
                          )}j ${String(
                            Math.floor(
                              (this.seriesbar[0].data[0] % 86400) / 3600
                            )
                          )}h ${String(
                            Math.floor(
                              ((this.seriesbar[0].data[0] % 86400) % 3600) / 60
                            )
                          )}m ${String(
                            Math.floor(
                              ((this.seriesbar[0].data[0] % 86400) % 3600) % 60
                            )
                          )}s`,
                          TempsMaximum: `${String(
                            Math.floor(this.seriesbar[0].data[1] / 86400)
                          )}j ${String(
                            Math.floor(
                              (this.seriesbar[0].data[1] % 86400) / 3600
                            )
                          )}h ${String(
                            Math.floor(
                              ((this.seriesbar[0].data[1] % 86400) % 3600) / 60
                            )
                          )}m ${String(
                            Math.floor(
                              ((this.seriesbar[0].data[1] % 86400) % 3600) % 60
                            )
                          )}s`,
                          TempsMoyenClients: `${String(
                            Math.floor(this.seriesbar[0].data[2] / 86400)
                          )}j ${String(
                            Math.floor(
                              (this.seriesbar[0].data[2] % 86400) / 3600
                            )
                          )}h ${String(
                            Math.floor(
                              ((this.seriesbar[0].data[2] % 86400) % 3600) / 60
                            )
                          )}m ${String(
                            Math.floor(
                              ((this.seriesbar[0].data[2] % 86400) % 3600) % 60
                            )
                          )}s`,
                          TempsMoyenSupport: `${String(
                            Math.floor(this.seriesbar[0].data[3] / 86400)
                          )}j ${String(
                            Math.floor(
                              (this.seriesbar[0].data[3] % 86400) / 3600
                            )
                          )}h ${String(
                            Math.floor(
                              ((this.seriesbar[0].data[3] % 86400) % 3600) / 60
                            )
                          )}m ${String(
                            Math.floor(
                              ((this.seriesbar[0].data[3] % 86400) % 3600) % 60
                            )
                          )}s`,
                        });
                        let dataListe = [];
                        this.state.ticketsFilter.map((ticket) => {
                          dataListe.push({
                            id: ticket.id !== null ? ticket.id : "",
                            societe:
                              ticket.sousSociete !== null
                                ? ticket.sousSociete
                                : "",
                            type:
                              ticket.typeSociete !== null
                                ? ticket.typeSociete
                                : "",
                            client:
                              ticket.prenomClient !== null
                                ? ticket.prenomClient
                                : "",
                            sujet: ticket.sujet !== null ? ticket.sujet : "",
                            technicien:
                              ticket.prenomTechnicien !== null
                                ? ticket.prenomTechnicien
                                : "",
                            dateCreation:
                              ticket.dateCreation !== null
                                ? dateFormat(
                                    ticket.dateCreation,
                                    "dd/mm/yyyy HH:MM"
                                  )
                                : "",
                            priorite:
                              ticket.priorite !== null ? ticket.priorite : "",
                            module: ticket.module !== null ? ticket.module : "",
                            typeCategorie:
                              ticket.typeCategorie !== null
                                ? ticket.typeCategorie
                                : "",
                            tempsTotal:
                              ticket.tempsTotal !== null
                                ? `${String(
                                    Math.floor(ticket.tempsTotal / 86400)
                                  )}j ${String(
                                    Math.floor(
                                      (ticket.tempsTotal % 86400) / 3600
                                    )
                                  )}h ${String(
                                    Math.floor(
                                      ((ticket.tempsTotal % 86400) % 3600) / 60
                                    )
                                  )}m ${String(
                                    Math.floor(
                                      ((ticket.tempsTotal % 86400) % 3600) % 60
                                    )
                                  )}s`
                                : "",
                            tempsSupport:
                              ticket.tempsSupport !== null
                                ? `${String(
                                    Math.floor(ticket.tempsSupport / 86400)
                                  )}j ${String(
                                    Math.floor(
                                      (ticket.tempsSupport % 86400) / 3600
                                    )
                                  )}h ${String(
                                    Math.floor(
                                      ((ticket.tempsSupport % 86400) % 3600) /
                                        60
                                    )
                                  )}m ${String(
                                    Math.floor(
                                      ((ticket.tempsSupport % 86400) % 3600) %
                                        60
                                    )
                                  )}s`
                                : "",
                            tempsClient:
                              ticket.tempsClient !== null
                                ? `${String(
                                    Math.floor(ticket.tempsClient / 86400)
                                  )}j ${String(
                                    Math.floor(
                                      (ticket.tempsClient % 86400) / 3600
                                    )
                                  )}h ${String(
                                    Math.floor(
                                      ((ticket.tempsClient % 86400) % 3600) / 60
                                    )
                                  )}m ${String(
                                    Math.floor(
                                      ((ticket.tempsClient % 86400) % 3600) % 60
                                    )
                                  )}s`
                                : "",
                          });
                          return ticket;
                        });
                        this.setState({
                          dataGraphique: dataGraphique,
                          dataListe: dataListe,
                          isLoading: false,
                          selectTicketsNomSociete: [
                            ...new Map(
                              this.state.selectTicketsNomSociete.map((item) => [
                                item["nom"],
                                item,
                              ])
                            ).values(),
                          ],
                          selectTicketsTypeSociete: [
                            ...new Map(
                              this.state.selectTicketsTypeSociete.map(
                                (item) => [item["nom"], item]
                              )
                            ).values(),
                          ],
                          selectTicketsPrenomClient: [
                            ...new Map(
                              this.state.selectTicketsPrenomClient.map(
                                (item) => [item["id"], item]
                              )
                            ).values(),
                          ],
                          selectTicketsPrenomTechnicien: [
                            ...new Map(
                              this.state.selectTicketsPrenomTechnicien.map(
                                (item) => [item["id"], item]
                              )
                            ).values(),
                          ],
                        });
                      }
                    );
                  }
                );
              }
            );
          });
      }
    );
  };

  getStatistiqueDureeFiltre = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.seriesbar[0].data = [];
        ticketService
          .RecupereStatistiqueDuree(
            this.state.select["sousSociete"],
            this.state.select["typeSociete"],
            this.state.select["clientId"],
            this.state.select["dateDebut"],
            this.state.select["dateFin"],
            this.state.select["technicienId"],
            this.state.select["priorite"],
            this.state.select["typeCategorie"]
          )
          .then(
            (tickets) => {
              this.setState(
                {
                  ticketsFilter: tickets.resource,
                },
                () => {
                  let tabTicketsTempsTotal = [];
                  let tabTicketsTempsSupport = [];
                  let tabTicketsTempsClient = [];
                  for (
                    let index = 0;
                    index < this.state.ticketsFilter.length;
                    index++
                  ) {
                    tabTicketsTempsTotal.push(
                      this.state.ticketsFilter[index].tempsTotal
                    );
                    tabTicketsTempsSupport.push(
                      this.state.ticketsFilter[index].tempsSupport
                    );
                    tabTicketsTempsClient.push(
                      this.state.ticketsFilter[index].tempsClient
                    );
                  }
                  if (this.state.ticketsFilter.length !== 0) {
                    this.seriesbar[0].data.push(
                      Math.floor(
                        tabTicketsTempsTotal.reduce((a, b) => a + b) /
                          tabTicketsTempsTotal.length
                      )
                    );
                    this.seriesbar[0].data.push(
                      Math.floor(Math.max(...tabTicketsTempsTotal))
                    );
                    this.seriesbar[0].data.push(
                      Math.floor(
                        tabTicketsTempsClient.reduce((a, b) => a + b) /
                          tabTicketsTempsClient.length
                      )
                    );
                    this.seriesbar[0].data.push(
                      Math.floor(
                        tabTicketsTempsSupport.reduce((a, b) => a + b) /
                          tabTicketsTempsSupport.length
                      )
                    );
                  } else {
                    this.seriesbar[0].data = [];
                  }
                  let dataGraphique = [];
                  dataGraphique.push({
                    TempsMoyen: `${String(
                      Math.floor(this.seriesbar[0].data[0] / 86400)
                    )}j ${String(
                      Math.floor((this.seriesbar[0].data[0] % 86400) / 3600)
                    )}h ${String(
                      Math.floor(
                        ((this.seriesbar[0].data[0] % 86400) % 3600) / 60
                      )
                    )}m ${String(
                      Math.floor(
                        ((this.seriesbar[0].data[0] % 86400) % 3600) % 60
                      )
                    )}s`,
                    TempsMaximum: `${String(
                      Math.floor(this.seriesbar[0].data[1] / 86400)
                    )}j ${String(
                      Math.floor((this.seriesbar[0].data[1] % 86400) / 3600)
                    )}h ${String(
                      Math.floor(
                        ((this.seriesbar[0].data[1] % 86400) % 3600) / 60
                      )
                    )}m ${String(
                      Math.floor(
                        ((this.seriesbar[0].data[1] % 86400) % 3600) % 60
                      )
                    )}s`,
                    TempsMoyenClients: `${String(
                      Math.floor(this.seriesbar[0].data[2] / 86400)
                    )}j ${String(
                      Math.floor((this.seriesbar[0].data[2] % 86400) / 3600)
                    )}h ${String(
                      Math.floor(
                        ((this.seriesbar[0].data[2] % 86400) % 3600) / 60
                      )
                    )}m ${String(
                      Math.floor(
                        ((this.seriesbar[0].data[2] % 86400) % 3600) % 60
                      )
                    )}s`,
                    TempsMoyenSupport: `${String(
                      Math.floor(this.seriesbar[0].data[3] / 86400)
                    )}j ${String(
                      Math.floor((this.seriesbar[0].data[3] % 86400) / 3600)
                    )}h ${String(
                      Math.floor(
                        ((this.seriesbar[0].data[3] % 86400) % 3600) / 60
                      )
                    )}m ${String(
                      Math.floor(
                        ((this.seriesbar[0].data[3] % 86400) % 3600) % 60
                      )
                    )}s`,
                  });
                  let dataListe = [];
                  this.state.ticketsFilter.map((ticket) => {
                    dataListe.push({
                      id: ticket.id !== null ? ticket.id : "",
                      societe:
                        ticket.sousSociete !== null ? ticket.sousSociete : "",
                      type:
                        ticket.typeSociete !== null ? ticket.typeSociete : "",
                      client:
                        ticket.prenomClient !== null ? ticket.prenomClient : "",
                      sujet: ticket.sujet !== null ? ticket.sujet : "",
                      technicien:
                        ticket.prenomTechnicien !== null
                          ? ticket.prenomTechnicien
                          : "",
                      dateCreation:
                        ticket.dateCreation !== null
                          ? dateFormat(ticket.dateCreation, "dd/mm/yyyy HH:MM")
                          : "",
                      priorite: ticket.priorite !== null ? ticket.priorite : "",
                      module: ticket.module !== null ? ticket.module : "",
                      typeCategorie:
                        ticket.typeCategorie !== null
                          ? ticket.typeCategorie
                          : "",
                      tempsTotal:
                        ticket.tempsTotal !== null
                          ? `${String(
                              Math.floor(ticket.tempsTotal / 86400)
                            )}j ${String(
                              Math.floor((ticket.tempsTotal % 86400) / 3600)
                            )}h ${String(
                              Math.floor(
                                ((ticket.tempsTotal % 86400) % 3600) / 60
                              )
                            )}m ${String(
                              Math.floor(
                                ((ticket.tempsTotal % 86400) % 3600) % 60
                              )
                            )}s`
                          : "",
                      tempsSupport:
                        ticket.tempsSupport !== null
                          ? `${String(
                              Math.floor(ticket.tempsSupport / 86400)
                            )}j ${String(
                              Math.floor((ticket.tempsSupport % 86400) / 3600)
                            )}h ${String(
                              Math.floor(
                                ((ticket.tempsSupport % 86400) % 3600) / 60
                              )
                            )}m ${String(
                              Math.floor(
                                ((ticket.tempsSupport % 86400) % 3600) % 60
                              )
                            )}s`
                          : "",
                      tempsClient:
                        ticket.tempsClient !== null
                          ? `${String(
                              Math.floor(ticket.tempsClient / 86400)
                            )}j ${String(
                              Math.floor((ticket.tempsClient % 86400) / 3600)
                            )}h ${String(
                              Math.floor(
                                ((ticket.tempsClient % 86400) % 3600) / 60
                              )
                            )}m ${String(
                              Math.floor(
                                ((ticket.tempsClient % 86400) % 3600) % 60
                              )
                            )}s`
                          : "",
                    });
                    return ticket;
                  });
                  this.setState({
                    dataGraphique: dataGraphique,
                    dataListe: dataListe,
                    isLoading: false,
                  });
                }
              );
            },
            () => {
              this.setState(
                {
                  ticketsFilter: [],
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              );
            }
          );
      }
    );
  };

  handleFilter = (value, action) => {
    if (value !== null) {
      let select = this.state.select;
      if (action.name === "clientId" || action.name === "technicienId") {
        select[action.name] = value.id;
      } else if (action.name === "typeCategorie") {
        select[action.name] = value.type;
      } else {
        select[action.name] = value.nom;
      }
      this.setState(
        {
          select: select,
        },
        () => {
          this.getStatistiqueDureeFiltre();
        }
      );
    } else {
      let select = this.state.select;
      if (
        action.name === "sousSociete" ||
        action.name === "priorite" ||
        action.name === "typeCategorie"
      ) {
        select[action.name] = "";
      } else if (
        action.name === "typeSociete" ||
        action.name === "clientId" ||
        action.name === "technicienId"
      ) {
        select[action.name] = "";
      } else {
        select[action.name] = null;
      }
      this.setState(
        {
          select: select,
        },
        () => {
          this.getStatistiqueDureeFiltre();
        }
      );
    }
  };

  handleDateMinChange = (e) => {
    let select = this.state.select;
    select[e.target.name] = e.target.value;
    this.setState(
      {
        select: select,
      },
      () => {
        if (
          this.state.select["dateDebut"] !== "" &&
          this.state.select["dateFin"] !== ""
        ) {
          this.getStatistiqueDureeFiltre();
        }
      }
    );
  };

  handleDateMaxChange = (e) => {
    let select = this.state.select;
    select[e.target.name] = e.target.value;
    this.setState(
      {
        select: select,
      },
      () => {
        if (
          this.state.select["dateDebut"] !== "" &&
          this.state.select["dateFin"] !== ""
        ) {
          this.getStatistiqueDureeFiltre();
        }
      }
    );
  };

  customFilterSociete = (option, searchText) => {
    if (
      (option.data.adresse !== null &&
        option.data.adresse.ville !== null &&
        option.data.adresse.ville
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (option.data.nom !== null &&
        option.data.nom.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetValueSelect = () => {
    this.selectRef["sousSociete"].select.clearValue();
    this.selectRef["typeSociete"].select.clearValue();
    this.selectRef["priorite"].select.clearValue();
    this.selectRef["clientId"].select.clearValue();
    this.selectRef["technicienId"].select.clearValue();
    this.selectRef["typeCategorie"].select.clearValue();
    document.getElementsByName("dateDebut")[0].value = "";
    document.getElementsByName("dateFin")[0].value = "";
    this.getStatistiqueDuree();
  };

  render() {
    const { selected, isLoading, activeTab } = this.state;
    return (
      <div>
        <Card>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-none d-md-block">
                  <i className="mdi mdi-filter-outline" /> Filtres
                </span>
                <span className="d-block d-md-none">
                  <i className="mdi mdi-filter-outline" />
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardBody className="pb-2">
                    <Row>
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par société</Label>
                          {this.state.selectTicketsNomSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsNomSociete}
                              onChange={this.handleFilter}
                              filterOption={this.customFilterSociete}
                              name="sousSociete"
                              placeholder="Votre societé/ville"
                              noOptionsMessage={() => "Aucune société"}
                              ref={(ref) => {
                                this.selectRef["sousSociete"] = ref;
                              }}
                              isClearable
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par type</Label>
                          {this.state.selectTicketsTypeSociete !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.nom}`}
                              getOptionValue={(option) => `${option.nom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsTypeSociete}
                              onChange={this.handleFilter}
                              noOptionsMessage={() => "Aucun type"}
                              placeholder="Votre type"
                              name="typeSociete"
                              ref={(ref) => {
                                this.selectRef["typeSociete"] = ref;
                              }}
                              isClearable
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par priorité</Label>
                          <Select
                            getOptionLabel={(option) => `${option.nom}`}
                            getOptionValue={(option) => `${option.nom}`}
                            styles={this.colourStyles}
                            options={this.selectTicketsPriorite}
                            onChange={this.handleFilter}
                            noOptionsMessage={() => "Aucune priorité"}
                            placeholder="Votre priorité"
                            isClearable
                            name="priorite"
                            ref={(ref) => {
                              this.selectRef["priorite"] = ref;
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par client</Label>
                          {this.state.selectTicketsPrenomClient !== null && (
                            <Select
                              getOptionLabel={(option) =>
                                `${option.prenom} ${option.nom}`
                              }
                              getOptionValue={(option) =>
                                `${option.prenom} ${option.nom}`
                              }
                              styles={this.colourStyles}
                              options={this.state.selectTicketsPrenomClient}
                              onChange={this.handleFilter}
                              name="clientId"
                              placeholder="Votre client"
                              noOptionsMessage={() => "Aucun client"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["clientId"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par catégorie</Label>
                          {this.state.selectTicketsCategorie !== null && (
                            <Select
                              getOptionLabel={(option) => `${option.type}`}
                              getOptionValue={(option) => `${option.type}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsCategorie}
                              onChange={this.handleFilter}
                              name="typeCategorie"
                              placeholder="Votre catégorie"
                              noOptionsMessage={() => "Aucune catégorie"}
                              isClearable
                              ref={(ref) => {
                                this.selectRef["typeCategorie"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <FormGroup>
                          <Label>Rechercher par technicien</Label>
                          {this.state.selectTicketsPrenomTechnicien !==
                            null && (
                            <Select
                              getOptionLabel={(option) => `${option.prenom}`}
                              getOptionValue={(option) => `${option.prenom}`}
                              styles={this.colourStyles}
                              options={this.state.selectTicketsPrenomTechnicien}
                              onChange={this.handleFilter}
                              noOptionsMessage={() => "Aucune technicien"}
                              placeholder="Votre technicien"
                              name="technicienId"
                              isClearable
                              ref={(ref) => {
                                this.selectRef["technicienId"] = ref;
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Rechercher par mois : Date début</Label>
                          <Input
                            type="date"
                            name="dateDebut"
                            id="dateDebut"
                            placeholder="date placeholder"
                            onChange={this.handleDateMinChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label> Date fin</Label>
                          <InputGroup>
                            <Input
                              type="date"
                              name="dateFin"
                              id="dateFin "
                              placeholder="date placeholder"
                              onChange={this.handleDateMaxChange}
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      {(this.state.select["sousSociete"] !== "" ||
                        this.state.select["typeSociete"] !== "" ||
                        this.state.select["clientId"] !== "" ||
                        this.state.select["priorite"] !== "" ||
                        this.state.select["technicienId"] !== "" ||
                        this.state.select["typeCategorie"] !== "" ||
                        (this.state.select["dateDebut"] !== null &&
                          this.state.select["dateFin"] !== null)) && (
                        <Col lg={12}>
                          <InputGroupAddon
                            addonType="append"
                            className="float-right"
                          >
                            <button
                              className="btn btn-danger"
                              onClick={() => this.resetValueSelect()}
                            >
                              Effacer les filtres
                            </button>
                          </InputGroupAddon>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
        <div className="bg-white pt-2">
          <ButtonGroup className="mb-1 ml-2">
            <button
              className="btn selectButton"
              onClick={() => this.setState({ selected: "graphique" })}
              active={(this.state.selected === "graphique").toString()}
            >
              Graphique
            </button>
            <button
              className="btn selectButton"
              onClick={() => {
                this.setState({ selected: "liste" });
              }}
              active={(this.state.selected === "graphique").toString()}
            >
              Liste
            </button>
          </ButtonGroup>
          <div className="float-right mr-3">
            {this.state.selected === "graphique" ? (
              <ExcelFile
                element={
                  <button className="btn btn-success">
                    Exporter les données du graphique
                  </button>
                }
                filename={"Statistique des durées"}
              >
                <ExcelSheet
                  data={this.state.dataGraphique}
                  name="Statistique des durées"
                >
                  <ExcelColumn
                    label="Temps moyen des tickets"
                    value="TempsMoyen"
                  />
                  <ExcelColumn
                    label="Temps maximum d'un ticket"
                    value="TempsMaximum"
                  />
                  <ExcelColumn
                    label="Temps moyen des clients"
                    value="TempsMoyenClients"
                  />
                  <ExcelColumn
                    label="Temps moyen du support"
                    value="TempsMoyenSupport"
                  />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              <ExcelFile
                element={
                  <button className="btn btn-success">
                    Exporter les données de la liste
                  </button>
                }
                filename={"Statistique des durées"}
              >
                <ExcelSheet
                  data={this.state.dataListe}
                  name="Statistique des durées"
                >
                  <ExcelColumn label="Id" value="id" />
                  <ExcelColumn label="Nom de la société" value="societe" />
                  <ExcelColumn label="Type de société" value="type" />
                  <ExcelColumn label="Prénom du client" value="client" />
                  <ExcelColumn label="Sujet" value="sujet" />
                  <ExcelColumn label="Technicien" value="technicien" />
                  <ExcelColumn label="Date de création" value="dateCreation" />
                  <ExcelColumn
                    label="Type de catégorie"
                    value="typeCategorie"
                  />
                  <ExcelColumn label="Priorité" value="priorite" />
                  <ExcelColumn label="Temps total" value="tempsTotal" />
                  <ExcelColumn label="Temps support" value="tempsSupport" />
                  <ExcelColumn label="Temps client" value="tempsClient" />
                </ExcelSheet>
              </ExcelFile>
            )}
          </div>
          {!isLoading && (
            <p className="h2 ml-2 font-weight-bold">
              Total : {this.state.ticketsFilter.length}
            </p>
          )}
          {selected === "graphique" ? (
            isLoading ? (
              <Chargement />
            ) : (
              <>
                <GraphiqueDuree
                  optionsbar={this.optionsbar}
                  seriesbar={this.seriesbar}
                />
              </>
            )
          ) : isLoading ? (
            <Chargement />
          ) : (
            <TableauDuree
              tickets={this.state.ticketsFilter}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    );
  }
}
