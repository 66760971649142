import {authHeader, handleResponse } from '../_helpers';


export const fichierService = {
    // (GET)
    RecupereTousFichiersParMessage
};

// Récupérer tous les tickets du client
function RecupereTousFichiersParMessage(messageId){
    const requestOptions = {method: "GET",headers: authHeader()};
    let url = `${process.env.REACT_APP_API}/fichiers/${messageId}`

    return fetch(url, requestOptions)
                .then(handleResponse)
                .then((reponse) => {
                    return reponse;
                });
}



