import {authHeader, handleResponse } from '../_helpers';


export const messageService = {
    // (GET)
    RecupereTousMessagesParTicket,
    //POST
    EnregistrerMessage,
    EnvoyerMail
};

// Récupérer tous les messages d'un ticket
async function RecupereTousMessagesParTicket(ticketId){
    const requestOptions = {method: "GET",headers: authHeader()};
    let url = `${process.env.REACT_APP_API}/messages/${ticketId}`

    return await fetch(url, requestOptions)
                .then(handleResponse)
                .then((reponse) => {
                    return reponse;
                });
}



function EnregistrerMessage(utilisateurId, ticketId,message, pieceJointes){

    let formData = new FormData();
    formData.append("Message", message);

    // eslint-disable-next-line
    for (let key of Object.keys(pieceJointes)) {
        formData.append("Fichier", pieceJointes[key]);
    }

    const url = `${process.env.REACT_APP_API}/messages/${utilisateurId}/${ticketId}`;

    let requestOptions = {
        method: "POST",
        headers: authHeader(false,false,true),
        body: formData
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((reponse) => {
            return reponse;
        });

}

function EnvoyerMail(sujet,emailDestinataire,utilisateurId,ticketId){

    const json = JSON.stringify({sujet,emailDestinataire});
    const url = `${process.env.REACT_APP_API}/messages/mails/${utilisateurId}/${ticketId}`;

    let requestOptions = {
        method: "POST",
        headers: authHeader(true),
        body: json
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((reponse) => {
            return reponse;
        });

}
