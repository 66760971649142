import React, { Component } from 'react'
import { 
    Card,
    CardBody,
    CardTitle,
    Table 
} from 'reactstrap';
import dateFormat from 'dateformat'
import { Redirect} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import retourneTechnicien from '../Fonctions/Utils/retourneTechnicien';
import retourneTexteRaccourci from '../Fonctions/Utils/retourneTexteRaccourci';

export default class TableauTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            id: ''
        }
        this.header = ["N°","Société","Produit","Client","Sujet","Priorité","Statut","Création","Technicien","Catégorie","Module"]
    }

    showInformationTicket = (id) => {
        this.setState({
            redirect: true,
            id: id
        })
    }
    

    render() {
        const {tickets} = this.props
        return (
            <div>
                {this.state.redirect === true && <Redirect to={{pathname : `/support/ticket/information/${this.state.id}`, state: {type: 'tickets'}}}/>}    
                <Card>
                    <CardBody className="bg-info-gradient rounded-top">
                        <CardTitle className="h4 mb-0 text-white">Liste des tickets</CardTitle>
                    </CardBody>
                    <Table className="mb-0 align-middle" hover responsive size={"sm"}>
                        <thead>
                            <tr>
                                {this.header.map((name,i) => {
                                    return <th scope="col" className="th" key={i}>{name}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                        {this.props.isLoading ? 
                            <>
                                {Array(7).fill().map((i,index) => (
                                    <tr key={index}>
                                        {Array(11).fill().map((i,index) => (
                                            <td key={index}>
                                                <Skeleton width={'100%'} duration={2} />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </> 
                        :
                        <>
                            {tickets !== null && tickets.map((ticket) => (
                                <React.Fragment key={ticket.id}>
                                    <tr className="cursor" onClick={ () => this.showInformationTicket(ticket.id)}>
                                        <td className="text-center">{ticket.id}</td>
                                        <td className="text-center">{ticket.sousSociete}</td>
                                        <td className="text-center">{ticket.typeSociete}</td>
                                        <td className="text-center">{ticket.prenomClient}</td>
                                        <td className="text-center">{retourneTexteRaccourci(ticket.sujet)}</td>
                                        <td className="text-center h5">{ticket.priorite === "Haute" ? <span className="badge badge-pill bg-danger text-white">{ticket.priorite}</span> : ticket.priorite === "Moyenne" ? <span className="badge badge-pill bg-warning text-white">{ticket.priorite}</span> : <span className="badge badge-pill bg-successV2 text-white">{ticket.priorite}</span>}</td>
                                        <td className="text-center h5">{ticket.statut === "En attente" ?
                                                                    <span className="badge badge-pill bg-warning text-white">{ticket.statut}</span> :
                                                                    ticket.statut === "Clôture" ? <span className="badge badge-pill bg-success text-white">{ticket.statut}</span> :
                                                                    ticket.statut === "Ouvert" ? <span className="badge badge-pill bg-danger text-white">{ticket.statut}</span> :
                                                                    ticket.statut === "En cours" ? <span className="badge badge-pill bg-secondary text-white">{ticket.statut}</span> :
                                                                    <span className="badge badge-pill bg-primary text-white">{ticket.statut}</span>}
                                        </td>
                                        <td className="text-center">{dateFormat(ticket.dateCreation,"dd/mm/yyyy HH:MM")}</td>
                                        <td className="text-center">{retourneTechnicien(ticket.prenomTechnicien)}</td>
                                        <td className="text-center">{ticket.categorie}</td>
                                        <td className="text-center">{ticket.module}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </>
                        }
                        </tbody>
                    </Table>
                </Card>
            </div>
        )
    }
}
