import React, { Component } from "react";
import { Card } from "reactstrap";

export default class GraphiqueDuree extends Component {
  render() {
    const { optionsbar, seriesbar } = this.props;
    return (
      <div className="mx-2 pb-1">
        <Card>
          {seriesbar[0].data.length !== 0 ? (
            <></>
          ) : (
            <p className="h1 text-center p-5 font-weight-bold">
              Aucun ticket clôturé
            </p>
          )}
        </Card>
      </div>
    );
  }
}
